<template>
  <div v-if="this.$root.dictionary != null">
    <LoginComponent />
  </div>
</template>

<script>
  import LoginComponent from "@/components/Auth/LoginComponent";

  export default {
    name: "Login-Page",
    components: { LoginComponent }
  };
</script>
