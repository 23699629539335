const initialState = () => {
  return {
    list: [],
    headers: [],
    tableFilters: [],
    currentPage: 0,
    totalDataCount: 0,
    totalPageCount: 0,
    searchType: searchTypes.URL
  };
};
const state = () => initialState();

import axios from "axios";
import app from "@/common/constants/app";
import searchTypes from "@/common/enums/searchTypes";
import utils from "@/common/utils/utils";

export const roleModule = {
  namespaced: true,
  state: state,
  mutations: {
    resetModuleState(state) {
      Object.assign(state, initialState());
    },
    getRoles: function (state, response) {
      state.list = response.data.state ? response.data.list : [];
      state.headers = [
        {
          title: "Id",
          column: "id",
          dataType: "int",
          inputType: "string",
          searchType: {
            type: searchTypes.URL,
            value: "/role/getwithoptions"
          },
          show: false,
          value: "",
          orderby: "",
          filterRule: ""
        },
        {
          title: "Heading",
          column: "heading",
          dataType: "string",
          inputType: "string",
          searchType: {
            type: searchTypes.URL,
            value: "/role/getwithoptions"
          },
          show: true,
          value: "",
          orderby: "",
          filterRule: ""
        },
        {
          title: "Date Created",
          column: "dateCreated",
          dataType: "date",
          inputType: "date",
          searchType: {
            type: searchTypes.URL,
            value: "/role/getwithoptions"
          },
          show: true,
          value: "",
          orderby: "",
          filterRule: ""
        },
        {
          title: "Date Updated",
          column: "dateUpdated",
          dataType: "date",
          inputType: "date",
          searchType: {
            type: searchTypes.URL,
            value: "/role/getwithoptions"
          },
          show: true,
          value: "",
          orderby: "",
          filterRule: ""
        },
        {
          title: "Is Active?",
          column: "isActive",
          dataType: "enum",
          enumType: "boolean",
          columnType: "ActivePassive",
          inputType: "enum_activepassive",
          searchType: {
            type: searchTypes.URL,
            value: "/role/getwithoptions"
          },
          show: true,
          value: "",
          orderby: "",
          filterRule: ""
        }
      ];
      state.tableFilters = app.tableFilters;
      state.totalDataCount = response.data.totalDataCount;
      state.totalPageCount = response.data.totalPageCount;
      state.currentPage = response.data.currentPage;
    }
  },
  actions: {
    RESET_APP_STATE({ commit }) {
      commit("resetModuleState");
    },
    GET_ROLES: ({ commit }) => {
      axios
        .post("role/getwithoptions", {
          page: app.firstPage,
          size: app.pageSize
        })
        .then((response) => {
          if (utils.isResponseOk(response)) {
            commit("getRoles", response);
          }
        })
        .catch((error) => {
          console.log("error on role state getroles() " + error);
        });
    }
  }
};
