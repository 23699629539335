<template>
  <div class="data-table-pager" v-if="showPager">
    <div v-if="totalPageCount > 1">
      <ul class="pagination" v-if="totalPageCount">
        <li class="page-item" v-if="showFirstButton">
          <a @click.stop="goto(1)" class="page-link">First</a>
        </li>
        <li class="page-item" v-if="showPreviousButton">
          <a @click.stop="clickPreviousButton" class="page-link"
            ><i class="las la-angle-left pager-icon"></i
          ></a>
        </li>
        <li
          class="page-item"
          :class="currentPage === page ? 'active' : ''"
          v-for="(page, index) in calculatePageCount"
          :key="index"
          ><a
            @click.stop="goto(page)"
            class="page-link"
            :class="currentPage !== page ? '' : 'disabled'"
          >
            {{ page }}
          </a>
        </li>
        <li class="page-item" v-if="showNextButton">
          <a @click.stop="clickNextButton" class="page-link"
            ><i class="las la-angle-right pager-icon"></i
          ></a>
        </li>
        <li class="page-item" v-if="showLastButton">
          <a @click.stop="goto(totalPageCount)" class="page-link">Last</a>
        </li>
      </ul>
    </div>
    <div class="w-100"></div>
    <div class="page-info">
      <div>
        Total Data Count is {{ $utils.bigNumberFormatter(totalDataCount) }}
      </div>
      <div v-if="totalPageCount > 1">Current page is {{ currentPage }}</div>
    </div>
  </div>
</template>

<script>
  import app from "@/common/constants/app";

  export default {
    name: "pagerComponent",
    props: {
      currentPage: { type: Number, default: 0 },
      totalDataCount: { type: Number, default: 0 },
      totalRowCount: { type: Number, default: 0 },
      totalPageCount: { type: Number, default: 0 }
    },
    watch: {
      currentPage() {},
      totalDataCount() {},
      totalRowCount() {},
      totalPageCount() {}
    },
    computed: {
      showPager() {
        return this.totalPageCount;
      },
      showFirstButton() {
        return (
          this.currentPage != app.firstPage &&
          this.currentPage > app.pageDivider - 1 &&
          this.totalPageCount > app.pageDivider + 1
        );
      },
      showLastButton() {
        return (
          this.totalPageCount > app.pageDivider &&
          this.currentPage != this.totalPageCount
        );
      },
      calculatePageCount() {
        let begin = this.currentPage < 5 ? 1 : this.currentPage - 2,
          end =
            this.totalPageCount <= app.pageDivider
              ? this.totalPageCount + 1
              : this.currentPage < app.pageDivider
              ? app.pageDivider + 1
              : this.currentPage >= this.totalPageCount - 2
              ? this.totalPageCount + 1
              : this.currentPage + 3,
          pages = [];

        for (let index = begin; index < end; index++) {
          pages.push(index);
        }

        return pages;
      },
      showPreviousButton() {
        return this.currentPage > app.pageDivider - 1;
      },
      showNextButton() {
        return (
          this.totalPageCount > app.pageDivider &&
          this.currentPage != this.totalPageCount
        );
      },
      getNextPage() {
        return this.currentPage + 1;
      }
    },
    methods: {
      clickNextButton() {
        var page = this.calculatePageCount;

        this.goto(page[page.length - 1] + 1);
      },
      clickPreviousButton() {
        var page = this.calculatePageCount;

        this.goto(page[0] - 1);
      },
      goto(page) {
        this.$emit("goto", page);
      }
    }
  };
</script>
