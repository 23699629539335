<template>
  <div :class="outerDivClass">
    <label :for="id" :class="labelDivClass">{{ text }}</label>
    <div div :class="inputDivClass" style="">
      <div class="input-group" style="height: auto !important">
        <slot name="InputSlot"></slot>
        <span class="input-group-addon" style="height: auto !important">
          <slot name="InputAppendSlot"></slot>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CtsInputGroup",
    data() {
      return {
        outerDivClass: "form-group ",
        labelDivClass: "control-label ",
        inputDivClass: ""
      };
    },
    props: {
      text: {
        type: String,
        default: ""
      },
      id: {
        type: [String, Number],
        default: ""
      },
      isOneLine: {
        type: Boolean,
        default: true
      },
      labelClass: {
        type: String,
        default: ""
      },
      inputClass: {
        type: String,
        default: ""
      }
    },
    methods: {
      getClass() {
        if (this.isOneLine) {
          this.outerDivClass += "row";
          this.labelDivClass += "col-xs-12 col-sm-4 col-md-3 col-lg-3 ";
          this.inputDivClass += "col-xs-12 col-sm-8 col-md-9 col-lg-9 ";
        }

        if (this.labelClass != "" && this.inputClass != "") {
          this.labelDivClass = "control-label " + this.labelClass + " ";
          this.inputDivClass = this.inputClass + " ";
        }
      }
    },
    created() {
      this.getClass();
    }
  };
</script>
<style scoped>
  .form-group label {
    padding-top: 5px;
  }
</style>
