<template>
  <div>
    <cts-modal
      name="ctsInputGroupModal"
      transition="pop-out"
      :draggable="false"
      :clickToClose="true"
      :scrollable="true"
      width="60%"
    >
      <InputGroupExamples />
    </cts-modal>
    <div class="row col-md-12 col-lg-12 row">
      <div class="col-md-6 col-lg-6" style="text-align: left">
        <cts-input-group text="InputGroup" id="InputGroup">
          <cts-input
            slot="InputSlot"
            text="InputGroup"
            name="InputGroup"
            id="InputGroup"
            v-model="inputData"
            :model="inputData"
            placeholder="This is a input group."
            :isFormGroup="false"
          />
          <div slot="InputAppendSlot">
            <button
              class="btn btn-success input-group-button"
              type="button"
              id="groupBtn"
              @click="$modal.show('ctsInputGroupModal')"
              >See More Examples</button
            >
          </div>
        </cts-input-group>
        <div class="form-group row">
          <label
            for="example"
            class="control-label col-xs-12 col-sm-4 col-md-3 col-lg-3"
          ></label>
          <div class="col-xs-12 col-sm-8 col-md-9 col-lg-9">
            <div class="example" id="example">
              <h5>
                <pre> {{ example }} </pre>
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-6" style="text-align: left">
        <ul>
          <li>
            <b>Props:</b>
            <ul>
              <li>text -> Type String (Not required, default: "" )</li>
              <li>id -> Type String, Number (Not required, default: "" )</li>
              <small> (This must be same with inputSlot element) </small>
              <li>isOneLine -> Type Boolean (Not required, default: true)</li>
              <small>
                (Used to determine whether the component and name will be on a
                line)
              </small>
              <li>Slot = "" prop should be used to add input and buttons.</li>
              <li>Click "See More Examples" for see the other items usage.</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import CtsInput from "@/components/ui-components/CtsInput/CtsInput";
  import CtsInputGroup from "@/components/ui-components/CtsInputGroup/CtsInputGroup";
  import CtsModal from "@/components/ui-components/CtsModal/CtsModal";
  import InputGroupExamples from "@/components/Development/CtsInputGroupComponent/InputGroupExamples";

  export default {
    name: "CtsInputGroupComponent",
    components: {
      "cts-input-group": CtsInputGroup,
      "cts-input": CtsInput,
      "cts-modal": CtsModal,
      InputGroupExamples
    },
    data() {
      return {
        inputData: "",
        example:
          '<cts-input-group text="InputGroup"' +
          "\n" +
          '    id="InputGroup">' +
          "\n" +
          "    <cts-input" +
          "\n" +
          '      slot="InputSlot"' +
          "\n" +
          '      text="inputGroup"' +
          "\n" +
          '      name="inputGroup"' +
          "\n" +
          '      id="inputGroup"' +
          "\n" +
          '      v-model="inputData"' +
          "\n" +
          '      :model="inputData"' +
          "\n" +
          '      placeholder="This is a input group."' +
          "\n" +
          '      :isFormGroup="false" />' +
          "\n" +
          '    <div slot="InputAppendSlot">' +
          "\n" +
          "      <button" +
          "\n" +
          '        class="btn btn-success"' +
          "\n" +
          '        type="button" ' +
          "\n" +
          '        id="groupBtn"' +
          "\n" +
          '        style="height: 100% !important;">' +
          "\n" +
          '        <i class="las la-search"></i>' +
          "\n" +
          "        See More Examples" +
          "\n" +
          "      </button>" +
          "\n" +
          "    </div>" +
          "\n" +
          " </cts-input-group>"
      };
    }
  };
</script>
