<template>
  <div
    class="row justify-content-center align-items-flex-start col-md-12 col-lg-12"
    style="position: relative"
  >
    <div class="col-md-6 col-lg-6" style="text-align: left">
      <cts-checkbox
        text="Checkbox"
        name="Checkbox"
        id="Checkbox"
        v-model="checkboxData"
        :model="checkboxData"
        textAlign="Left"
      />
      <br />
      <div class="form-group row">
        <label
          for="exampleSingle"
          class="control-label col-xs-12 col-sm-4 col-md-3 col-lg-3"
        ></label>
        <div class="col-xs-12 col-sm-8 col-md-9 col-lg-9">
          <div class="example" id="exampleSingle">
            <h5>
              <pre> {{ exampleSingle }} </pre>
            </h5>
          </div>
        </div>
      </div>

      <div class="col-12 row">
        <div class="col-6">
          <h6>Use array in checkbox:</h6>
          <cts-checkbox
            :isArray="true"
            text="Checkbox1"
            name="Checkbox1"
            id="Checkbox1"
            v-model="checkboxDataList"
            val="Checkbox1"
            textAlign="Rigth"
          />
          <cts-checkbox
            :isArray="true"
            text="Checkbox2"
            name="Checkbox2"
            id="Checkbox2"
            v-model="checkboxDataList"
            val="Checkbox2"
            textAlign="Right"
          />
        </div>
        <div class="col-6">
          <h6>Selected Items : {{ checkboxDataList }}</h6>
        </div>
      </div>

      <br />
      <div class="form-group row">
        <label
          for="exampleMulti"
          class="control-label col-xs-12 col-sm-4 col-md-3 col-lg-3"
        ></label>
        <div class="col-xs-12 col-sm-8 col-md-9 col-lg-9">
          <div class="example" id="exampleMulti">
            <h5>
              <pre> {{ exampleMulti }} </pre>
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6" style="text-align: left">
      <ul>
        <li>
          <b>Props:</b>
          <ul>
            <li>text -> Type String (Not required, default: "" )</li>
            <li>name -> Type String (Not required, default: "" )</li>
            <li>id -> Type String, Number (Not required, default: "" )</li>
            <li>model -> Type String, Array (Required)</li>
            <li>textAlign -> Type String (Not required, default: "Left")</li>
            <small>
              (Used to determine where the text will be. You can use "Left" or
              "Right")
            </small>
            <li>color -> Type String (Not required, default: "primary")</li>
            <small> (Used to determine the color of the checkBox") </small>
            <li>isInGrid -> Type Boolean (Not required, default: false)</li>
            <small> (Used to avoid labeling the element") </small>
            <li>isArray -> Type Boolean (Not required, default: false)</li>
            <small>
              (Used when an array is given to the item as a model. (With val
              prop)")
            </small>
            <li>val -> Type Boolean (Not required, default: false)</li>
            <small>
              (When the array is used as a model, the value to be push to the
              array when the related item is selected.)
            </small>
            <li>change -> Type Function (Optionally)</li>
            <small>
              (Function to be run when selection is made. Use of:
              @change="yourfunction")
            </small>
            <li
              >Reference :
              <a href="https://bantikyan.github.io/icheck-bootstrap/"
                >icheck-bootstrap</a
              ></li
            >
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import CtsCheckbox from "@/components/ui-components/CtsCheckbox/CtsCheckbox";
  export default {
    name: "CtsCheckboxComponent",
    data() {
      return {
        checkboxData: true,
        checkboxDataList: [],
        exampleSingle:
          "<cts-checkbox" +
          "\n" +
          ' text="Checkbox"' +
          "\n" +
          ' name="description"' +
          "\n" +
          ' id="Checkbox"' +
          "\n" +
          ' v-model="checkboxData"' +
          "\n" +
          ' :model="checkboxData"' +
          "\n" +
          ' textAlign="Left"' +
          "\n" +
          " />",
        exampleMulti:
          "<cts-checkbox" +
          "\n" +
          ' :isArray="true"' +
          "\n" +
          ' text="Checkbox1"' +
          "\n" +
          ' name="Checkbox1"' +
          "\n" +
          ' id="Checkbox1"' +
          "\n" +
          ' v-model="checkboxDataList"' +
          "\n" +
          ' val="Checkbox1"' +
          "\n" +
          ' textAlign="Rigth"' +
          "\n" +
          " />"
      };
    },
    components: {
      "cts-checkbox": CtsCheckbox
    }
  };
</script>
