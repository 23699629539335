import axios from "axios";

const ApiService = {
  initialize(baseURL) {
    axios.defaults.baseURL = baseURL;
    axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  }
};

export default ApiService;
