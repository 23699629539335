const initialState = () => {
  return {
    parameters: []
  };
};
const state = () => initialState();

import axios from "axios";
import utils from "@/common/utils/utils";

export const parameterModule = {
  namespaced: true,
  state: state,
  mutations: {
    resetModuleState(state) {
      Object.assign(state, initialState());
    },
    setParameter: function (state, response) {
      var parameter = {};

      response.forEach((item) => {
        Object.defineProperty(parameter, item.key, {
          get: function () {
            return item.parameterValues.length > 0
              ? item.parameterValues[0].value
              : "";
          },
          enumerable: true
        });
      });

      state.parameters = parameter;
    }
  },
  actions: {
    RESET_APP_STATE({ commit }) {
      commit("resetModuleState");
    },
    GET_PARAMETERS: async ({ commit }) => {
      await axios
        .get("parameter")
        .then((response) => {
          if (utils.isResponseOk(response) && response.data != null) {
            commit("setParameter", response.data.list);
          }
          Promise.resolve(response);
        })
        .catch((error) => {
          console.log("error on language state getParameter() " + error);
          Promise.reject(error);
        });
    }
  }
};
