<template>
  <div v-if="type == 'LongNumber'">
    <div v-if="this.$utils.canShow(name) || !checkAuth">
      <div v-if="isFormGroup">
        <div :class="outerDivClass">
          <label :for="id" :class="labelDivClass">{{ text }}</label>
          <div :class="inputDivClass">
            <input
              v-if="!disabled && (this.$utils.canModify(name) || !checkAuth)"
              :type="type"
              :name="name"
              :id="id"
              :v-model="formatted"
              :value="formatted"
              :class="[css, isRequired ? 'requiredField' : '']"
              :placeholder="this.placeholder"
              @input="update($event.target.value)"
              multiple="multiple"
              class="form-control cts-input"
              :autocomplete="autocomplete"
              v-mask="mask"
              @blur="blur"
            />
            <div class="disabledField form-control" v-else>{{ formatted }}</div>
            <div class="errors" v-if="validator.$error">
              <div
                class="error"
                v-for="error in activeErrorMessages"
                :key="error"
                ><span>{{ getError(error) }}</span></div
              >
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!disabled && (this.$utils.canModify(name) || !checkAuth)">
        <input
          :type="type"
          :name="name"
          :id="id"
          :v-model="formatted"
          :value="formatted"
          :class="[css, isRequired ? 'requiredField' : '']"
          :placeholder="this.placeholder"
          @input="update($event.target.value)"
          multiple="multiple"
          class="form-control cts-input"
          :autocomplete="autocomplete"
          v-mask="mask"
          @blur="blur"
        />
        <div class="errors" v-if="validator.$error">
          <div class="error" v-for="error in activeErrorMessages" :key="error">
            <span>{{ getError(error) }}</span>
          </div>
        </div>
      </div>

      <div class="disabledField form-control" v-else>{{ model }}</div>
    </div>
  </div>
  <div v-else>
    <div v-if="this.$utils.canShow(name) || !checkAuth">
      <div v-if="isFormGroup">
        <div :class="outerDivClass">
          <label :for="id" :class="labelDivClass">{{ text }}</label>
          <div :class="inputDivClass">
            <input
              v-if="!disabled && (this.$utils.canModify(name) || !checkAuth)"
              :type="type"
              :name="name"
              :id="id"
              :v-model="model"
              :value="model"
              :class="[css, isRequired ? 'requiredField' : '']"
              :placeholder="this.placeholder"
              @input="update($event.target.value)"
              multiple="multiple"
              class="form-control cts-input"
              :autocomplete="autocomplete"
              @blur="blur"
            />
            <div class="disabledField form-control" v-else>{{ model }}</div>
            <div class="errors" v-if="validator.$error">
              <div
                class="error"
                v-for="error in activeErrorMessages"
                :key="error"
              >
                <span>{{ getError(error) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!disabled && (this.$utils.canModify(name) || !checkAuth)">
        <input
          :type="type"
          :name="name"
          :id="id"
          :v-model="model"
          :value="model"
          :class="[css, isRequired ? 'requiredField' : '']"
          :placeholder="this.placeholder"
          @input="update($event.target.value)"
          multiple="multiple"
          class="form-control cts-input"
          :autocomplete="autocomplete"
          @blur="blur"
        />
        <div class="errors" v-if="validator.$error">
          <div class="error" v-for="error in activeErrorMessages" :key="error">
            <span>{{ getError(error) }}</span>
          </div>
        </div>
      </div>

      <div class="disabledField form-control" v-else>{{ model }}</div>
    </div>
  </div>
</template>

<script>
  import createNumberMask from "text-mask-addons/dist/createNumberMask";
  import { singleErrorExtractorMixin } from "vuelidate-error-extractor";
  export default {
    name: "CtsInput",
    mixins: [singleErrorExtractorMixin],
    data() {
      return {
        outerDivClass: " ",
        labelDivClass: " ",
        inputDivClass: "",
        formatted: "",
        mask: createNumberMask({
          allowDecimal: this.allowDecimal,
          decimalSymbol: this.$root.parameters.DecimalSymbol,
          includeThousandsSeparator: true,
          prefix: "",
          thousandsSeparatorSymbol: this.$root.parameters.DigitGroupingSymbol,
          decimalLimit: this.decimalLimit
        })
      };
    },
    props: {
      text: {
        type: String,
        default: ""
      },
      name: {
        type: String,
        default: ""
      },
      id: {
        type: [String, Number],
        default: ""
      },
      model: {
        type: [String, Array, Number],
        default: ""
      },
      type: {
        type: String,
        default: "text"
      },
      isOneLine: {
        type: Boolean,
        default: true
      },
      placeholder: {
        type: String,
        default: ""
      },
      isRequired: {
        type: Boolean,
        default: false
      },
      css: {
        type: [String, Array],
        default: ""
      },
      isFormGroup: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      autocomplete: {
        type: String,
        default: ""
      },
      labelClass: {
        type: String,
        default: ""
      },
      inputClass: {
        type: String,
        default: ""
      },
      checkAuth: {
        // for check menu object permission
        type: Boolean,
        default: true
      },
      allowDecimal: {
        type: Boolean,
        default: false
      },
      decimalLimit: {
        type: Number,
        default: 4
      },
      decimalAltLimit: {
        type: Number,
        default: 0
      },
      validator: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    watch: {
      formatted() {},
      model() {
        this.checkDigits();
      }
    },
    methods: {
      blur() {
        this.checkAltLimit();
        this.$emit("blur");
      },
      getClass() {
        if (this.isOneLine) {
          this.outerDivClass += "form-group row";
          this.labelDivClass =
            "control-label col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 ";
          this.inputDivClass =
            "col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-9 ";
        }

        if (this.labelClass != "" && this.inputClass != "") {
          this.labelDivClass = "control-label " + this.labelClass + " ";
          this.inputDivClass = this.inputClass + " ";
        }
      },
      getError(error) {
        return this.$utils.getVuelidateErrorMessage(error);
      },
      update(value) {
        this.formatted = value;
        var res = value;

        if (this.type == "LongNumber") {
          res = res
            .toString()
            .replace(
              new RegExp("\\" + this.$root.parameters.DigitGroupingSymbol, "g"),
              ""
            )
            .replace(this.$root.parameters.DecimalSymbol, ".");
        }

        this.$emit("input", res);
        this.$emit("change");
      },
      checkDigits() {
        if (this.model != null) {
          this.formatted = this.model
            .toString()
            .replace(".", this.$root.parameters.DecimalSymbol);
        }
      },
      checkAltLimit() {
        if (this.allowDecimal && this.decimalAltLimit != 0) {
          let values = this.formatted.split(
            this.$root.parameters.DecimalSymbol
          );

          if (!this.$utils.isNullOrWhiteSpace(this.formatted)) {
            if (values.length < 2) {
              this.formatted =
                this.formatted + this.$root.parameters.DecimalSymbol;

              for (let index = 0; index < this.decimalAltLimit; index++) {
                this.formatted = this.formatted + "0";
              }
            } else if (parseInt(values[1].length) < this.decimalAltLimit) {
              var missingDigits =
                this.decimalAltLimit - parseInt(values[1].length);

              for (let index = 0; index < missingDigits; index++) {
                this.formatted = this.formatted + "0";
              }
            }
          }
        }
      }
    },
    created() {
      this.getClass();
    },
    async mounted() {
      await this.checkDigits();
    }
  };
</script>

<style scoped>
  .form-group label {
    padding-top: 0 !important;
  }
</style>
