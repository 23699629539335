<template>
  <div v-if="this.$utils.canShow(name) || !checkAuth">
    <button ref="refButton" class="hide" @click="clickToRefButton" />
    <div v-if="isFormGroup" :class="outerDivClass">
      <label :for="id" :class="labelDivClass">{{ text }}</label>
      <div :class="inputDivClass">
        <div
          class="input-group"
          :ref="name"
          id="append-here"
          v-if="!disabled && (this.$utils.canModify(name) || !checkAuth)"
        >
          <flat-pickr
            :set="
              (rand = (Date.now() * Math.random()).toString().replace('.', ''))
            "
            :id="id + '_' + rand"
            :name="name + '_' + rand"
            :v-model="this.$utils.convertToDate(model)"
            :model="this.$utils.convertToDate(model)"
            @input="update($event)"
            :config="this.getConfigs"
            :placeholder="placeholder"
            :value="this.$utils.convertToDate(model)"
            :position="position"
            :disabled="disabled"
            :class="['form-control ' + css, isRequired ? 'requiredField' : '']"
            :style="this.getDisabledStyle(disabled)"
            autocomplete="off"
          >
          </flat-pickr>
          <span class="input-group-btn">
            <button
              class="btn btn-default"
              type="button"
              title="Clear"
              data-clear
            >
              <i class="las la-times">
                <span aria-hidden="true" class="sr-only">Clear</span>
              </i>
            </button>
          </span>
        </div>
        <div class="disabledField form-control" v-else>{{
          this.$utils.convertToDate(model)
        }}</div>
        <div class="errors" v-if="validator.$error">
          <div class="error" v-for="error in activeErrorMessages" :key="error"
            ><span>{{ getError(error) }}</span></div
          >
        </div>
      </div>
    </div>
    <div v-else-if="!disabled && (this.$utils.canModify(name) || !checkAuth)">
      <div class="input-group">
        <flat-pickr
          :set="
            (rand = (Date.now() * Math.random()).toString().replace('.', ''))
          "
          :id="id + '_' + rand"
          :v-model="this.$utils.convertToDate(model)"
          :model="this.$utils.convertToDate(model)"
          @input="update($event)"
          :config="this.getConfigs"
          :class="['form-control ' + css, isRequired ? 'requiredField' : '']"
          :placeholder="placeholder"
          :name="name + '_' + rand"
          :value="this.$utils.convertToDate(model)"
          :disabled="disabled"
          :style="this.getDisabledStyle(disabled)"
          :position="position"
          autocomplete="off"
        >
        </flat-pickr>
        <span class="input-group-btn">
          <button
            class="btn btn-default"
            type="button"
            title="Clear"
            data-clear
          >
            <i class="las la-times">
              <span aria-hidden="true" class="sr-only">Clear</span>
            </i>
          </button>
        </span>
      </div>
      <div class="errors" v-if="validator.$error">
        <div class="error" v-for="error in activeErrorMessages" :key="error"
          ><span>{{ getError(error) }}</span></div
        >
      </div>
    </div>

    <div class="disabledField form-control" v-else>{{
      this.$utils.convertToDate(model)
    }}</div>
  </div>
</template>

<script>
  import flatPickr from "vue-flatpickr-component";
  // import { Turkish as TurkishLocale } from "flatpickr/dist/l10n/tr.js";
  import "flatpickr/dist/flatpickr.css";

  import { singleErrorExtractorMixin } from "vuelidate-error-extractor";
  export default {
    name: "CtsFlatpickr",
    mixins: [singleErrorExtractorMixin],
    data() {
      return {
        outerDivClass: "form-group ",
        labelDivClass: "control-label ",
        inputDivClass: ""
      };
    },
    props: {
      model: {
        type: String
      },
      isOneLine: {
        type: Boolean,
        default: true
      },
      text: {
        type: String,
        default: ""
      },
      placeholder: {
        type: String,
        default: ""
      },
      name: {
        type: String,
        default: ""
      },
      time: {
        type: Boolean,
        default: false
      },
      id: {
        type: [String, Number],
        default: ""
      },
      css: {
        type: String,
        default: ""
      },
      disabled: {
        type: Boolean,
        default: false
      },
      isFormGroup: {
        type: Boolean,
        default: true
      },
      labelClass: {
        type: String,
        default: ""
      },
      inputClass: {
        type: String,
        default: ""
      },
      mode: {
        type: String,
        default: ""
      },
      isRequired: {
        type: Boolean,
        default: false
      },
      position: {
        type: String,
        default: "below"
      },
      configs: {
        type: Object,
        default() {
          return {
            wrap: true,
            // locale: TurkishLocale,
            dateFormat: "d.m.Y H:i:S",
            //dateFormat: this.time ? "d.m.Y H:i:S" : "d.m.Y",
            static: false,
            allowInput: true
            // defaultDate: new Date()
          };
        }
      },
      checkAuth: {
        // for check menu object permission
        type: Boolean,
        default: true
      },
      validator: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      getConfigs() {
        return Object.assign(
          {
            wrap: true,
            // locale: TurkishLocale,
            dateFormat: "d.m.Y H:i:S",
            static: false,
            allowInput: true,
            altInput: false,
            // onChange: function (selDates, dateStr) {
            //   // console.log(selDates);
            //   // console.log(dateStr);
            // },
            onClose: function () {
              if (
                this.config.allowInput &&
                this._input.value &&
                this._input.value !== this._selDateStr
              ) {
                this.setDate(this._input.value, true);
              }
              if (!this._input.value) {
                this.setDate(null, true);
              }
            }
          },
          this.configs
        );
      }
    },
    components: {
      flatPickr
    },
    methods: {
      update(value) {
        let dataColumn = this.$attrs["data-column"],
          css = this.css,
          refButton = this.$refs.refButton;

        if (this.model != value) {
          this.$emit("input", value);
          this.$emit("change", { value, dataColumn, css });

          refButton.click();
        }
      },
      getDisabledStyle(isDisabled) {
        return isDisabled
          ? "margin-bottom: 0px;"
          : "margin-bottom: 0px;background-color:white !important;";
      },
      configuration() {
        if (this.isOneLine) {
          this.outerDivClass += "row";
          this.labelDivClass += "col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3";
          this.inputDivClass += "col-xs-12 col-sm-6 col-md-6 col-lg-8 col-xl-9";
        }

        if (this.labelClass != "" && this.inputClass != "") {
          this.labelDivClass = "control-label " + this.labelClass + " ";
          this.inputDivClass = this.inputClass + " ";
        }

        if (this.time) {
          this.$set(this.configs, "enableTime", true);
          // this.$set(this.configs, "dateFormat", "d-m-Y H:i:S");
        }

        if (this.mode != "") {
          this.$set(this.configs, "mode", this.mode);
        }

        this.$set(this.configs, "appendTo", this.$refs[this.name]);
      },
      getError(error) {
        return this.$utils.getVuelidateErrorMessage(error);
      },
      clickToRefButton() {
        this.$emit("dataChange");
      }
    },
    created() {
      this.configuration();
    }
  };
</script>

<style>
  .flatpickr-wrapper {
    display: block !important;
  }
  .flatpickr-wrapper .input-group-btn .btn-default {
    background-color: #e5e5e5;
    border: solid 1px #ced4da;
  }
  .flatpickr-wrapper .input-group-btn .btn-default i.la-times {
    margin-top: 4px;
  }
  span.input-group-btn {
    margin-left: -3px !important;
    background-color: rgb(229, 229, 229);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
</style>
