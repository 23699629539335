import "@/services/AxiosInterceptors";
import "line-awesome/dist/line-awesome/css/line-awesome.min.css";
import { mapState } from "vuex";
import { systemTypeEnum } from "@/common/enums/systemTypeEnum";
import apiService from "./services/ApiService";
import App from "./App.vue";
import app from "./common/constants/app";
import BootstrapVue from "bootstrap-vue";
import CtsLoading from "./components/LoadingComponent";
import CtsModal from "./components/ui-components/CtsAlert/CtsAlert";
import CtsToastr from "./components/ui-components/CtsToastr/CtsToastr";
import Default from "./layouts/Default.vue";
import Empty from "./layouts/Empty.vue";
import excelHelper from "./common/helpers/excelHelper";
import languageServices from "./services/LanguageServices.js";
import localStorageService from "@/services/LocalStorageService.js";
import NoSidebar from "./layouts/NoSidebar.vue";
import objectPermissionHelper from "./common/helpers/objectPermissionHelper";
import router from "./router";
import store from "./store";
import utils from "./common/utils/utils";
import VModal from "vue-js-modal";
import Vue from "vue";
import Vuelidate from "vuelidate";
import vuelidateErrorExtractor from "vuelidate-error-extractor";
import VueMask from "v-mask";
import VueSimpleAlert from "vue-simple-alert";

Object.defineProperty(Vue.prototype, "$loading", { value: CtsLoading });
Object.defineProperty(Vue.prototype, "$app", { value: app });
Object.defineProperty(Vue.prototype, "$utils", { value: utils });
Object.defineProperty(Vue.prototype, "$CtsToastr", { value: CtsToastr });
Object.defineProperty(Vue.prototype, "$CtsModal", { value: CtsModal });
Object.defineProperty(Vue.prototype, "$systemTypes", { value: systemTypeEnum });
Object.defineProperty(Vue.prototype, "$objectPermissionHelper", {
  value: objectPermissionHelper
});
Object.defineProperty(Vue.prototype, "$excelHelper", {
  value: excelHelper
});

require("./styles/bootstrap.min.css");
require("./styles/animate.min.css");
require("./styles/main.css");
require("./components/ui-components/CtsToastr/ctstoastr.css");
require("./styles/tooltip.css");

Vue.component("default-layout", Default);
Vue.component("no-sidebar-layout", NoSidebar);
Vue.component("empty-layout", Empty);
Vue.component("nav-bar", require("./components/NavbarComponent.vue").default);
Vue.component("side-bar", require("./components/SidebarComponent.vue").default);
Vue.component("tool-bar", require("./components/ToolbarComponent.vue").default);

Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(VueSimpleAlert);
Vue.use(VModal);
Vue.use(VueMask);
Vue.use(vuelidateErrorExtractor, {
  messages: {
    required: "VUELIDATE_ERROR_REQUIRED",
    maxLength: "VUELIDATE_ERROR_MAXLENGTH@{max}",
    minLength: "VUELIDATE_ERROR_MINLENGTH@{min}",
    between: "VUELIDATE_ERROR_BETWEEN@{min} - {max}",
    numeric: "VUELIDATE_ERROR_NUMERIC",
    isStartsWithZ: "VUELIDATE_ERROR_IS_START_WITH_Z"
  }
});

Vue.config.productionTip = false;

export default new Vue({
  async created() {
    apiService.initialize(app.apiURL);
    await this.$store.dispatch("languageModule/GET_LANGUAGES", null, {
      root: true
    });
    await this.$store.dispatch("languageModule/GET_DICTIONARIES", null, {
      root: true
    });
    await this.getUser();
    languageServices.getDictionary(2);
  },
  computed: {
    ...mapState({
      dictionary: (state) => state.languageModule.dictionary,
      tableFilters: (state) => state.languageModule.tableFilters,
      parameters: (state) => state.parameterModule.parameters
    })
  },
  methods: {
    async getUser() {
      let user = store.state.login.user;

      if (user === null) {
        let userInfo = localStorageService.get("user"),
          cognito_token = localStorageService.get("cngtkn");

        if (
          cognito_token !== null &&
          userInfo !== null &&
          userInfo !== "undefined" &&
          this.$route.path !== "/auth/login"
        ) {
          await this.$store.dispatch("login/GET_USER_BY_EMAIL", userInfo.email);
          await this.$store.dispatch("bookmarksModule/GET_BOOKMARKS");
          await this.$store.dispatch("parameterModule/GET_PARAMETERS");
        } else {
          if (
            !this.$utils.isNullOrUndefined(this.$router.currentRoute) &&
            this.$route.path !== "/auth/login" &&
            this.$router.currentRoute.fullPath.indexOf("status") === -1
          ) {
            this.$router.push("/auth/login");
          }
        }
      } else {
        await this.$store.dispatch("bookmarksModule/GET_BOOKMARKS");
        await this.$store.dispatch("parameterModule/GET_PARAMETERS");
      }
    },
    changeHeader(headerList) {
      headerList.forEach((x) => {
        x.title = this.dictionary[x.langDefinition];
      });

      return headerList;
    }
  },
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
