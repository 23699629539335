const app = {
  environment: "dev",
  apiURL: require("@/configs/configs." + process.env.NODE_ENV + ".json").apiUrl
    .url,
  staticUrl: require("@/configs/configs." + process.env.NODE_ENV + ".json")
    .staticUrl.url,
  loginWithActiveDirectory: require("@/configs/configs." +
    process.env.NODE_ENV +
    ".json").loginWithActiveDirectory,
  redirectUri: require("@/configs/configs." + process.env.NODE_ENV + ".json")
    .redirectUri,
  dataStates: { Added: 1, Updated: 2, Deleted: 3, NoChange: 4 },
  menuObjectTypes: { page: 1, toolbar: 2 },
  tableFilters: {
    string: [
      "Starts with",
      "Ends with",
      "Contains",
      "Doesn't contain",
      "Equals",
      "Not equals",
      "Show empty"
    ],
    int: ["Equals", "Not equal", "Show empty"],
    date: ["From", "To", "Show empty"],
    enum_activepassive: ["True", "False", "Show empty"],
    enum_yesno: ["Yes", "No", "Show empty"]
  },
  enums: [
    {
      activePassive: [
        {
          value: true,
          desc: "TABLE_FILTER_ENUM_ACTIVE_PASSIVE_ACTIVE"
        },
        {
          value: false,
          desc: "TABLE_FILTER_ENUM_ACTIVE_PASSIVE_PASSIVE"
        }
      ]
    },
    {
      yesNo: [
        {
          value: true,
          desc: "TABLE_FILTER_ENUM_YES_NO_YES"
        },
        {
          value: false,
          desc: "TABLE_FILTER_ENUM_YES_NO_NO"
        }
      ]
    },
    {
      CalculationType: [
        {
          value: 1,
          desc: "TABLE_FILTER_ENUM_PRICE"
        },
        {
          value: 2,
          desc: "TABLE_FILTER_ENUM_UNIT_OF_WEIGHT"
        },
        {
          value: 3,
          desc: "TABLE_FILTER_ENUM_OVER_THE_RATE"
        }
      ]
    },
    {
      DistributionType: [
        {
          value: 1,
          desc: "TABLE_FILTER_ENUM_FREIGHT"
        },
        {
          value: 2,
          desc: "TABLE_FILTER_ENUM_FREIGHT_WEIGHT"
        },
        {
          value: 3,
          desc: "TABLE_FILTER_ENUM_TOTAL"
        }
      ]
    },
    {
      DeclarationType: [
        {
          value: 1,
          desc: "TABLE_FILTER_ENUM_BONDED"
        },
        {
          value: 2,
          desc: "TABLE_FILTER_ENUM_DIRECT"
        }
      ]
    },
    {
      DdsFlagTypes: [
        {
          value: 1,
          desc: "TABLE_FILTER_ENUM_DDSFLAGS_PO_VISIBILITY"
        },
        {
          value: 2,
          desc: "TABLE_FILTER_ENUM_DDSFLAGS_PU_DEVIATION"
        },
        {
          value: 3,
          desc: "TABLE_FILTER_ENUM_DDSFLAGS_TT_DEVIATION"
        },
        {
          value: 4,
          desc: "TABLE_FILTER_ENUM_DDSFLAGS_ETA_DEVIATION"
        },
        {
          value: 5,
          desc: "TABLE_FILTER_ENUM_DDSFLAGS_CC_DEVIATION"
        },
        {
          value: 6,
          desc: "TABLE_FILTER_ENUM_DDSFLAGS_GR_DEVIATION"
        },
        {
          value: 7,
          desc: "TABLE_FILTER_ENUM_DDSFLAGS_CC_GR_DEVIATION"
        },
        {
          value: 8,
          desc: "TABLE_FILTER_ENUM_DDSFLAGS_MISSING_INCOTERM"
        },
        {
          value: 9,
          desc: "TABLE_FILTER_ENUM_DDSFLAGS_MISSING_TRANSPORT_MODE"
        },
        {
          value: 10,
          desc: "TABLE_FILTER_ENUM_DDSFLAGS_ETA_CHANGE"
        },
        {
          value: 11,
          desc: "TABLE_FILTER_ENUM_DDSFLAGS_EXPECTED_ARRIVAL"
        },
        {
          value: 12,
          desc: "TABLE_FILTER_ENUM_DDSFLAGS_PU_DEVIATION_PU_COMPLETED"
        },
        {
          value: 13,
          desc: "TABLE_FILTER_ENUM_DDSFLAGS_PU_DEVIATION_NOT_SAILED"
        },
        {
          value: 14,
          desc: "TABLE_FILTER_ENUM_DDSFLAGS_PU_DEVIATION_SAILED"
        },
        {
          value: 15,
          desc: "TABLE_FILTER_ENUM_DDSFLAGS_TT_DEVIATION_ARRIVED"
        },
        {
          value: 16,
          desc: "TABLE_FILTER_ENUM_DDSFLAGS_DOUBLE_INBOUND"
        },
        {
          value: 17,
          desc: "TABLE_FILTER_ENUM_DDSFLAGS_EARLY_ARRIVAL"
        },
        {
          value: 18,
          desc: "TABLE_FILTER_ENUM_DDSFLAGS_LATE_ACK_ENTRY"
        },
        {
          value: 19,
          desc: "TABLE_FILTER_ENUM_DDSFLAGS_BOOKING_NOT_YET"
        },
        {
          value: 20,
          desc: "TABLE_FILTER_ENUM_DDSFLAGS_AB_ADHERENCE"
        }
      ]
    },
    {
      BondedInOut: [
        {
          value: 1,
          desc: "TABLE_FILTER_ENUM_BONDED_IN"
        },
        {
          value: 2,
          desc: "TABLE_FILTER_ENUM_BONDED_OUT"
        }
      ]
    },
    {
      OtherInvoicePostingCheck: [
        {
          value: 1,
          desc: "TABLE_FILTER_ENUM_APPROVE"
        },
        {
          value: 2,
          desc: "TABLE_FILTER_ENUM_RETURN_INVOICE"
        },
        {
          value: 3,
          desc: "TABLE_FILTER_ENUM_FI"
        },
        {
          value: 4,
          desc: "TABLE_FILTER_ENUM_OTHER"
        },
        {
          value: 5,
          desc: "TABLE_FILTER_ENUM_CONDITION"
        }
      ]
    },
    {
      OtherInvoicePostingStatus: [
        {
          value: 1,
          desc: "LABEL_INITIAL"
        },
        {
          value: 2,
          desc: "LABEL_SUCCESS"
        },
        {
          value: 3,
          desc: "LABEL_FAILED"
        }
      ]
    },
    {
      RunTimePeriod: [
        {
          value: 1,
          desc: "LABEL_RUNTIMEPERIOD_IMMEDIATELY"
        },
        {
          value: 2,
          desc: "LABEL_RUNTIMEPERIOD_MINUTE"
        },
        {
          value: 3,
          desc: "LABEL_RUNTIMEPERIOD_HOURLY"
        },
        {
          value: 4,
          desc: "LABEL_RUNTIMEPERIOD_DAILY"
        },
        {
          value: 5,
          desc: "LABEL_RUNTIMEPERIOD_WEEKLY"
        },
        {
          value: 6,
          desc: "LABEL_RUNTIMEPERIOD_MONTHLY"
        }
      ]
    },
    {
      WindowsServiceStatus: [
        {
          value: 1,
          desc: "LABEL_SERVICE_RUNNING"
        },
        {
          value: 2,
          desc: "LABEL_SERVICE_RUN_BEFORE"
        }
      ]
    },
    {
      CbExtraCostType: [
        {
          value: 1,
          desc: "TABLE_FILTER_ENUM_FREE_OF_CHARGE"
        },
        {
          value: 2,
          desc: "TABLE_FILTER_ENUM_BULK_MATERIAL"
        },
        {
          value: 3,
          desc: "TABLE_FILTER_ENUM_SUPHALAN"
        },
        {
          value: 4,
          desc: "TABLE_FILTER_ENUM_PER_CONTAINER"
        },
        {
          value: 5,
          desc: "TABLE_FILTER_ENUM_FEATURED_PROCESSOZ3"
        },
        {
          value: 6,
          desc: "TABLE_FILTER_ENUM_FEATURED_PROCESSOZ4"
        }
      ]
    },
    {
      GtipType: [
        {
          value: 1,
          desc: "TABLE_FILTER_ENUM_DTS"
        },
        {
          value: 2,
          desc: "TABLE_FILTER_ENUM_TSE"
        },
        {
          value: 3,
          desc: "TABLE_FILTER_ENUM_REGISTRATION"
        },
        {
          value: 4,
          desc: "TABLE_FILTER_ENUM_TAPDK"
        }
      ]
    },
    {
      SapShipmentStatus: [
        {
          value: 0,
          desc: "LABEL_STATUS_NOINFO"
        },
        {
          value: 1,
          desc: "LABEL_STATUS_PLANNED"
        },
        {
          value: 2,
          desc: "LABEL_STATUS_CHECKIN"
        },
        {
          value: 3,
          desc: "LABEL_STATUS_LOADING_STARTED"
        },
        {
          value: 4,
          desc: "LABEL_STATUS_LOADING_END"
        },
        {
          value: 5,
          desc: "LABEL_STATUS_SHIPMENT_COMPLETION"
        },
        {
          value: 6,
          desc: "LABEL_STATUS_SHIPMENT_STARTED"
        },
        {
          value: 7,
          desc: "LABEL_STATUS_SHIPMENT_FINISHED"
        }
      ]
    },
    {
      DdsFlagReason: [
        {
          value: "",
          desc: ""
        },
        {
          value: "First Available Vessel",
          desc: "LABEL_DDSREASON_FIRST_AVAILABLE_VESSEL"
        },
        {
          value: "Fast Track",
          desc: "LABEL_DDSREASON_FASTTRACK"
        },
        {
          value: "Liner Delay",
          desc: "LABEL_DDSREASON_LINERDELAY"
        },
        {
          value: "Lsp Delay On Truck Arrangement",
          desc: "LABEL_DDSREASON_LSPDELAYONTRUCKARRANGEMENT"
        },
        {
          value: "Ltl Consolidation",
          desc: "LABEL_DDSREASON_LTLCONSOLIDATION"
        },
        {
          value: "Mot Changing",
          desc: "LABEL_DDSREASON_MOTCHANGING"
        },
        {
          value: "PO Consolidation",
          desc: "LABEL_DDSREASON_POCONSOLIDATION"
        },
        {
          value: "Vendor Not Ready",
          desc: "LABEL_DDSREASON_VENDORNOTREADY"
        }
      ]
    },
    {
      Companies: [
        {
          value: "PHILSA",
          desc: "PHILSA"
        },
        {
          value: "JORDAN",
          desc: "JORDAN"
        },
        {
          value: "SENEGAL",
          desc: "SENEGAL"
        },
        {
          value: "SERBIA",
          desc: "SERBIA"
        },
        {
          value: "EGYPT",
          desc: "EGYPT"
        },
        {
          value: "ALGERIA",
          desc: "ALGERIA"
        }
      ]
    },
    {
      OceanRateType: [
        {
          value: 1,
          desc: "PreCarriage"
        },
        {
          value: 2,
          desc: "OceanFreight"
        },
        {
          value: 3,
          desc: "Port"
        },
        {
          value: 4,
          desc: "OnCarriage"
        }
      ]
    },
    {
      MultiModelRateType: [
        {
          value: 1,
          desc: "LABEL_MULTIMODELRATETYPE_CESME"
        },
        {
          value: 2,
          desc: "LABEL_MULTIMODELRATETYPE_PENDIK"
        }
      ]
    }
  ],
  timeZones: [
    {
      page: "BillOfLading",
      columns: ["blDate", "hblDate"]
    },
    {
      page: "ContainerTrackingView",
      columns: ["DeliveryDate", "returnTargetDate", "workOrderDate"]
    },
    {
      page: "CommercialInvoice",
      columns: ["dueDate"]
    },
    {
      page: "CommercialInvoiceDetail",
      columns: [
        "paymentDate",
        "tagvisDate",
        "customsDeclaration.customsDeclarationDate",
        "goodReceiptDate",
        "certificateDate"
      ]
    },
    {
      page: "CtrPostView",
      columns: ["sendDate"]
    },
    {
      page: "CustomsDeclaration",
      columns: ["summaryDeclarationDate", "taxBillDate"]
    },
    {
      page: "CustomsFee",
      columns: ["bankReceiptDate", "documentDate"]
    },
    {
      page: "DdsFlag",
      columns: ["flagDate"]
    },
    {
      page: "ExchangeRate",
      columns: ["Date"]
    },
    {
      page: "Expedited",
      columns: [
        "commercialInvoiceDetail.puDate",
        "commercialInvoiceDetail.shipment.ata",
        "commercialInvoiceDetail.transportOrder.partnerDeliveryDate"
      ]
    },
    {
      page: "ExpirationDateControlList",
      columns: ["expirationDate", "issueDate"]
    },
    {
      page: "FIControlAir",
      columns: ["airFreightInvoiceDate"]
    },
    {
      page: "FIControlMaster",
      columns: ["blDate"]
    },
    {
      page: "FIControlOcean",
      columns: [
        "portInvoiceDate",
        "onCarriageInvoiceDate",
        "oceanInvoiceDate",
        "preCarriageInvoiceDate"
      ]
    },
    {
      page: "FIControlOverland",
      columns: ["overlandFreightInvoiceDate"]
    },
    {
      page: "FreightInvoice",
      columns: ["receiptDate"]
    },
    {
      page: "ImportFromBondedWarehouse",
      columns: [
        "bondedDeclaration.customsDeclarationDate",
        "customsDeclaration.customsDeclarationDate",
        "grDate",
        "taxBillDate",
        "sentDate",
        "clearanceDate",
        "tapdkDocumentDate"
      ]
    },
    {
      page: "ImportPayment",
      columns: ["workorderDate", "grDate"]
    },
    {
      page: "InwardProcessingCertificate",
      columns: ["issueDate", "expirationDate"]
    },
    {
      page: "Material",
      columns: ["approveDate", "erfExpireDate"]
    },
    {
      page: "QueueProcess",
      columns: ["dateProceeded"]
    },
    {
      page: "OtherInvoicePosting",
      columns: ["postingDate", "invoiceReceiptDate"]
    },
    {
      page: "OutwardProcessingCertificate",
      columns: ["issueDate", "expirationDate"]
    },
    {
      page: "OrderDetail",
      columns: [
        "AbDate",
        "DDate",
        "GoodReceiptDate",
        "InboundDeliverySendDate",
        "InboundDeliveryCreatedDate",
        "InboundDeliveryChangedDate"
      ]
    },
    {
      page: "ReceiptCostPostView",
      columns: [
        "sendDate",
        "bankReceiptDate",
        "documentDate",
        "eInvoiceDocumentDate"
      ]
    },
    {
      page: "ReuseContainerTrackingDetail",
      columns: ["sendDate"]
    },
    {
      page: "RoleChangeLog",
      columns: ["changeDate"]
    },
    {
      page: "SapIntegrationLog",
      columns: ["timestamp"]
    },
    {
      page: "Shipment",
      columns: ["BookingDate", "CutOffDate"]
    },
    {
      page: "TransportOrder",
      columns: ["RequestedDeliveryDate", "commercialInvoiceDetail.puDate"]
    },
    {
      page: "TransportOrderReport",
      columns: ["RequestedDeliveryDate"]
    },
    {
      page: "UserDelegation",
      columns: ["startDate", "endDate"]
    },
    {
      page: "VDDReport",
      columns: ["LastRunTime", "ActualRunTime", "NextRunTime"]
    },
    {
      page: "WorkerMethod",
      columns: ["LastRunTime", "ActualRunTime", "NextRunTime"]
    },
    {
      page: "Common",
      columns: [
        "dateCreated",
        "dateUpdated",
        "IssueDate",
        "ExpirationDate",
        "EffectiveDate",
        "timeStamp",
        "invoiceDate",
        "receiptDate",
        "customsDeclarationDate",
        "partnerDeliveryDate",
        "puDate",
        "RequiredDate",
        "grDate"
      ]
    }
  ],
  selectedObj: {},
  selectedRows: [],
  selectedSubRows: [],
  searchArr: [],
  sortArr: [],
  requiredFieldsByArea: [],
  isFormValid: false,
  selected: 0,
  firstPage: 1,
  pageSize: 25,
  pageDivider: 5,
  decimalPoint: 2,
  editableListPageSize: 15
};

export default app;
