<template>
  <modal
    :name="name"
    :delay="delay"
    :resizable="resizable"
    :adaptive="adaptive"
    :draggable="draggable"
    :scrollable="scrollable"
    :reset="reset"
    :clickToClose="clickToClose"
    :transition="transition"
    :classes="classes"
    :width="getSize[0]"
    height="auto"
    :maxHeight="getSize[1]"
    size="medium"
  >
    <button class="modal-button" @click="$modal.hide(name)">
      <i class="las la-times close-icon" />
    </button>
    <div class="slot-div"> <slot></slot> </div>
  </modal>
</template>

<script>
  const MODAL_WIDTH = 656;
  const SCROLL_HEIGHT = 50;

  export default {
    name: "CtsModal",
    props: {
      name: {
        type: [String, Number],
        required: true
      },
      delay: {
        type: Number,
        default: 0
      },
      resizable: {
        type: Boolean,
        default: false
      },
      adaptive: {
        type: Boolean,
        default: false
      },
      draggable: {
        type: [Boolean, String],
        default: false
      },
      scrollable: {
        type: Boolean,
        default: true
      },
      reset: {
        type: Boolean,
        default: false
      },
      clickToClose: {
        type: Boolean,
        default: true
      },
      transition: {
        type: String,
        default: "pop-out"
      },
      classes: {
        type: [String, Array],
        default: "v--modal"
      },
      width: {
        type: [String, Number],
        default: MODAL_WIDTH
      },
      height: {
        type: [String, Number],
        default: "500"
      },
      minWidth: {
        type: Number,
        default: 0
      },
      minHeight: {
        type: Number,
        default: 0
      },
      maxWidth: {
        type: Number,
        default: Infinity
      },
      maxHeight: {
        type: Number,
        default: Infinity
      },
      size: {
        type: String,
        default: "medium"
      }
    },
    data() {
      return {
        selected: [],
        compHeight: 0
      };
    },
    watch: {
      content() {
        this.$nextTick(() => {
          this.compHeight =
            document.getElementsByClassName("v--modal").length > 0
              ? document.getElementsByClassName("v--modal")[0].scrollHeight
              : 250;
        });
      }
    },
    computed: {
      getSize() {
        let width =
            document.getElementsByClassName("app-wrapper")[0] != null
              ? document.getElementsByClassName("app-wrapper")[0].offsetWidth
              : 100,
          height = document.body.scrollHeight - SCROLL_HEIGHT;

        switch (this.size) {
          case "small":
            width = width / 4 + 100;
            break;
          case "medium":
            width = width / 2 + 200;
            break;
          case "large":
            width = width / 2 + 100;
            break;
          case "xlarge":
            width = width - 150;
            break;
        }

        return [width, height];
      }
    },
    mounted() {
      let modalElement = document.getElementsByClassName("v--modal")[0],
        isExist = typeof modalElement !== "undefined",
        modalMaxHeight = document.body.clientHeight - SCROLL_HEIGHT;

      if (isExist) {
        modalElement.style.maxHeight = modalMaxHeight + "px";
      }
    },
    created() {
      this.MODAL_WIDTH =
        window.innerWidth < MODAL_WIDTH ? MODAL_WIDTH / 2 : MODAL_WIDTH;
    }
  };
</script>

<style>
  @import "./ctsmodal.css";
</style>
