<template>
  <div class="profile-dropdown">
    <cts-modal
      name="bookmark"
      transition="pop-out"
      :draggable="false"
      :clickToClose="false"
    >
      <BookmarkOptions />
    </cts-modal>
    <div
      class="profile-menus"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      :class="{ pmenu: hover }"
    >
      <div
        id="profile"
        @click="profileClick()"
        style="text-align: center"
        @mouseover="hover = true"
        @mouseleave="hover = false"
        :class="{ active: hover }"
      >
        Profile
      </div>
      <hr />
      <div
        id="bookmark"
        @click="bookmarkClick()"
        style="text-align: center"
        @mouseover="hover = true"
        @mouseleave="hover = false"
        :class="{ active: hover }"
      >
        Bookmark
      </div>
      <hr />
      <div
        id="logout"
        @click="logoutClick()"
        style="text-align: center"
        @mouseover="hover = true"
        @mouseleave="hover = false"
        :class="{ active: hover }"
      >
        Logout
      </div>
    </div>
  </div>
</template>

<script>
  import CtsModal from "@/components/ui-components/CtsModal/CtsModal";
  import BookmarkOptions from "../../data-table/BookmarkOptions";
  import router from "../../../router";
  import localStorageService from "@/services/LocalStorageService.js";

  export default {
    name: "CtsDropdownProfile",
    components: {
      "cts-modal": CtsModal,
      BookmarkOptions
    },
    data() {
      return {
        hover: false
      };
    },
    methods: {
      bookmarkClick() {
        this.$parent.profileDrop = false;
        this.$modal.show("bookmark");
      },
      async logoutClick() {
        try {
          this.$parent.profileDrop = false;
          localStorageService.clear();
          router.push("/auth/login");
        } catch (error) {
          console.log("error signing out: ", error);
        }
      },
      profileClick() {
        this.$parent.profileDrop = false;
        this.$router.push("/profile");
      }
    }
  };
</script>

<style>
  @import "./ctsprofiledropdown.css";
</style>
