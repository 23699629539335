export const pageStateEnum = {
  NOCHANGE: 0,
  UPDATED: 1,
  NOTUPDATED: 2,
  CREATED: 3,
  NOTCREATED: 4,
  DELETED: 5,
  NOTDELETED: 6,
  NOTCOMPARE: 7,
  SAVED: 8,
  WARNING: 9,
  NOTFOUND: 10
};
