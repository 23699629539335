<template>
  <div>
    <div class="row col-md-12 col-lg-12 row">
      <div class="col-md-6 col-lg-6" style="text-align: left">
        <cts-input-group text="Input Group Multiselect" id="MultiSelect">
          <cts-multiselect
            slot="InputSlot"
            v-model="multiselectData"
            :model="multiselectData"
            label="heading"
            trackBy="id"
            id="MultiSelect"
            :isOneLine="true"
            url="/role/GetForMultiselect"
            :multiple="true"
            :closeOnSelect="false"
            name="MultiSelect"
            :isFormGroup="false"
          ></cts-multiselect>
          <div slot="InputAppendSlot" style="height: 100% !important">
            <button
              class="btn btn-success input-group-button"
              type="button"
              id="groupBtn"
            >
              <i class="las la-cloud"></i>
            </button>
          </div>
        </cts-input-group>
        <div class="form-group row">
          <div class="col-12">
            <div class="example" id="example">
              <h5>
                <pre> {{ exampleMultiselect }} </pre>
              </h5>
            </div>
          </div>
        </div>
        <hr />
        <cts-input-group text="Input Group File Input" id="singleFileInput">
          <cts-fileinput
            slot="InputSlot"
            name="username"
            id="singleFileInput"
            :multiple="false"
            :isFormGroup="false"
          />
          <div slot="InputAppendSlot" style="height: 100% !important">
            <button
              class="btn btn-primary input-group-button"
              type="button"
              id="groupBtn"
              ><i class="las la-share"></i
            ></button>
          </div>
        </cts-input-group>
        <div class="form-group row">
          <div class="col-12">
            <div class="example" id="example">
              <h5>
                <pre> {{ exampleFileInput }} </pre>
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-lg-6" style="text-align: left">
        <cts-input-group text="Input Group Flatpickr" id="date">
          <cts-flatpickr
            slot="InputSlot"
            :isFormGroup="false"
            v-model="flatpickrData"
            :model="flatpickrData"
            :time="true"
            placeholder="Select date and time"
            name="date"
            id="date"
          />
          <div slot="InputAppendSlot" style="height: 100% !important">
            <button
              class="btn btn-success input-group-button"
              type="button"
              id="groupBtn"
              >Two</button
            >
            <button
              class="btn btn-danger"
              type="button"
              id="groupBtn"
              style="height: 100% !important"
              >Buttons</button
            >
          </div>
        </cts-input-group>
        <div class="form-group row">
          <div class="col-12">
            <div class="example" id="example">
              <h5>
                <pre> {{ exampleFlatpickr }} </pre>
              </h5>
            </div>
          </div>
        </div>
        <hr />
        <cts-input-group text="Input Group Textarea" id="Textarea">
          <cts-textarea
            slot="InputSlot"
            name="description"
            id="Textarea"
            v-model="textAreaData"
            :model="textAreaData"
            :rows="5"
            placeholder="This is a multi line input."
            :isFormGroup="false"
          />
          <div slot="InputAppendSlot" style="height: 100% !important">
            <button
              class="btn btn-warning input-group-button"
              type="button"
              id="groupBtn_Textarea"
            >
              <i class="las la-paper-plane"></i>
            </button>
          </div>
        </cts-input-group>
        <div class="form-group row">
          <div class="col-12">
            <div class="example" id="example">
              <h5>
                <pre> {{ exampleTexarea }} </pre>
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CtsTextArea from "@/components/ui-components/CtsTextarea/CtsTextarea";
  import CtsFileinput from "@/components/ui-components/CtsFileInput/CtsFileInput";
  import CtsInputGroup from "@/components/ui-components/CtsInputGroup/CtsInputGroup";
  import CtsMultiselect from "@/components/ui-components/CtsMultiselect/CtsMultiselect";
  import CtsFlatpickr from "@/components/ui-components/CtsFlatpickr/CtsFlatpickr";

  export default {
    name: "InputGroupExamples",
    components: {
      "cts-fileinput": CtsFileinput,
      "cts-input-group": CtsInputGroup,
      "cts-textarea": CtsTextArea,
      "cts-multiselect": CtsMultiselect,
      "cts-flatpickr": CtsFlatpickr
    },
    data() {
      return {
        inputData: "",
        textAreaData: "",
        multiselectData: [],
        flatpickrData: "",
        exampleMultiselect:
          '<cts-input-group text="Input Group Multiselect" id="MultiSelect">' +
          "\n" +
          "          <cts-multiselect" +
          "\n" +
          '            slot="InputSlot"' +
          "\n" +
          '            v-model="multiselectData"' +
          "\n" +
          '            :model="multiselectData"' +
          "\n" +
          '            label="heading"' +
          "\n" +
          '            trackBy="id"' +
          "\n" +
          '            id="MultiSelect"' +
          "\n" +
          '            :isOneLine="true"' +
          "\n" +
          '            url="/role/GetForMultiselect"' +
          "\n" +
          '            :multiple="true"' +
          "\n" +
          '            :closeOnSelect="false"' +
          "\n" +
          '            name="MultiSelect"' +
          "\n" +
          '            :isFormGroup="false">' +
          "\n" +
          "          </cts-multiselect>" +
          "\n" +
          '          <div slot="InputAppendSlot" style="height: 100% !important;">' +
          "\n" +
          "            <button" +
          "\n" +
          '              class="btn btn-success"' +
          "\n" +
          '              type="button" id="groupBtn"' +
          "\n" +
          '              style="height: 100% !important;">' +
          "\n" +
          '              <i class="las la-cloud"></i>' +
          "\n" +
          "            </button>" +
          "\n" +
          "          </div>" +
          "\n" +
          "        </cts-input-group>",
        exampleFileInput:
          '<cts-input-group text="Input Group File Input" id="singleFileInput">' +
          "\n" +
          "          <cts-fileinput" +
          "\n" +
          '            slot="InputSlot"' +
          "\n" +
          '            name="username"' +
          "\n" +
          '            id="singleFileInput"' +
          "\n" +
          '            :multiple="false"' +
          "\n" +
          '            :isFormGroup="false"' +
          "\n" +
          "          />" +
          "\n" +
          '          <div slot="InputAppendSlot" style="height: 100% !important;">' +
          "\n" +
          "            <button" +
          "\n" +
          '              class="btn btn-primary"' +
          "\n" +
          '              type="button"' +
          "\n" +
          '              id="groupBtn"' +
          "\n" +
          '              style="height: 100% !important;"' +
          "\n" +
          '              ><i class="las la-share"></i' +
          "\n" +
          "            ></button>" +
          "\n" +
          "          </div>" +
          "\n" +
          "        </cts-input-group>",
        exampleFlatpickr:
          '<cts-input-group text="Input Group Flatpickr" id="date">' +
          "\n" +
          "          <cts-flatpickr" +
          "\n" +
          '            slot="InputSlot"' +
          "\n" +
          '            :isFormGroup="false"' +
          "\n" +
          '            v-model="flatpickrData"' +
          "\n" +
          '            :model="flatpickrData"' +
          "\n" +
          '            :time="true"' +
          "\n" +
          '            placeholder="Select date and time"' +
          "\n" +
          '            name="date" id="date"/>' +
          "\n" +
          '          <div slot="InputAppendSlot" style="height: 100% !important;">' +
          "\n" +
          "            <button" +
          "\n" +
          '              class="btn btn-success"' +
          "\n" +
          '              type="button"' +
          "\n" +
          '              id="groupBtn"' +
          "\n" +
          '              style="height: 100% !important;"' +
          "\n" +
          "              >Two</button>" +
          "\n" +
          "            <button" +
          "\n" +
          '              class="btn btn-danger"' +
          "\n" +
          '              type="button"' +
          "\n" +
          '              id="groupBtn"' +
          "\n" +
          '              style="height: 100% !important;"' +
          "\n" +
          "              >Buttons</button>" +
          "\n" +
          "          </div>" +
          "\n" +
          "        </cts-input-group>",
        exampleTexarea:
          '<cts-input-group text="Input Group Textarea" id="Textarea">' +
          "\n" +
          "          <cts-textarea" +
          "\n" +
          '            slot="InputSlot"' +
          "\n" +
          '            name="description"' +
          "\n" +
          '            id="Textarea"' +
          "\n" +
          '            v-model="textAreaData"' +
          "\n" +
          '            :model="textAreaData"' +
          "\n" +
          '            :rows="5"' +
          "\n" +
          '            placeholder="This is a multi line input."' +
          "\n" +
          '            :isFormGroup="false"' +
          "\n" +
          "          />" +
          "\n" +
          '          <div slot="InputAppendSlot" style="height: 100% !important;">' +
          "\n" +
          "            <button" +
          "\n" +
          '              class="btn btn-warning "' +
          "\n" +
          '              type="button"' +
          "\n" +
          '              id="groupBtn_Textarea"' +
          "\n" +
          '              style="height: 100% !important;"' +
          "\n" +
          '              ><i class="las la-paper-plane"></i' +
          "\n" +
          "            ></button>" +
          "\n" +
          "          </div>" +
          "\n" +
          "        </cts-input-group>"
      };
    }
  };
</script>
