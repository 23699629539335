import Noty from "noty";
import localStorageService from "@/services/LocalStorageService.js";

export default {
  showToastr: (type, text) => {
    new Noty({
      type: type,
      layout: "bottomRight",
      theme: "bootstrap-v4",
      text: text,
      timeout: false,
      progressBar: true,
      closeWith: ["button"],
      killer: true
    }).show();
  },
  pageStateToastr: (state, message) => {
    let type = "",
      text = "",
      pageStates = localStorageService.get("dictionary");

    console.log("state", state);

    switch (state) {
      case 1:
        type = "success";
        text = pageStates.PAGESTATE_UPDATED;
        break;
      case 2:
        type = "error";
        text = pageStates.PAGESTATE_NOTUPDATED;
        break;
      case 3:
        type = "success";
        text = pageStates.PAGESTATE_CREATED;
        break;
      case 4:
        type = "error";
        text = pageStates.PAGESTATE_NOTCREATED;
        break;
      case 5:
        type = "success";
        text = pageStates.PAGESTATE_DELETED;
        break;
      case 6:
        type = "error";
        text = pageStates.PAGESTATE_NOTDELETED;
        break;
      case 8:
        type = "success";
        text = pageStates.PAGESTATE_SAVED;
        break;
      case 9:
        type = "warning";
        text = pageStates.PAGESTATE_WARNING;
        break;
      case 10:
        type = "warning";
        text = pageStates.PAGESTATE_NOT_FOUND;
        break;
    }

    if (message !== undefined) {
      text += "<br />" + message;
    }

    new Noty({
      type: type,
      layout: "bottomRight",
      theme: "bootstrap-v4",
      text: text,
      timeout: false,
      progressBar: true,
      closeWith: ["button"],
      killer: true
    }).show();
  },
  selectOneRecord: () => {
    new Noty({
      type: "warning",
      layout: "bottomRight",
      theme: "bootstrap-v4",
      text: "Please select at least one record!",
      timeout: false,
      progressBar: true,
      closeWith: ["button"],
      killer: true
    }).show();
  }
};
