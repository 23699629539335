<template>
  <div class="wrapper">
    <div class="main">
      <h1>Your request is forbidden</h1>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Forbidden-Page"
  };
</script>

<style scoped>
  body,
  html,
  .wrapper {
    width: 100%;
    height: 100%;
  }
  .wrapper {
    display: table;
  }
  .main {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
</style>
