<template>
  <div
    class="row justify-content-center align-items-flex-start col-md-12 col-lg-12"
    style="position: relative"
  >
    <div class="col-md-6 col-lg-6" style="text-align: left">
      <div class="col-md-12 col-lg-12 row">
        <div class="col-md-6 col-lg-6">
          <div class="form-group">
            <button
              class="btn btn-toastr btn-success btn-block"
              @click="showToastr('success', 'Thats a success message')"
              >Success Toastr</button
            >
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="form-group">
            <button
              class="btn btn-toastr btn-warning btn-block"
              @click="showToastr('warning', 'Thats a warning message')"
              >Warning Toastr</button
            >
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="form-group">
            <button
              class="btn btn-toastr btn-danger btn-block"
              @click="showToastr('error', 'Thats a error message')"
              >Error Toastr</button
            >
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="form-group">
            <button
              class="btn btn-toastr btn-info btn-block"
              @click="showToastr('info', 'Thats a info message')"
              >Info Toastr</button
            >
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="example" id="example">
          <h5>
            <pre> {{ example }} </pre>
          </h5>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6" style="text-align: left">
      <ul>
        <li>
          <b>Options:</b>
          <ul>
            <li>text -> Type String (Required)</li>
            <li>type -> Type String (Required)</li>
            <li>Reference : <a href="https://ned.im/noty">Vue Noty</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  export default {
    name: "CtsToastrComponent",
    data() {
      return {
        example:
          "Usage in the function:" +
          "\n" +
          " showToastr(type, text) {" +
          "\n" +
          "   this.$CtsToastr.showToastr(type, text);" +
          "\n" +
          "  },"
      };
    },
    methods: {
      showToastr(type, text) {
        this.$CtsToastr.showToastr(type, text);
      }
    }
  };
</script>
<style>
  .btn-toastr {
    height: 30px;
  }
</style>
