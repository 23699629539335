const initialState = () => {
  let storageLanguage =
    localStorage.language != null && localStorage.language != ""
      ? JSON.parse(localStorage.language)
      : null;

  if (storageLanguage != null && storageLanguage != "undefined") {
    return {
      languageId: storageLanguage.languageId,
      languages: storageLanguage.languages,
      dictionary: storageLanguage.dictionary,
      tableFilters: storageLanguage.tableFilters
    };
  }

  return {
    languageId: 2,
    languages: null,
    dictionary: null,
    tableFilters: null
  };
};
const state = () => initialState();

import axios from "axios";
import utils from "@/common/utils/utils";

export const languageModule = {
  namespaced: true,
  state: state,
  mutations: {
    resetModuleState(state) {
      Object.assign(state, initialState());
    },
    getDictionary: function (state, response) {
      let dictionary = {},
        storageLanguage = !utils.isNullOrWhiteSpace(localStorage.language)
          ? JSON.parse(localStorage.language)
          : null;

      response.data.forEach((item) => {
        Object.defineProperty(dictionary, item.definition, {
          get: function () {
            return item.description;
          },
          enumerable: true
        });
      });

      state.dictionary = dictionary;
      state.tableFilters = {
        string: [
          {
            value: "Starts with",
            title: dictionary.TABLE_FILTER_STRING_STARTS_WITH
          },
          {
            value: "Ends with",
            title: dictionary.TABLE_FILTER_STRING_ENDS_WITH
          },
          {
            value: "Contains",
            title: dictionary.TABLE_FILTER_STRING_CONTAINS
          },
          {
            value: "Doesn't contain",
            title: dictionary.TABLE_FILTER_STRING_DOESNT_CONTAINS
          },
          {
            value: "Equals",
            title: dictionary.TABLE_FILTER_STRING_EQUALS
          },
          {
            value: "Not equals",
            title: dictionary.TABLE_FILTER_STRING_NOT_EQUALS
          },
          {
            value: "Show empty",
            title: dictionary.TABLE_FILTER_STRING_SHOW_EMPTY
          }
        ],
        int: [
          {
            value: "Equals",
            title: dictionary.TABLE_FILTER_INT_EQUALS
          },
          {
            value: "Not equals",
            title: dictionary.TABLE_FILTER_INT_NOT_EQUALS
          },
          {
            value: "Show empty",
            title: dictionary.TABLE_FILTER_INT_SHOW_EMPTY
          }
        ],
        decimal: [
          {
            value: "Equals",
            title: dictionary.TABLE_FILTER_INT_EQUALS
          },
          {
            value: "Not equals",
            title: dictionary.TABLE_FILTER_INT_NOT_EQUALS
          },
          {
            value: "Show empty",
            title: dictionary.TABLE_FILTER_INT_SHOW_EMPTY
          }
        ],
        float: [
          {
            value: "Equals",
            title: dictionary.TABLE_FILTER_INT_EQUALS
          },
          {
            value: "Not equals",
            title: dictionary.TABLE_FILTER_INT_NOT_EQUALS
          },
          {
            value: "Show empty",
            title: dictionary.TABLE_FILTER_INT_SHOW_EMPTY
          }
        ],
        date: [
          {
            value: "from",
            title: dictionary.TABLE_FILTER_DATE_FROM
          },
          {
            value: "to",
            title: dictionary.TABLE_FILTER_DATE_TO
          },
          {
            value: "between",
            title: dictionary.TABLE_FILTER_DATE_BETWEEN
          },
          {
            value: "Show empty",
            title: dictionary.TABLE_FILTER_DATE_SHOW_EMPTY
          }
        ],
        datetime: [
          {
            value: "from",
            title: dictionary.TABLE_FILTER_DATE_FROM
          },
          {
            value: "to",
            title: dictionary.TABLE_FILTER_DATE_TO
          },
          {
            value: "between",
            title: dictionary.TABLE_FILTER_DATE_BETWEEN
          },
          {
            value: "Show empty",
            title: dictionary.TABLE_FILTER_DATE_SHOW_EMPTY
          }
        ],
        enum_activepassive: [
          {
            value: "True",
            title: dictionary.TABLE_FILTER_ENUM_ACTIVE_PASSIVE_ACTIVE,
            cssClass: "badge badge-success"
          },
          {
            value: "False",
            title: dictionary.TABLE_FILTER_ENUM_ACTIVE_PASSIVE_PASSIVE,
            cssClass: "badge badge-danger"
          },
          {
            value: "Show empty",
            title: dictionary.TABLE_FILTER_ENUM_ACTIVE_PASSIVE_SHOW_EMPTY,
            cssClass: "badge badge-danger"
          }
        ],
        enum_yesno: [
          {
            value: "True",
            title: dictionary.TABLE_FILTER_ENUM_YES_NO_YES,
            cssClass: "badge badge-success"
          },
          {
            value: "False",
            title: dictionary.TABLE_FILTER_ENUM_YES_NO_NO,
            cssClass: "badge badge-danger"
          },
          {
            value: "Show empty",
            title: dictionary.TABLE_FILTER_ENUM_YES_NO_SHOW_EMPTY,
            cssClass: "badge badge-danger"
          }
        ],
        enum_integrationResult: [
          {
            value: 0,
            title:
              dictionary.TABLE_FILTER_ENUM_INTEGRATION_RESULT_NOT_SPECIFIED,
            cssClass: "badge badge-light"
          },
          {
            value: 1,
            title: dictionary.TABLE_FILTER_ENUM_INTEGRATION_RESULT_SUCCESS,
            cssClass: "badge badge-success"
          },
          {
            value: 2,
            title: dictionary.TABLE_FILTER_ENUM_INTEGRATION_RESULT_FAILED,
            cssClass: "badge badge-danger"
          },
          {
            value: 3,
            title:
              dictionary.TABLE_FILTER_ENUM_INTEGRATION_RESULT_PARTIALLY_COMPLETED,
            cssClass: "badge badge-warning"
          },
          {
            value: 4,
            title: dictionary.TABLE_FILTER_ENUM_INTEGRATION_RESULT_SKIPPED,
            cssClass: "badge badge-light"
          },
          {
            value: 5,
            title:
              dictionary.TABLE_FILTER_ENUM_INTEGRATION_RESULT_VALIDATION_ERROR,
            cssClass: "badge badge-warning"
          }
        ],
        enum_subModule: [
          {
            value: 0,
            title: dictionary.TABLE_FILTER_ENUM_INTEGRATION_RESULT_NOT_SPECIFIED
          },
          {
            value: 1,
            title: "BillOfLading"
          },
          {
            value: 2,
            title: "FreightInvoice"
          },
          {
            value: 3,
            title: "CommercialInvoice"
          },
          {
            value: 4,
            title: "Insurance"
          },
          {
            value: 5,
            title: "Shipment"
          },
          {
            value: 6,
            title: "Material"
          }
        ],
        enum_queue_system_type: [
          {
            value: 0,
            title: dictionary.TABLE_FILTER_ENUM_INTEGRATION_RESULT_NOT_SPECIFIED
          },
          {
            value: 1,
            title: "Shipmenttt"
          },
          {
            value: 2,
            title: "Commercial Invoice Detail"
          },
          {
            value: 3,
            title: "Extra Cost"
          },
          {
            value: 4,
            title: "InboundDeliveryCreateCheck"
          }
        ],
        enum_system_workstate: [
          {
            value: 0,
            title: dictionary.TABLE_FILTER_ENUM_INTEGRATION_RESULT_NOT_SPECIFIED
          },
          {
            value: 1,
            title: "Add"
          },
          {
            value: 2,
            title: "Update"
          },
          {
            value: 3,
            title: "Both"
          }
        ],
        enum_dataStatus: [
          {
            value: 0,
            title: dictionary.TABLE_FILTER_ENUM_DATA_STATUS_NO_CHANGE
          },
          {
            value: 1,
            title: dictionary.TABLE_FILTER_ENUM_DATA_STATUS_ADDED
          },
          {
            value: 2,
            title: dictionary.TABLE_FILTER_ENUM_DATA_STATUS_NOT_ADDED
          },
          {
            value: 3,
            title: dictionary.TABLE_FILTER_ENUM_DATA_STATUS_UPDATED
          },
          {
            value: 4,
            title: dictionary.TABLE_FILTER_ENUM_DATA_STATUS_NOT_UPDATED
          },
          {
            value: 5,
            title: dictionary.TABLE_FILTER_ENUM_DATA_STATUS_DELETED
          },
          {
            value: 6,
            title: dictionary.TABLE_FILTER_ENUM_DATA_STATUS_NOT_DELETED
          },
          {
            value: 7,
            title: dictionary.TABLE_FILTER_ENUM_DATA_STATUS_SHOW
          },
          {
            value: 8,
            title: dictionary.TABLE_FILTER_ENUM_DATA_STATUS_HIDE
          },
          {
            value: 9,
            title: dictionary.TABLE_FILTER_ENUM_DATA_STATUS_DO_NOT_COMPARE
          },
          {
            value: 10,
            title: dictionary.TABLE_FILTER_ENUM_DATA_STATUS_PARTIAL_ADDED
          },
          {
            value: 11,
            title: dictionary.TABLE_FILTER_ENUM_DATA_STATUS_PARTIAL_UPDATED
          },
          {
            value: 12,
            title: dictionary.TABLE_FILTER_ENUM_DATA_STATUS_PARTIAL_DELETED
          }
        ],
        enum_systemType: [
          {
            value: 0,
            title: dictionary.TABLE_FILTER_ENUM_INTEGRATION_RESULT_NOT_SPECIFIED
          },
          {
            value: 1,
            title: dictionary.TABLE_FILTER_ENUM_SYSTEM_TYPE_MATERIAL
          },
          {
            value: 2,
            title: dictionary.TABLE_FILTER_ENUM_SYSTEM_TYPE_COMMERCIAL_INVOICE
          },
          {
            value: 3,
            title:
              dictionary.TABLE_FILTER_ENUM_SYSTEM_TYPE_COMMERCIAL_INVOICE_DETAIL
          },
          {
            value: 4,
            title: dictionary.TABLE_FILTER_ENUM_SYSTEM_TYPE_TRUCK_RATE
          },
          {
            value: 5,
            title: dictionary.TABLE_FILTER_ENUM_SYSTEM_TYPE_TRUCK_RATE_PALLET
          },
          {
            value: 7,
            title: dictionary.TABLE_FILTER_ENUM_SYSTEM_TYPE_GTIP_PAYMENT_TYPE
          },
          {
            value: 8,
            title: dictionary.TABLE_FILTER_ENUM_SYSTEM_TYPE_BILL_OF_LADING
          },
          {
            value: 9,
            title: dictionary.TABLE_FILTER_ENUM_SYSTEM_TYPE_BILL_OF_LADING
          },
          {
            value: 10,
            title: dictionary.TABLE_FILTER_ENUM_SYSTEM_TYPE_AIR_RATE
          },
          {
            value: 11,
            title: dictionary.TABLE_FILTER_ENUM_SYSTEM_TYPE_SHIPMENT
          },
          {
            value: 12,
            title: dictionary.TABLE_FILTER_ENUM_SYSTEM_CC_COST_RATE
          },
          {
            value: 13,
            title: dictionary.TABLE_FILTER_CONTAINER_TRACKING
          },
          {
            value: 14,
            title: dictionary.TABLE_FILTER_IMPORT_FROM_BONDED_WAREHOUSE
          },
          {
            value: 15,
            title: dictionary.TABLE_FILTER_COMMERCIAL_INVOICE_EXTRA_COST
          }
        ],
        enums_statusType: [
          {
            value: 1,
            title: dictionary.LABEL_STATUS_WAITING_APPROVAL,
            cssClass: "badge badge-warning"
          },
          {
            value: 2,
            title: dictionary.LABEL_STATUS_APPROVED,
            cssClass: "badge badge-success"
          },
          {
            value: 3,
            title: dictionary.LABEL_STATUS_REJECTED,
            cssClass: "badge badge-danger"
          }
        ],
        enums_bondedStatusType: [
          {
            value: 16,
            title: dictionary.LABEL_INITIAL,
            cssClass: "badge badge-warning"
          },
          {
            value: 7,
            title: dictionary.LABEL_STATUS_WORK_ORDER_SENT,
            cssClass: "badge badge-success"
          },
          {
            value: 17,
            title: dictionary.LABEL_CUSTOMS_CLEARED,
            cssClass: "badge badge-danger"
          }
        ],
        enum_calculation_type: [
          {
            value: 1,
            title: dictionary.TABLE_FILTER_ENUM_PRICE
          },
          {
            value: 2,
            title: dictionary.TABLE_FILTER_ENUM_UNIT_OF_WEIGHT
          },
          {
            value: 3,
            title: dictionary.TABLE_FILTER_ENUM_OVER_THE_RATE
          }
        ],
        enum_distribution_type: [
          {
            value: 1,
            title: dictionary.TABLE_FILTER_ENUM_FREIGHT
          },
          {
            value: 2,
            title: dictionary.TABLE_FILTER_ENUM_FREIGHT_WEIGHT
          },
          {
            value: 3,
            title: dictionary.TABLE_FILTER_ENUM_TOTAL
          }
        ],
        enum_declaration_type: [
          {
            value: 1,
            title: dictionary.TABLE_FILTER_ENUM_BONDED
          },
          {
            value: 2,
            title: dictionary.TABLE_FILTER_ENUM_DIRECT
          }
        ],
        enum_bonded_inOut: [
          {
            value: 1,
            title: dictionary.TABLE_FILTER_ENUM_BONDED_IN
          },
          {
            value: 2,
            title: dictionary.TABLE_FILTER_ENUM_BONDED_OUT
          }
        ],
        enum_financeOperationType: [
          {
            value: 1,
            title: dictionary.TABLE_FILTER_ENUM_POST_INVOICE
          },
          {
            value: 2,
            title: dictionary.TABLE_FILTER_ENUM_DOWN_PAYMENT_REQUEST
          }
        ],
        enum_shift: [
          {
            value: 1,
            title: dictionary.TABLE_FILTER_ENUM_A
          },
          {
            value: 2,
            title: dictionary.TABLE_FILTER_ENUM_B
          },
          {
            value: 2,
            title: dictionary.TABLE_FILTER_ENUM_C
          }
        ],
        enum_otherInvoicePostingCheck: [
          {
            value: 1,
            title: dictionary.TABLE_FILTER_ENUM_APPROVE
          },
          {
            value: 2,
            title: dictionary.TABLE_FILTER_ENUM_RETURN_INVOICE
          },
          {
            value: 3,
            title: dictionary.TABLE_FILTER_ENUM_FI
          },
          {
            value: 4,
            title: dictionary.TABLE_FILTER_ENUM_OTHER
          },
          {
            value: 5,
            title: dictionary.TABLE_FILTER_ENUM_CONDITION
          }
        ],
        enum_otherInvoicePostingStatus: [
          {
            value: 1,
            title: dictionary.LABEL_INITIAL
          },
          {
            value: 2,
            title: dictionary.LABEL_SUCCESS
          },
          {
            value: 3,
            title: dictionary.LABEL_FAILED
          }
        ],
        enum_runtimePeriod: [
          {
            value: 1,
            title: dictionary.LABEL_RUNTIMEPERIOD_IMMEDIATELY
          },
          {
            value: 2,
            title: dictionary.LABEL_RUNTIMEPERIOD_MINUTE
          },
          {
            value: 3,
            title: dictionary.LABEL_RUNTIMEPERIOD_HOURLY
          },
          {
            value: 4,
            title: dictionary.LABEL_RUNTIMEPERIOD_DAILY
          },
          {
            value: 5,
            title: dictionary.LABEL_RUNTIMEPERIOD_WEEKLY
          },
          {
            value: 6,
            title: dictionary.LABEL_RUNTIMEPERIOD_MONTHLY
          }
        ],
        enum_windowsServiceStatus: [
          {
            value: 1,
            title: dictionary.LABEL_SERVICE_RUNNING
          },
          {
            value: 2,
            title: dictionary.LABEL_SERVICE_RUN_BEFORE
          }
        ],
        enum_system_ctrType: [
          {
            value: 1,
            title: dictionary.LABEL_CTR_WITH_PO
          },
          {
            value: 2,
            title: dictionary.LABEL_CTR_WITH_STO
          },
          {
            value: 3,
            title: dictionary.LABEL_CTR_WITHOUT_PO
          }
        ],
        enum_cbExtraCost_type: [
          {
            value: 1,
            title: dictionary.TABLE_FILTER_ENUM_FREE_OF_CHARGE
          },
          {
            value: 2,
            title: dictionary.TABLE_FILTER_ENUM_BULK_MATERIAL
          },
          {
            value: 3,
            title: dictionary.TABLE_FILTER_ENUM_SUPHALAN
          },
          {
            value: 4,
            title: dictionary.TABLE_FILTER_ENUM_PER_CONTAINER
          },
          {
            value: 5,
            title: dictionary.TABLE_FILTER_ENUM_FEATURED_PROCESSOZ3
          },
          {
            value: 6,
            title: dictionary.TABLE_FILTER_ENUM_FEATURED_PROCESSOZ4
          }
        ],
        enum_gtip_type: [
          {
            value: 1,
            title: dictionary.TABLE_FILTER_ENUM_DTS
          },
          {
            value: 2,
            title: dictionary.TABLE_FILTER_ENUM_TSE
          },
          {
            value: 3,
            title: dictionary.TABLE_FILTER_ENUM_REGISTRATION
          },
          {
            value: 4,
            title: dictionary.TABLE_FILTER_ENUM_TAPDK
          }
        ],
        enum_system_bmrType: [
          {
            value: 1,
            title: dictionary.LABEL_BMR_WITH_PO
          },
          {
            value: 2,
            title: dictionary.LABEL_BMR_WITHOUT_PO
          }
        ],
        enum_ddsflag_reason: [
          {
            value: 0,
            title: ""
          },
          {
            value: 1,
            title: dictionary.LABEL_DDSREASON_CONTRACT_ISSUE
          },
          {
            value: 2,
            title: dictionary.LABEL_DDSREASON_FASTTRACK
          },
          {
            value: 3,
            title: dictionary.LABEL_DDSREASON_LINERDELAY
          },
          {
            value: 4,
            title: dictionary.LABEL_DDSREASON_LSPDELAYONTRUCKARRANGEMENT
          },
          {
            value: 5,
            title: dictionary.LABEL_DDSREASON_LTLCONSOLIDATION
          },
          {
            value: 6,
            title: dictionary.LABEL_DDSREASON_MOTCHANGING
          },
          {
            value: 7,
            title: dictionary.LABEL_DDSREASON_POCONSOLIDATION
          },
          {
            value: 8,
            title: dictionary.LABEL_DDSREASON_VENDORNOTREADY
          },
          {
            value: 9,
            title: dictionary.LABEL_DDSREASON_WAITINGDETAILS
          }
        ],
        enum_ddsflag_type: [
          {
            value: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_PO_VISIBILITY,
            title: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_PO_VISIBILITY
          },
          {
            value: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_PU_DEVIATION,
            title: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_PU_DEVIATION
          },
          {
            value: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_TT_DEVIATION,
            title: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_TT_DEVIATION
          },
          {
            value: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_ETA_DEVIATION,
            title: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_ETA_DEVIATION
          },
          {
            value: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_CC_DEVIATION,
            title: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_CC_DEVIATION
          },
          {
            value: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_GR_DEVIATION,
            title: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_GR_DEVIATION
          },
          {
            value: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_CC_GR_DEVIATION,
            title: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_CC_GR_DEVIATION
          },
          {
            value: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_MISSING_INCOTERM,
            title: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_MISSING_INCOTERM
          },
          {
            value: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_MISSING_TRANSPORT_MODE,
            title: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_MISSING_TRANSPORT_MODE
          },
          {
            value: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_ETA_CHANGE,
            title: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_ETA_CHANGE
          },
          {
            value: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_EXPECTED_ARRIVAL,
            title: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_EXPECTED_ARRIVAL
          },
          {
            value:
              dictionary.TABLE_FILTER_ENUM_DDSFLAGS_PU_DEVIATION_PU_COMPLETED,
            title:
              dictionary.TABLE_FILTER_ENUM_DDSFLAGS_PU_DEVIATION_PU_COMPLETED
          },
          {
            value:
              dictionary.TABLE_FILTER_ENUM_DDSFLAGS_PU_DEVIATION_NOT_SAILED,
            title: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_PU_DEVIATION_NOT_SAILED
          },
          {
            value: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_PU_DEVIATION_SAILED,
            title: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_PU_DEVIATION_SAILED
          },
          {
            value: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_TT_DEVIATION_ARRIVED,
            title: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_TT_DEVIATION_ARRIVED
          },
          {
            value: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_DOUBLE_INBOUND,
            title: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_DOUBLE_INBOUND
          },
          {
            value: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_EARLY_ARRIVAL,
            title: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_EARLY_ARRIVAL
          },
          {
            value: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_LATE_ACK_ENTRY,
            title: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_LATE_ACK_ENTRY
          },
          {
            value: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_BOOKING_NOT_YET,
            title: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_BOOKING_NOT_YET
          },
          {
            value: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_AB_ADHERENCE,
            title: dictionary.TABLE_FILTER_ENUM_DDSFLAGS_AB_ADHERENCE
          }
        ],
        enum_companies: [
          {
            value: "PHILSA",
            title: "PHILSA"
          },
          {
            value: "JORDAN",
            title: "JORDAN"
          },
          {
            value: "SENEGAL",
            title: "SENEGAL"
          },
          {
            value: "SERBIA",
            title: "SERBIA"
          },
          {
            value: "EGYPT",
            title: "EGYPT"
          },
          {
            value: "ALGERIA",
            title: "ALGERIA"
          }
        ],
        enum_multiModelRate: [
          {
            value: 1,
            title: dictionary.TABLE_FILTER_ENUM_Cesme
          },
          {
            value: 2,
            title: dictionary.TABLE_FILTER_ENUM_Pendik
          }
        ]
      };

      if (utils.isNullOrUndefined(storageLanguage)) {
        localStorage.setItem("language", JSON.stringify(state));
        return;
      }
      if (
        !utils.isNullOrUndefined(storageLanguage) &&
        storageLanguage.dictionary != dictionary
      ) {
        localStorage.setItem("language", JSON.stringify(state));
      }
      // }
    },
    getLanguage: function (state, response) {
      state.languages = response;
    },
    getLanguageId: function (state, id) {
      state.languageId = id;
    }
  },
  actions: {
    RESET_APP_STATE({ commit }) {
      commit("resetModuleState");
    },
    GET_DICTIONARIES: ({ commit, state }, payload) => {
      if (payload != null && payload != state.languageId) {
        commit("getLanguageId", payload);
        axios
          .get("languagedictionary/GetById", {
            params: {
              languageId: payload
            }
          })
          .then((response) => {
            if (utils.isResponseOk(response)) {
              commit("getDictionary", response);
            }
            Promise.resolve(response);
          })
          .catch((error) => {
            console.log("error on language state getdictionary() " + error);
            Promise.reject(error);
          });
      } else {
        commit("getLanguageId", state.languageId);
        axios
          .get("languagedictionary/GetById", {
            params: {
              languageId: state.languageId
            }
          })
          .then((response) => {
            if (utils.isResponseOk(response)) {
              commit("getDictionary", response);
            }
            Promise.resolve(response);
          })
          .catch((error) => {
            console.log("error on language state getdictionary() " + error);
            Promise.reject(error);
          });
      }
    },
    GET_LANGUAGES: async ({ commit }) => {
      await axios
        .get("language")
        .then((response) => {
          if (utils.isResponseOk(response) && response.data != null) {
            commit("getLanguage", response.data.list);
          }
          Promise.resolve(response);
        })
        .catch((error) => {
          console.log("error on language state getlanguage() " + error);
          Promise.reject(error);
        });
    }
  }
};
