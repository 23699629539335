const initialState = () => {
  return {
    bookmarks: null
  };
};
const state = () => initialState();

import axios from "axios";
import utils from "@/common/utils/utils";

export const bookmarksModule = {
  namespaced: true,
  state: state,
  mutations: {
    resetModuleState(state) {
      Object.assign(state, initialState());
    },
    setBookmarks(state, payload) {
      if (payload !== null) {
        state.bookmarks = payload;
      }
    }
  },
  actions: {
    RESET_APP_STATE({ commit }) {
      commit("resetModuleState");
    },
    GET_BOOKMARKS({ commit }) {
      axios
        .get("userbookmark")
        .then((response) => {
          if (utils.isResponseOk(response)) {
            commit("setBookmarks", response.data.list);
          }

          Promise.resolve(response);
        })
        .catch((error) => {
          console.log("error on bookmark state getBookmarks() " + error);
          Promise.reject(error);
        });
    },
    ADD_BOOKMARK({ commit }, payload) {
      axios
        .post("userbookmark", {
          menuId: payload.id
        })
        .then((response) => {
          if (utils.isResponseOk(response)) {
            commit("setBookmarks", response.data.list);
          }

          Promise.resolve(response);
        })
        .catch((error) => {
          console.log("error on bookmark state addBookmark() " + error);
          Promise.reject(error);
        });
    },
    REMOVE_BOOKMARK({ commit }, payload) {
      axios
        .delete("userbookmark/" + payload.id)
        .then((response) => {
          if (utils.isResponseOk(response)) {
            commit("setBookmarks", response.data.list);
          }

          Promise.resolve(response);
        })
        .catch((error) => {
          console.log("error on bookmark state removeBookmark() " + error);
          Promise.reject(error);
        });
    },
    UPDATE_BOOKMARKS({ commit }, payload) {
      axios
        .put("userbookmark/", payload)
        .then((response) => {
          if (utils.isResponseOk(response)) {
            commit("setBookmarks", response.data.list);
          }

          Promise.resolve(response);
        })
        .catch((error) => {
          console.log("error on bookmark state updateBookmarks() " + error);
          Promise.reject(error);
        });
    }
  }
};
