<template>
  <cts-collapse
    :header="this.$root.dictionary.TITLE_ORDER_COUNT_WIDGET"
    :isOpen="true"
    :isWidget="true"
    closedColor="#F2F2F2"
  >
    <i
      slot="widgetHeader"
      class="las la-sync-alt la-2x refreshButton"
      @click.stop="getOrderCount()"
    />
    <cts-flatpickr
      v-model="orderDate"
      :model="orderDate"
      slot="widgetHeader"
      labelClass="col-xs-12 col-sm-4 col-md-3 col-lg-3"
      inputClass="col-xs-12 col-sm-8 col-md-9 col-lg-9"
      :isFormGroup="false"
      class="dateFilter"
      mode="range"
      :checkAuth="false"
    />
    <bar-chart :chart-data="orderDataPoints" />
  </cts-collapse>
</template>

<script>
  import axios from "axios";
  import BarChart from "@/components/charts/BarChart.js";
  import LineChart from "@/components/charts/LineChart.js";
  import CtsCollapse from "@/components/ui-components/CtsCollapse/CtsCollapse";
  import CtsFlatpickr from "@/components/ui-components/CtsFlatpickr/CtsFlatpickr";

  export default {
    name: "OrderCountWidget",
    components: {
      "bar-chart": BarChart,
      "line-chart": LineChart,
      "cts-collapse": CtsCollapse,
      "cts-flatpickr": CtsFlatpickr
    },
    data: function () {
      return {
        orderDataPoints: {},
        orderDate: "",
        searchParameters: []
      };
    },
    methods: {
      generateSearchParameters(dataType, columnName, value, rule) {
        return {
          DataType: dataType,
          Column: columnName,
          Value: value,
          FilterRule: rule ? rule : ""
        };
      },
      async getOrderCount() {
        let labels = [],
          datas = [];

        if (this.orderDate != "") {
          this.searchParameters.push(
            this.generateSearchParameters(
              "date",
              "dateCreated",
              this.orderDate,
              "between"
            )
          );
        }

        let response = await axios.post("dashboard/GetOrderCount", {
          searchParameters: this.searchParameters
        });

        if (this.$utils.isResponseOk(response)) {
          response.data.forEach((order) => {
            labels.push(order.label);
            datas.push(order.count);
          });

          this.orderDataPoints = {
            labels: labels,
            datasets: [
              {
                label: this.$root.dictionary.LABEL_ORDER,
                backgroundColor: "#4FC3F7",
                borderColor: "#0288D1",
                data: datas
              }
            ]
          };
          this.searchParameters = [];
        } else {
          this.$utils.showErrorMessage();
        }
      }
    },
    async mounted() {
      let todayString = this.$utils.convertToStringToDate(new Date()),
        oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
      let oneWeekAgoString = this.$utils.convertToStringToDate(oneWeekAgo);
      this.orderDate = oneWeekAgoString + " - " + todayString;
      await this.getOrderCount();
    }
  };
</script>

<style scoped>
  .flatpickr-wrapper {
    float: right !important;
  }
</style>
