import app from "@/common/constants/app";
import axios from "axios";
import router from "@/router";
import utils from "../utils/utils";

const excelHelper = {
  async export(url, searchParameters, sortParameters, fileName, componentId) {
    await axios
      .post(
        url,
        {
          page: app.firstPage,
          size: app.pageSize,
          menuId: router.currentRoute.meta.menuId,
          componentId: !utils.isNullOrUndefined(componentId)
            ? componentId
            : router.currentRoute.meta.componentId,
          searchParameters: searchParameters,
          sortParameters: sortParameters
        },
        {
          responseType: "blob"
        }
      )
      .then((response) => {
        if (utils.isResponseOk(response)) {
          const url = window.URL.createObjectURL(new Blob([response.data]), {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            }),
            link = document.createElement("a");

          link.href = url;
          link.download =
            fileName +
            "_" +
            (Date.now() * Math.random()).toString().replace(".", "") +
            ".xlsx";
          document.body.appendChild(link);
          link.click();
        } else {
          utils.showErrorMessage();
        }
      });
  }
};

export default excelHelper;
