var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt20"},[_c('div',{staticClass:"alert alert-warning"},[_vm._v(" "+_vm._s(_vm.$root.dictionary["OPTIONS_INFO"])+" ")]),_c('div',{staticClass:"list-group"},[_c('vue-tabs',{on:{"tab-change":_vm.handleTabChange}},_vm._l((_vm.tables),function(table,index){return _c('v-tab',{key:index,attrs:{"title":!_vm.$utils.isNullOrWhiteSpace(
            _vm.$root.dictionary['TABLE_' + table.toUpperCase()]
          )
            ? _vm.$root.dictionary['TABLE_' + table.toUpperCase()]
            : 'Columns'}},[_c('draggable',{staticClass:"ghost-container",attrs:{"draggable":".list-group-item","ghost-class":"ghost","move":_vm.handleChange},model:{value:(_vm.list),callback:function ($$v) {_vm.list=$$v},expression:"list"}},[_c('transition-group',_vm._l((_vm.list),function(option,index){return _c('div',{key:index,class:'list-group-item no_margin no_padding'},[_c('div',{staticClass:"form-group no_margin no_padding row"},[_c('div',{staticClass:"col-1 sequence",staticStyle:{"background-color":"#efefef","border":"1px"}},[_c('b',[_vm._v(_vm._s(index + 1))])]),_c('div',{staticClass:"col-1 checkbox"},[_c('cts-checkbox',{attrs:{"set":(_vm.rand = (Date.now() * Math.random())
                        .toString()
                        .replace('.', '')),"disabled":option.isFixed || option.isRequired,"name":option.column + _vm.rand,"id":option.column + _vm.rand,"val":option.id,"model":option.isChecked,"checkAuth":false},model:{value:(option.isChecked),callback:function ($$v) {_vm.$set(option, "isChecked", $$v)},expression:"option.isChecked"}})],1),_c('div',{staticClass:"col-10"},[_c('p',{staticClass:"col_name"},[_vm._v(" "+_vm._s(_vm.getColumnLanguageDefinition(option.langDefinition))+" ")])])])])}),0)],1),_c('div',{staticClass:"row rowContent"},[_c('div',{staticClass:"col-12"},[_c('button',{staticClass:"btn btn-spock float-right",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.saveGridOptions.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$root.dictionary["OPTIONS_SAVE_BUTTON"]))])])])],1)}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }