<template>
  <div class="accordion" :class="cssClass" :style="handlePopout()">
    <div class="card">
      <div class="card-header" :style="handleColor()">
        <h2 class="mb-0">
          <button
            class="btn btn-link btn-block"
            :class="!isWidget ? 'expandButton' : ''"
            type="button"
            @click="!isWidget ? expand($event) : null"
          >
            <div class="row header-label">
              <slot name="header" /><label>
                {{ header }}
              </label>
            </div>
            <a @click="isWidget ? expand($event) : null">
              <i
                :class="[
                  'icon',
                  isOpen ? 'las la la-angle-up' : 'las la-angle-down',
                  isWidget ? 'la-2x expandButton' : ''
                ]"
              ></i>
            </a>
            <div><slot name="widgetHeader" /></div>
          </button>
        </h2>
      </div>
      <div
        class="collapse"
        :class="isOpen ? 'show' : ''"
        aria-labelledby="headingOne"
      >
        <div class="card-body"> <slot /> </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CtsCollapse",
    props: {
      header: {
        type: String,
        default: ""
      },
      isOpen: {
        type: Boolean,
        default: false
      },
      openedColor: {
        type: String,
        default: "#E5E5E5"
      },
      closedColor: {
        type: String,
        default: "#FFF"
      },
      isPopout: {
        type: Boolean,
        default: false
      },
      isWidget: {
        type: Boolean,
        default: false
      },
      cssClass: {
        type: String,
        default: ""
      }
    },
    created() {},
    methods: {
      expand(event) {
        let clickedElement =
            typeof event.target !== "undefined" ? event.target : event,
          icon = clickedElement.closest(".card").querySelector("i"),
          collapse = clickedElement.closest(".card").querySelector(".collapse"),
          card = clickedElement.closest(".card"),
          header = clickedElement.closest(".card-header"),
          accordion = card.parentNode;

        if (collapse.classList.contains("show")) {
          this.$utils.removeClass(icon, ["la-angle-up"]);
          this.$utils.addClass(icon, ["la-angle-down"]);
          //header.style.removeProperty("background-color");
          header.style.backgroundColor = this.closedColor;
          card.style.removeProperty("margin-top");
          card.style.removeProperty("margin-bottom");

          if (this.isPopout) {
            accordion.style.marginLeft = "20px";
            accordion.style.marginRight = "20px";
          }
        } else {
          this.$utils.removeClass(icon, ["la-angle-down"]);
          this.$utils.addClass(icon, ["la-angle-up"]);
          header.style.backgroundColor = this.openedColor;
          card.style.marginTop = "10px";
          card.style.marginBottom = "10px";

          if (this.isPopout) {
            accordion.style.marginLeft = "0px";
            accordion.style.marginRight = "0px";
          }
        }

        collapse.classList.toggle("show");
      },
      handleColor() {
        return this.isOpen
          ? `background-color:${this.openedColor}`
          : `background-color:${this.closedColor}`;
      },
      handlePopout() {
        if (this.isPopout) {
          return `margin:0px 20px 0 20px`;
        }
      }
    }
  };
</script>

<style>
  .btn-link {
    color: black !important;
  }
  .card-header {
    padding: 0px !important;
    transition: all 0.5s ease-in-out;
  }
  .header-label {
    float: left;
    position: relative;
    top: 3px;
    line-height: 2;
    padding-left: 15px;
  }
  .icon {
    float: right;
    position: relative;
    top: 7px;
  }
</style>
