<template>
  <div
    class="row justify-content-center align-items-flex-start col-md-12 col-lg-12"
    style="position: relative"
  >
    <div class="col-md-6 col-lg-6">
      <cts-fileinput
        text="Single File Input"
        name="username"
        id="singleFileInput"
        :multiple="false"
        @handleFileUpload="handleFileUpload"
      />
      <button style="float: right" @click="submitFile">Submit</button>
      <br />
      <br />
      <cts-fileinput
        text="Multiple File Input"
        name="username"
        id="multipleFileInput"
        type="file"
        :multiple="true"
        @handleFileUpload="handleFileUpload"
      />

      <button style="float: right" @click="submitFile">Submit</button>
      <br />
      <br />
      <div class="form-group row">
        <label
          for="example"
          class="control-label col-xs-12 col-sm-4 col-md-3 col-lg-3"
        ></label>
        <div class="col-xs-12 col-sm-8 col-md-9 col-lg-9">
          <div class="example" id="example">
            <h5>
              <pre> {{ example }} </pre>
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6" style="text-align: left">
      <ul>
        <li>
          <b>Props:</b>
          <ul>
            <li>text -> Type String (Not required, default: "" )</li>
            <li>name -> Type String (Not required, default: "" )</li>
            <li>id -> Type String, Number (Not required, default: "" )</li>
            <li>isOneLine -> Type Boolean (Not required, default: true)</li>
            <small>
              (Used to determine whether the component and name will be on a
              line)
            </small>
            <li>multiple -> Type Boolean (Not required, default: false)</li>
            <small>
              (Used to determine whether multiple selections can be made)
            </small>
            <li>handleFileUpload -> Type Function (Required)</li>
            <small>
              (Used for assign the file to data when file selected. Use of:
              @handleFileUpload="yourfunction")
            </small>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import CtsFileinput from "@/components/ui-components/CtsFileInput/CtsFileInput";
  export default {
    name: "CtsFileinputComponent",
    data() {
      return {
        FileInputData: "",
        example:
          "<cts-fileinput" +
          "\n" +
          ' text="Multiple File Input"' +
          "\n" +
          ' name="username"' +
          "\n" +
          ' id="multipleFileInput"' +
          "\n" +
          ' :multiple="true"' +
          "\n" +
          ' @handleFileUpload="handleFileUpload"' +
          "\n" +
          ' file input."' +
          "\n" +
          " />"
      };
    },
    components: {
      "cts-fileinput": CtsFileinput
    },
    methods: {
      handleFileUpload(value) {
        this.FileInputData = value;
      },
      submitFile() {
        let formData = new FormData();

        if (this.FileInputData.constructor.name == "File") {
          formData.append("file", this.FileInputData);
        } else {
          for (let i = 0; i < this.FileInputData.length; i++) {
            let file = this.FileInputData[i];

            formData.append("files[" + i + "]", file);
          }
        }

        axios
          .post("/role/UploadFiles", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(() => {})
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
</script>
