import utils from "../utils/utils";

const collectionHelper = {
  filterListForCollection(searchValues, sortValues, dataList) {
    let filterList = [];

    Object.assign(filterList, dataList);
    searchValues.forEach((searchObj) => {
      let dataType = searchObj.dataType,
        filterRule = searchObj.filterRule;

      if (utils.isNullOrWhiteSpace(filterRule)) {
        if (searchObj.dataType == "string") {
          filterRule = "contains";
        }
        if (searchObj.dataType == "int" || searchObj.dataType == "decimal") {
          filterRule = "equals";
        }
        if (searchObj.dataType == "date" || searchObj.dataType == "datetime") {
          filterRule = "from";
        }
      }

      switch (dataType) {
        case "string": {
          if (filterRule.toLowerCase() == "contains") {
            filterList = filterList.filter((x) =>
              x[searchObj.column]
                .toLowerCase()
                .includes(searchObj.value.toLowerCase())
            );
          } else if (filterRule.toLowerCase() == "starts with") {
            filterList = filterList.filter((x) =>
              x[searchObj.column]
                .toLowerCase()
                .startsWith(searchObj.value.toLowerCase())
            );
          } else if (filterRule.toLowerCase() == "ends with") {
            filterList = filterList.filter((x) =>
              x[searchObj.column]
                .toLowerCase()
                .endsWith(searchObj.value.toLowerCase())
            );
          } else if (filterRule.toLowerCase() == "doesn't contain") {
            filterList = filterList.filter(
              (x) =>
                !x[searchObj.column]
                  .toLowerCase()
                  .includes(searchObj.value.toLowerCase())
            );
          } else if (filterRule.toLowerCase() == "equals") {
            filterList = filterList.filter(
              (x) =>
                x[searchObj.column].toLowerCase() ===
                searchObj.value.toLowerCase()
            );
          } else if (filterRule.toLowerCase() == "not equals") {
            filterList = filterList.filter(
              (x) =>
                x[searchObj.column].toLowerCase() !==
                searchObj.value.toLowerCase()
            );
          } else if (filterRule.toLowerCase() == "show empty") {
            filterList = filterList.filter(
              (x) => x[searchObj.column].toLowerCase() === ""
            );
          }

          break;
        }
        case "decimal":
        case "int": {
          if (filterRule.toLowerCase() == "equals") {
            filterList = filterList.filter((x) => {
              return (
                x[searchObj.column].toString().toLowerCase() ===
                searchObj.value.toString().toLowerCase()
              );
            });
          } else if (filterRule.toLowerCase() == "not equals") {
            filterList = filterList.filter(
              (x) =>
                x[searchObj.column].toString().toLowerCase() !==
                searchObj.value.toString().toLowerCase()
            );
          }

          break;
        }
        case "date": {
          let startDate, endDate;

          if (searchObj.value.includes(" - ")) {
            let splitedValue = searchObj.value.split(" - ");

            startDate = new Date(utils.reverseDateTime(splitedValue[0].trim()));
            endDate = new Date(utils.reverseDateTime(splitedValue[1].trim()));
          } else {
            startDate = new Date(utils.reverseDateTime(searchObj.value.trim()));
            endDate = new Date(utils.reverseDateTime(searchObj.value.trim()));
          }

          filterList = filterList.filter((x) => {
            if (!utils.isNullOrUndefined(x[searchObj.column])) {
              let date = new Date(utils.reverseDateTime(x[searchObj.column]));
              //date = new Date(date.setMonth(date.getMonth() - 1));

              return date >= startDate && date <= endDate;
            } else {
              return false;
            }
          });

          break;
        }
        case "enum": {
          if (searchObj.value === "True") {
            filterList = filterList.filter((x) => x[searchObj.column]);
          } else {
            filterList = filterList.filter(
              (x) => x[searchObj.column] === false
            );
          }

          break;
        }
      }
    });

    if (
      !utils.isNullOrUndefined(sortValues) &&
      sortValues.length > 0 &&
      !utils.isNullOrUndefined(sortValues[0].orderBy) &&
      sortValues[0].orderBy === "asc"
    ) {
      filterList.sort();
    } else {
      filterList.sort();
      filterList.reverse();
    }

    return filterList;
  }
};

export default collectionHelper;
