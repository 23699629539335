const initialState = () => {
  return {
    loginInfo: {},
    user: null,
    isRefreshTokenUsed: false,
    refreshToken: null,
    signedIn: false
  };
};
const state = () => initialState();

import axios from "axios";
import router from "@/router";
import localStorageService from "@/services/LocalStorageService";
import menuHelper from "@/common/helpers/menuHelper";

export const login = {
  namespaced: true,
  state: state,
  getters: {
    getUser: (state) => {
      return state.user;
    }
  },
  mutations: {
    resetModuleState(state) {
      Object.assign(state, initialState());
    },
    setUser(state, user) {
      state.user = user;

      let userData = {
        email: user.email,
        username: user.userName,
        accessToken: user.accessToken,
        refreshToken: user.refreshToken,
        fullname: user.fullName,
        companyId: user.companyId
      };

      localStorage.removeItem("user");
      localStorageService.set({
        key: "user",
        value: userData
      });
      menuHelper.getDynamicMenus();
    },
    setRefreshTokenInfo(state, bool) {
      state.isRefreshTokenUsed = bool;
    },
    setRefreshToken(state, payload) {
      state.refreshToken = payload;
    }
  },
  actions: {
    RESET_APP_STATE({ commit }) {
      commit("resetModuleState");
    },
    LOGIN({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("auth/login", payload)
          .then((response) => {
            if (
              response.status === 200 &&
              response.data.state &&
              response.data.data != null
            ) {
              commit("setUser", response.data.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    LOGIN_OR_REGISTER({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("auth/loginorregister", payload)
          .then((response) => {
            if (
              response.status === 200 &&
              response.data.state &&
              response.data.data != null
            ) {
              commit("setUser", response.data.data);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    SET_USER({ commit }, payload) {
      commit("setUser", payload);
    },
    async REFRESH_TOKEN({ commit }, payload) {
      if (
        payload !== null &&
        (state.refreshToken === null || !state.isRefreshTokenUsed)
      ) {
        await axios
          .post("auth/refreshtoken", payload)
          .then((response) => {
            if (response.status === 200 && response.data.data != null) {
              commit("setUser", response.data.data);
              commit("setRefreshToken", response.data.data.refreshToken);
              Promise.resolve(response);
            } else if (
              response.status === 200 &&
              response.data.message === "Token is not valid!"
            ) {
              router.push("/auth/login");
            }
          })
          .catch((error) => {
            console.log("error on refreshing token!");
            Promise.reject(error);
          });
      }
    },
    async SET_REFRESH_STATE({ commit }, payload) {
      if (payload != null) {
        commit("setRefreshTokenInfo", payload);
      }
    },
    GET_USER_BY_EMAIL({ commit }, payload) {
      axios
        .post("user/getUserByEmail", { email: payload })
        .then((response) => {
          if (response.status === 200 && response.data != null) {
            commit("setUser", response.data.data);
            Promise.resolve(response);
          }
        })
        .catch((error) => {
          console.log("error on get user!");
          Promise.reject(error);
        });
    }
  }
};
