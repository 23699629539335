<template>
  <div>
    <slot />
  </div>
</template>

<script>
/* eslint-disable */
  export default {
    name: "Empty-Layout"
  };
</script>
