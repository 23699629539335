export default {
  showInfoModal: (that, title, text, type) => {
    that.$fire({
      title: title,
      text: text,
      type: type,
      position: "center",
      showCloseButton: true,
      showConfirmButton: false,
      showCancelButton: false
    });
  },

  showConfirmModal: (that, title, text, footer) => {
    return new Promise((resolve, reject) => {
      that
        .$fire({
          title: title,
          text: text,
          footer: footer,
          allowEscapeKey: false,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: that.$root.dictionary.LABEL_CONFIRM_BUTTON_YES,
          cancelButtonText: that.$root.dictionary.LABEL_CONFIRM_BUTTON_CANCEL,
          confirmButtonColor: "#59B78E",
          cancelButtonColor: "#D15363",
          focusConfirm: true,
          showCloseButton: true,
          reverseButtons: true,
          closeButtonHtml: "&times;",
          allowOutsideClick: false
        })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  showFormModal: (that, title, text, html, preconfirm) => {
    return new Promise((resolve, reject) => {
      that
        .$fire({
          title: title,
          text: text,
          html: html,
          position: "center",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: that.$root.dictionary.LABEL_CONFIRM_BUTTON_YES,
          cancelButtonText: that.$root.dictionary.LABEL_CONFIRM_BUTTON_CANCEL,
          confirmButtonColor: "#59B78E",
          cancelButtonColor: "#D15363",
          focusCancel: true,
          showCloseButton: true,
          closeButtonHtml: "&times;",
          showLoaderOnConfirm: true,
          scrollbarPadding: true,
          preConfirm: preconfirm
        })
        .then((result) => {
          if (result.value) {
            resolve(result);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  showTextModal: (that, title, text) => {
    return new Promise((resolve, reject) => {
      that
        .$fire({
          title: title,
          text: text,
          position: "center",
          showConfirmButton: false,
          showCancelButton: false,
          showCloseButton: true,
          scrollbarPadding: true,
          width: text.length > 1000 ? "50%" : "30%"
        })
        .then((result) => {
          if (result.value) {
            resolve(result);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  showHtmlModal: (that, title, text) => {
    return new Promise((resolve, reject) => {
      that
        .$fire({
          title: title,
          html: text, //.replace(/[\r\n]/g, "<br />"),
          position: "center",
          showConfirmButton: false,
          showCancelButton: false,
          showCloseButton: true,
          scrollbarPadding: true,
          width: text.length > 1000 ? "50%" : "30%"
        })
        .then((result) => {
          if (result.value) {
            resolve(result);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  showDeleteConfirmModal: (
    that,
    text = that.$root.dictionary.LABEL_CONFIRM_DELETE
  ) => {
    return new Promise((resolve, reject) => {
      that
        .$fire({
          title: that.$root.dictionary.TITLE_DELETE_CONFIRM_MODAL,
          text: text,
          allowEscapeKey: false,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: that.$root.dictionary.LABEL_CONFIRM_BUTTON_YES,
          cancelButtonText: that.$root.dictionary.LABEL_CONFIRM_BUTTON_CANCEL,
          confirmButtonColor: "#59B78E",
          cancelButtonColor: "#D15363",
          focusConfirm: true,
          showCloseButton: true,
          reverseButtons: true,
          closeButtonHtml: "&times;",
          allowOutsideClick: false
        })
        .then((result) => {
          if (result.value) {
            resolve(result);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
};
