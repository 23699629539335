<template>
  <cts-collapse
    :header="this.$root.dictionary.TITLE_APPROVAL_REQUEST"
    :isOpen="true"
    :isWidget="true"
    closedColor="#F2F2F2"
  >
    <i
      slot="widgetHeader"
      class="las la-sync-alt la-2x refreshButton"
      @click.stop="getApprovalCount()"
    />
    <cts-flatpickr
      v-model="approvalDate"
      :model="approvalDate"
      slot="widgetHeader"
      labelClass="col-xs-12 col-sm-4 col-md-3 col-lg-3"
      inputClass="col-xs-12 col-sm-8 col-md-9 col-lg-9"
      :isFormGroup="false"
      class="dateFilter"
      mode="range"
      :checkAuth="false"
    />
    <div class="col">
      <div class="col">
        <pie-chart :chart-data="approvalDataPoints" />
      </div>
      <div class="row mt20">
        <div class="col">
          <button
            class="btn btn-squared-default btn-primary"
            @click.prevent="goPendingApproval"
          >
            <h6>{{ this.$root.dictionary.LABEL_PENDING_APPROVALS }}</h6>
            <hr />
            <h5>{{ this.pendingApprovalCount }}</h5>
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-squared-default btn-primary"
            @click.prevent="goApprovedRequest"
          >
            <h6>{{ this.$root.dictionary.LABEL_APPROVED_REQUESTS }}</h6>
            <hr />
            <h5>{{ this.approvedCount }}</h5>
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-squared-default btn-primary"
            @click.prevent="goRejectedApprovals"
          >
            <h6>{{ this.$root.dictionary.LABEL_REJECTED_APPROVALS }}</h6>
            <hr />
            <h5> {{ this.rejectedCount }} </h5>
          </button>
        </div>
        <div class="col">
          <button
            class="btn btn-squared-default btn-primary"
            @click.prevent="goSentApproval"
          >
            <h6>{{ this.$root.dictionary.LABEL_SENT_APPROVALS }}</h6>
            <hr />
            <h5> {{ this.approvalRequestCount }} </h5>
          </button>
        </div>
      </div>
    </div>
  </cts-collapse>
</template>

<script>
  import { statusTypeEnum } from "@/common/enums/statusTypeEnum";
  import app from "@/common/constants/app";
  import axios from "axios";
  import CtsCollapse from "@/components/ui-components/CtsCollapse/CtsCollapse";
  import CtsFlatpickr from "@/components/ui-components/CtsFlatpickr/CtsFlatpickr";
  import LineChart from "@/components/charts/LineChart.js";
  import PieChart from "@/components/charts/PieChart.js";

  export default {
    name: "ApprovalSystemWidget",
    components: {
      "line-chart": LineChart,
      "pie-chart": PieChart,
      "cts-collapse": CtsCollapse,
      "cts-flatpickr": CtsFlatpickr
    },
    data: function () {
      return {
        approvalDataPoints: {},
        approvalDate: "",
        searchParameters: [],
        pendingApprovalCount: 0,
        approvedCount: 0,
        rejectedCount: 0,
        approvalRequestCount: 0
      };
    },
    methods: {
      generateSearchParameters(dataType, columnName, value, rule) {
        return {
          DataType: dataType,
          Column: columnName,
          Value: value,
          FilterRule: rule ? rule : ""
        };
      },
      async goPendingApproval() {
        this.$router.push({
          name: "Approvals_List",
          params: { key: "" }
        });
      },
      async goApprovedRequest() {
        this.$router.push({
          name: "Approvals_List",
          params: { key: this.$root.dictionary.TAB_APPROVED_APPROVALS }
        });
      },
      async goRejectedApprovals() {
        this.$router.push({
          name: "Approvals_List",
          params: { key: this.$root.dictionary.TAB_REJECTED_APPROVALS }
        });
      },
      async goSentApproval() {
        this.$router.push({
          name: "Approvals_List",
          params: { key: this.$root.dictionary.TAB_APPROVAL_REQUEST }
        });
      },
      async getApprovalCount() {
        let labels = [],
          datas = [];

        if (this.approvalDate != "") {
          this.searchParameters.push(
            this.generateSearchParameters(
              "date",
              "dateCreated",
              this.approvalDate,
              "between"
            )
          );
        }

        let response = await axios.post("dashboard/GetApprovalRequestCount", {
          searchParameters: this.searchParameters
        });

        if (this.$utils.isResponseOk(response)) {
          response.data.forEach((approval) => {
            labels.push(approval.label);
            datas.push(approval.count);
          });

          this.approvalDataPoints = {
            labels: labels,
            datasets: [
              {
                label: this.$root.dictionary.LABEL_APPROVAL_REQUESTS,
                backgroundColor: [
                  "#E57373",
                  "#CE93D8",
                  "#9FA8DA",
                  "#81D4FA",
                  "#E6EE9C",
                  "#3F51B5",
                  "#80CBC4",
                  "#A5D6A7",
                  "#FFF59D",
                  "#FFCC80",
                  "#BCAAA4",
                  "#B0BEC5"
                ],
                data: datas
              }
            ]
          };
          this.searchParameters = [];
        } else {
          this.$utils.showErrorMessage();
        }
      },
      async getPendingApproval() {
        let response = await axios.post(
          "approvalFlow/GetPendingAprrovalFlowsByUser",
          {
            page: app.firstPage,
            size: app.pageSize,
            menuId: this.$route.meta.menuId,
            componentId: this.$route.meta.componentId,
            searchParameters: [],
            tableName: "Home"
          }
        );

        if (this.$utils.isResponseReturnedOk(response)) {
          this.pendingApprovalCount = response.data.list.length;
        } else {
          this.$utils.showErrorMessage();
        }
      },
      async getApproved() {
        let response = await axios.post(
          "approvalFlow/getApprovalFlowsByStatus/" + statusTypeEnum.Approved,
          {
            page: app.firstPage,
            size: app.pageSize,
            menuId: this.$route.meta.menuId,
            componentId: this.$route.meta.componentId,
            searchParameters: []
          }
        );

        if (this.$utils.isResponseReturnedOk(response)) {
          this.approvedCount = response.data.list.length;
        } else {
          this.$utils.showErrorMessage();
        }
      },
      async getRejected() {
        let response = await axios.post(
          "approvalFlow/getApprovalFlowsByStatus/" + statusTypeEnum.Rejected,
          {
            page: app.firstPage,
            size: app.pageSize,
            menuId: this.$route.meta.menuId,
            componentId: this.$route.meta.componentId,
            searchParameters: []
          }
        );

        if (this.$utils.isResponseReturnedOk(response)) {
          this.rejectedCount = response.data.list.length;
        } else {
          this.$utils.showErrorMessage();
        }
      },
      async getApprovalRequest() {
        let response = await axios.post(
          "approvalFlow/GetApprovalFlowRequestByUserId",
          {
            page: app.firstPage,
            size: app.pageSize,
            menuId: this.$route.meta.menuId,
            componentId: this.$route.meta.componentId,
            searchParameters: []
          }
        );

        if (this.$utils.isResponseReturnedOk(response)) {
          this.approvalRequestCount = response.data.list.length;
        } else {
          this.$utils.showErrorMessage();
        }
      }
    },
    async mounted() {
      let todayString = this.$utils.convertToStringToDate(new Date()),
        oneWeekAgo = new Date();

      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

      let oneWeekAgoString = this.$utils.convertToStringToDate(oneWeekAgo);

      this.approvalDate = oneWeekAgoString + " - " + todayString;

      await this.getApprovalCount();
      await this.getPendingApproval();
      await this.getApproved();
      await this.getRejected();
      await this.getApprovalRequest();
    }
  };
</script>

<style scoped>
  .flatpickr-wrapper {
    float: right !important;
  }
  .btn-squared-default {
    min-width: 125px;
    width: 100%;
    height: 100px !important;
    padding: 5px 10px;
    margin-top: 10px;
  }
</style>
