export const timelineStatusEnum = {
  SUCCESS: 1,
  DANGER: 2,
  PLANNING: 3,
  INFO: 4,
  PRIMARY: 5,
  WARNING: 6,
  PAST: 7,
  DISTANTPAST: 8
};
