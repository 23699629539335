import { render, staticRenderFns } from "./OrderCountWidget.vue?vue&type=template&id=3b5f6209&scoped=true"
import script from "./OrderCountWidget.vue?vue&type=script&lang=js"
export * from "./OrderCountWidget.vue?vue&type=script&lang=js"
import style0 from "./OrderCountWidget.vue?vue&type=style&index=0&id=3b5f6209&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b5f6209",
  null
  
)

export default component.exports