<template>
  <div v-if="this.$utils.canShow(name) || !checkAuth">
    <div v-if="isFormGroup">
      <div :class="outerDivClass">
        <label :for="id" :class="labelDivClass">{{ text }}</label>
        <div :class="inputDivClass">
          <label
            class="upload-area"
            :for="id"
            v-if="!disabled && (this.$utils.canModify(name) || !checkAuth)"
            :class="[css, isRequired ? 'requiredField' : '']"
          >
            <input
              type="file"
              :id="id"
              :ref="id"
              :name="name"
              :multiple="multiple"
              class="form-control cts-input"
              @change="handleFileUpload"
              style="display: none"
              :class="[css, isRequired ? 'requiredField' : '']"
            />
            <i class="las la-cloud-upload-alt la-5x icon" :for="id" />
            <br />
            <span v-for="file in fileNameList" :key="file">
              <i class="las la-check icon" />
              {{ file }}<br
            /></span>
          </label>
          <div
            style="text-align: center"
            class="disabledField form-control"
            v-else
          >
            <i class="las la-cloud-upload-alt la-5x icon" :for="id" />
          </div>
          <div class="errors" v-if="validator.$error">
            <div class="error" v-for="error in activeErrorMessages" :key="error"
              ><span>{{ getError(error) }}</span></div
            >
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!disabled && (this.$utils.canModify(name) || !checkAuth)">
      <label
        class="upload-area"
        :for="id"
        :class="[css, isRequired ? 'requiredField' : '']"
      >
        <input
          type="file"
          :id="id"
          :ref="id"
          :name="name"
          :multiple="multiple"
          class="form-control cts-input"
          @change="handleFileUpload"
          style="display: none"
          :disabled="disabled"
        />
        <i class="las la-cloud-upload-alt la-5x icon" :for="id" />
        <br />
        <span v-for="file in getFileNameList" :key="file">
          <i class="las la-check icon" />
          {{ file }}<br
        /></span>
      </label>
      <div class="errors" v-if="validator.$error">
        <div class="error" v-for="error in activeErrorMessages" :key="error"
          ><span>{{ getError(error) }}</span></div
        >
      </div>
    </div>

    <div class="disabledField" style="text-align: center" v-else>
      <i class="las la-cloud-upload-alt la-5x icon" :for="id" />
    </div>
  </div>
</template>

<script>
  import { singleErrorExtractorMixin } from "vuelidate-error-extractor";
  export default {
    name: "CtsFileInput",
    mixins: [singleErrorExtractorMixin],
    data() {
      return {
        outerDivClass: "form-group ",
        labelDivClass: "control-label ",
        inputDivClass: "",
        fileNameList: [],
        file: ""
      };
    },
    props: {
      id: {
        type: [String, Number],
        default: ""
      },
      text: {
        type: String,
        default: ""
      },
      name: {
        type: String,
        default: ""
      },
      isOneLine: {
        type: Boolean,
        default: true
      },
      multiple: {
        type: Boolean,
        default: false
      },
      isFormGroup: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      labelClass: {
        type: String,
        default: ""
      },
      isRequired: {
        type: Boolean,
        default: false
      },
      inputClass: {
        type: String,
        default: ""
      },
      css: {
        type: [String, Array],
        default: ""
      },
      checkAuth: {
        // for check menu object permission
        type: Boolean,
        default: true
      },
      validator: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    computed: {
      getFileNameList() {
        return this.fileNameList;
      }
    },
    methods: {
      getClass() {
        if (this.isOneLine) {
          this.outerDivClass += "row";
          this.labelDivClass += "col-xs-12 col-sm-4 col-md-3 col-lg-3";
          this.inputDivClass += "col-xs-12 col-sm-8 col-md-9 col-lg-9";
        }

        if (this.labelClass != "" && this.inputClass != "") {
          this.labelDivClass = "control-label " + this.labelClass + " ";
          this.inputDivClass = this.inputClass + " ";
        }
      },
      handleFileUpload() {
        this.fileNameList = [];
        this.file = this.$refs[this.id].files;

        if (this.file.length > 0) {
          for (let i = 0; i < this.file.length; i++) {
            let item = this.file[i];

            this.fileNameList.push(item.name);
          }
        }

        this.$emit("handleFileUpload", this.file);
      },
      getError(error) {
        return this.$utils.getVuelidateErrorMessage(error);
      }
    },
    created() {
      this.getClass();
    }
  };
</script>
<style scoped>
  .cts-input {
    min-height: 40px !important;
  }
  .upload-area {
    background-color: #f5f5f5 !important;
    cursor: pointer !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    text-align: center !important;
    flex: 1 1 0% !important;
    margin-bottom: 0px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .icon {
    color: #9a9a9a !important;
    float: initial !important;
    top: 0px !important;
  }

  .icon:hover {
    color: #55534d !important;
  }
</style>
