import localStorageService from "@/services/LocalStorageService";
import router from "../router";

const tokenService = {
  getAccessToken() {
    let user = localStorageService.get("user");

    if (user !== null && typeof user !== "undefined" && user !== "") {
      return user.accessToken;
    } else {
      if (
        typeof router !== "undefined" &&
        typeof router.currentRoute.name !== "undefined" &&
        router.currentRoute.name !== "login"
      ) {
        this.getRefreshToken();
      }
      return null;
    }
  },
  getRefreshToken() {
    let user = localStorageService.get("user");

    if (user != null && typeof user !== "undefined" && user != "") {
      return user.refreshToken;
    }
  },
  clear() {
    localStorageService.remove("user");
  }
};

export default tokenService;
