<template>
  <cts-collapse
    :header="this.$root.dictionary.TITLE_ORDER_DETAIL_COUNT_WIDGET"
    :isOpen="true"
    :isWidget="true"
  >
    <i
      slot="widgetHeader"
      class="las la-sync-alt la-2x refreshButton"
      @click.stop="getOrderDetailCount()"
    />
    <cts-flatpickr
      v-model="orderDetailDate"
      :model="orderDetailDate"
      slot="widgetHeader"
      labelClass="col-xs-12 col-sm-4 col-md-3 col-lg-3"
      inputClass="col-xs-12 col-sm-8 col-md-9 col-lg-9"
      :isFormGroup="false"
      class="dateFilter"
      mode="range"
      :checkAuth="false"
    />
    <bar-chart :chart-data="orderDetailDataPoints" />
  </cts-collapse>
</template>

<script>
  import axios from "axios";
  import BarChart from "@/components/charts/BarChart.js";
  import CtsCollapse from "@/components/ui-components/CtsCollapse/CtsCollapse";
  import CtsFlatpickr from "@/components/ui-components/CtsFlatpickr/CtsFlatpickr";

  export default {
    name: "OrderDetailCountWidget",
    components: {
      "bar-chart": BarChart,
      "cts-collapse": CtsCollapse,
      "cts-flatpickr": CtsFlatpickr
    },
    data: function () {
      return {
        orderDetailDataPoints: {},
        orderDetailDate: "",
        searchParameters: []
      };
    },
    methods: {
      generateSearchParameters(dataType, columnName, value, rule) {
        return {
          DataType: dataType,
          Column: columnName,
          Value: value,
          FilterRule: rule ? rule : ""
        };
      },
      async getOrderDetailCount() {
        let labels = [];
        let datas = [];

        if (this.orderDetailDate != "") {
          this.searchParameters.push(
            this.generateSearchParameters(
              "date",
              "dateCreated",
              this.orderDetailDate,
              "between"
            )
          );
        }

        let response = await axios.post("dashboard/GetOrderDetailCount", {
          searchParameters: this.searchParameters
        });

        if (this.$utils.isResponseOk(response)) {
          response.data.forEach((orderDetail) => {
            labels.push(orderDetail.label);
            datas.push(orderDetail.count);
          });

          this.orderDetailDataPoints = {
            labels: labels,
            datasets: [
              {
                label: this.$root.dictionary.LABEL_ORDER_DETAIL_CODE,
                backgroundColor: "#FFCC80",
                borderColor: "#FFB74D",
                data: datas
              }
            ]
          };
          this.searchParameters = [];
        } else {
          this.$utils.showErrorMessage();
        }
      }
    },
    async mounted() {
      let todayString = this.$utils.convertToStringToDate(new Date()),
        oneWeekAgo = new Date();

      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

      let oneWeekAgoString = this.$utils.convertToStringToDate(oneWeekAgo);

      this.orderDetailDate = oneWeekAgoString + " - " + todayString;

      await this.getOrderDetailCount();
    }
  };
</script>

<style scoped>
  .flatpickr-wrapper {
    float: right !important;
  }
</style>
