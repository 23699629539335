import Vue from "vue";
import Vuex from "vuex";

import { app } from "./modules/AppModule";
import { login } from "./modules/LoginModule";
import { sidebar } from "./modules/SidebarModule";
import { routerModule } from "./modules/RouterModule";
import { roleModule } from "./modules/RoleModule";
import { menuObject } from "./modules/MenuObjectModule";
import { languageModule } from "./modules/LanguageModule";
import { bookmarksModule } from "./modules/BookmarkModule";
import { statusTypeModule } from "./modules/StatusTypeModule";
import { parameterModule } from "./modules/ParameterModule";
import { loading } from "./modules/LoadingModule";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    login,
    sidebar,
    routerModule,
    roleModule,
    languageModule,
    bookmarksModule,
    menuObject,
    statusTypeModule,
    parameterModule,
    loading
  },
  actions: {
    resetAllStates({ dispatch }) {
      dispatch("app/RESET_APP_STATE");
    }
  }
});
