<template>
  <div
    class="row justify-content-center align-items-flex-start col-md-12 col-lg-12"
    style="position: relative"
  >
    <div class="col-md-6 col-lg-6">
      <div class="col-md-12 col-lg-12 row">
        <div class="col-md-6 col-lg-6" style="justify-content: center">
          <div class="form-group">
            <button
              class="btn btn-success btn-block"
              tooltip="This is pure css tooltip. Light"
              effect="light"
              >Tooltip 1</button
            >
          </div>
        </div>
        <div class="col-md-6 col-lg-6" style="justify-content: center">
          <div class="form-group">
            <button
              class="btn btn-info btn-block"
              tooltip="This is pure css tooltip. Dark"
              effect="dark"
              >Tooltip 2</button
            >
          </div>
        </div>
        <div class="col-md-12 col-lg-12">
          <div class="example col-md-12 col-lg-12" id="example">
            <h5>
              <pre> {{ example }} </pre>
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6" style="text-align: left">
      <ul>
        <li>
          <b>Props:</b>
          <ul>
            <li>tooltip -> Type String (Required)</li>
            <li>effect -> Type String (Required)</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  export default {
    name: "CtsTooltipComponent",
    data() {
      return {
        example:
          "<button" +
          "\n" +
          '  class="btn btn-success btn-block"' +
          "\n" +
          '  tooltip="This is pure css tooltip. Light"' +
          "\n" +
          '  effect="light">' +
          "\n" +
          "  Tooltip 1" +
          "\n" +
          " </button>"
      };
    }
  };
</script>
