<template>
  <cts-collapse
    :header="this.$root.dictionary.TITLE_QUANTITY_AND_EXPIRATION_DATE_WIDGET"
    :isOpen="true"
    :isWidget="true"
  >
    <div class="home">
      <div class="row-6"> <expiration-date-list /></div>
      <div class="row-6"> <quantity-list /></div>
    </div>
  </cts-collapse>
</template>

<script>
  import ExpirationDateList from "./ExpirationDateControlList";
  import QuantityList from "./QuantityControlList";
  import CtsCollapse from "@/components/ui-components/CtsCollapse/CtsCollapse";

  export default {
    name: "CertificateInfoWidget",
    components: {
      "expiration-date-list": ExpirationDateList,
      "quantity-list": QuantityList,
      "cts-collapse": CtsCollapse
    }
  };
</script>
