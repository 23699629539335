<template>
  <div
    class="row justify-content-center align-items-flex-start col-md-12 col-lg-12"
    style="position: relative"
  >
    <div class="col-md-6 col-lg-6" style="text-align: left">
      <div class="col-md-12 col-lg-12">
        <cts-timeline :items="timelinedata" @handleClick="handleClick" />
      </div>
      <br />
      <div class="col-md-12 col-lg-12">
        <div class="example col-md-12 col-lg-12" id="example">
          <h5>
            <pre> {{ example }} </pre>
          </h5>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6" style="text-align: left">
      <ul>
        <li>
          <b>Options:</b>
          <ul>
            <li>items -> Type Array (Required)</li>
            <li>handleClick -> Type Function (Required)</li>
            <small>
              (Used for determine to what to do when clicked control buttons.
              Use of: @handleClick="yourfunction")
            </small>
            <li>An item example </li>
            <small>
              { "id": 1, "Statu": 1, "title": "SUCCESS", "controls": [ {
              "method": "edit", "icon_class": "pencil-alt" }, { "method":
              "delete", "icon_class": "trash" } ], "created": "Monday Oct. 4 -
              12:00", "body": "Lorem ipsum dolor sit amet, consectetur
              adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam." },
            </small>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import CtsTimeline from "@/components/ui-components/CtsTimeline/CtsTimeline";
  import timelinedata from "./timelinedata.json";

  export default {
    name: "CtsTimelineComponent",
    data() {
      return {
        timelinedata: timelinedata,
        example:
          "<cts-timeline" +
          "\n" +
          ' :items="timelinedata"' +
          "\n" +
          ' @handleClick="handleClick"' +
          "\n" +
          " />"
      };
    },
    components: {
      "cts-timeline": CtsTimeline
    },
    methods: {
      handleClick(control, item) {
        if (control.method == "delete") {
          console.log("delete");
          console.log(item.title);
        } else if (control.method == "edit") {
          console.log("edit");
          console.log(item.title);
        } else {
          console.log("Unknown method " + control.method);
          console.log(item.title);
        }
      }
    }
  };
</script>
<style>
  .btn-modal {
    height: 40px;
  }
</style>
