<template>
  <div
    class="row justify-content-center align-items-flex-start col-md-12 col-lg-12"
    style="position: relative"
  >
    <div class="col-md-6 col-lg-6" style="text-align: left">
      <div class="col-md-12 col-lg-12 row">
        <cts-modal
          name="ctsmodal"
          transition="pop-out"
          :draggable="true"
          :clickToClose="false"
        >
          <LoginComponent />
        </cts-modal>
        <button
          class="btn btn-modal btn-primary btn-block"
          @click="$modal.show('ctsmodal')"
        >
          Open Login Component
        </button>
      </div>
      <br />
      <div class="col-md-12 col-lg-12 row">
        <div class="example col-md-12 col-lg-12" id="example">
          <h5>
            <pre> {{ example }} </pre>
          </h5>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6" style="text-align: left">
      <ul>
        <li>
          <b>Options:</b>
          <ul>
            <li>name -> Type [String, Number] (Required)</li>
            <li>delay -> Type String (Not required, default: 0)</li>
            <small>
              (Delay between showing overlay and actual modal box)
            </small>
            <li>resizable -> Type Boolean (Not required, default: false)</li>
            <small>
              (If true allows resizing the modal window, keeping it in the
              center of the screen.)
            </small>
            <li>adaptive -> Type Boolean (Not required, default: false)</li>
            <small>
              (If true, modal box will try to adapt to the window size)
            </small>
            <li
              >draggable -> Type [Boolean, String] (Not required, default:
              false)</li
            >
            <small> (If true, modal box will be draggable.) </small>
            <li>scrollable -> Type Boolean (Not required, default: false)</li>
            <small>
              ( If height property is auto and the modal height exceeds window
              height - you will be able to scroll modal)
            </small>
            <li>reset -> Type Boolean (Not required, default: false)</li>
            <small> (Resets position and size before showing modal) </small>
            <li>clickToClose -> Type Boolean (Not required, default: true)</li>
            <small>
              (If set to false, it will not be possible to close modal by
              clicking on the background)
            </small>
            <li
              >transition -> Type String (Not required, default: "pop-out")</li
            >
            <small> (Transition name) </small>
            <li
              >classes -> Type [String, Array] (Not required, default:
              "v--modal")</li
            >
            <small>
              (Classes that will be applied to the actual modal box, if not
              specified, the default v--modal class will be applied)
            </small>
            <li
              >width -> Type [String, Number] (Not required, default: 656 or
              328)</li
            >
            <small>
              (Width in pixels or percents (e.g. 50 or "50px", "50%"))
            </small>
            <li
              >height -> Type [String, Number] (Not required, default:
              "auto")</li
            >
            <small>
              ( Height in pixels or percents (e.g. 50 or "50px", "50%") or
              "auto")
            </small>
            <li>minWidth -> Type Number (Not required, default: 0)</li>
            <small> ( The minimum width to which modal can be resized) </small>
            <li>minHeight -> Type Number (Not required, default: 0)</li>
            <small> ( The minimum height to which modal can be resized) </small>
            <li>maxWidth -> Type Number (Not required, default: Infinity)</li>
            <small>
              (The maximum width of the modal (if the value is greater than
              window width, window width will be used instead)
            </small>
            <li>maxHeight -> Type Number (Not required, default: Infinity)</li>
            <small>
              (The maximum height of the modal (if the value is greater than
              window height, window height will be used instead)
            </small>
            <li
              >Reference :
              <a href="https://github.com/euvl/vue-js-modal">Vue Modal</a></li
            >
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import CtsModal from "@/components/ui-components/CtsModal/CtsModal";
  import LoginComponent from "@/components/Auth/LoginComponent";

  export default {
    name: "CtsModalComponent",
    data() {
      return {
        example:
          "<cts-modal" +
          "\n" +
          ' name="ctsmodal"' +
          "\n" +
          ' transition="pop-out"' +
          "\n" +
          ' :draggable="true",' +
          "\n" +
          ' :clickToClose="false"' +
          "\n" +
          "  >" +
          "\n" +
          "     <LoginComponent />" +
          "\n" +
          "</cts-modal>" +
          "\n" +
          "<button" +
          "\n" +
          ' class="btn btn-modal btn-primary btn-block"' +
          "\n" +
          ' @click="$modal.show("ctsmodal")"' +
          "\n" +
          " >" +
          "\n" +
          " Open Login Component" +
          "\n" +
          "</button>"
      };
    },
    components: {
      "cts-modal": CtsModal,
      LoginComponent
    }
  };
</script>
<style>
  .btn-modal {
    height: 40px;
  }
</style>
