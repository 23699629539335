<template>
  <div class="wrapper">
    <div class="main">
      <h1>This is simple welcome page for testing...</h1>
      <div class="table-responsive"> </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Welcome-Page"
  };
</script>

<style scoped>
  body,
  html,
  .wrapper {
    width: 100%;
    height: 100%;
  }
  .table th {
    white-space: nowrap;
    vertical-align: middle !important;
  }
  table > thead > tr > th,
  table > thead > tr > td {
    vertical-align: top;
  }
  .table thead th {
    position: -webkit-sticky !important;
    position: sticky !important;
    z-index: 997;
  }
</style>
