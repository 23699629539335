import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home/Home.vue";
import Login from "../views/Auth/Login.vue";
import Profile from "../views/Profile/Form.vue";
import Forbidden from "../views/Status/Forbidden.vue";
import Welcome from "../views/Status/Welcome.vue";
import UiComponents from "../views/UiComponents/List.vue";
import awsTokenService from "@/services/AwsTokenService.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/auth/login",
    name: "login",
    component: Login,
    meta: {
      layout: "empty",
      public: true
    }
  },
  {
    path: "/status/welcome",
    name: "welcome",
    component: Welcome,
    meta: {
      layout: "empty",
      public: true
    }
  },
  {
    path: "/",
    name: "default",
    redirect: "/home",
    meta: {
      public: false
    }
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      public: false
    }
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      public: false,
      // This area(componentId) will be update an other time
      componentId: 10000000
    }
  },
  {
    path: "/status/forbidden",
    name: "forbidden",
    component: Forbidden,
    meta: {
      layout: "empty",
      public: true
    }
  },
  {
    path: "/uicomponents/list",
    name: "uicomponents",
    component: UiComponents,
    meta: {
      layout: "empty",
      public: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeResolve(async (to, from, next) => {
  const store = require("@/store");

  if (to.name !== "login") {
    await awsTokenService.refreshToken();
    let isLoginNeeded = await awsTokenService.isLoginNeeded();

    if (isLoginNeeded) {
      const path = "/auth/login";
      next(path);
      return;
    }
  }

  store.default.dispatch("loading/SET_LOADING_STATE", true);
  store.default.dispatch("resetAllStates");
  // Redirect if fullPath begins with a hash (ignore hashes later in path)
  if (to.fullPath.substr(0, 2) === "/#") {
    const path = to.fullPath.substr(2);
    next(path);
    return;
  }
  next();
});

router.afterEach(() => {
  const store = require("@/store");
  window.scrollTo(0, 0);

  setTimeout(() => {
    store.default.dispatch("loading/SET_LOADING_STATE", false);
  }, 750);
});

export default router;
