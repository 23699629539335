<template>
  <div class="table-responsive">
    <h4 class="table-title" v-if="title.length">{{ title }}</h4>
    <div class="table-span">
      <table
        class="table table-bordered table-hover table-records"
        :class="title.length > 0 ? '' : ''"
      >
        <thead>
          <tr class="search-filters" :class="hasSearchFilters ? '' : 'hide'">
            <th colspan="100%">
              <span class="search-title">
                {{ this.$root.dictionary.LABEL_SEARCHING }} :
              </span>
              <div
                class="search-badge"
                v-for="(header, index) in getHeaders"
                :key="index"
                @click.stop="removeSearchFilter(header)"
              >
                <span v-if="header.value != null && header.value != ''">
                  {{ $root.dictionary[header.langDefinition] }}
                  {{ getFilterRule(header) }}
                  {{
                    header.dataType === "enum"
                      ? getEnumValue(
                          header.value === "True"
                            ? true
                            : header.value === "False"
                            ? false
                            : header.value,
                          header.columnType
                        )
                      : header.value
                  }}
                </span>
              </div>
            </th>
          </tr>
          <tr class="search-filters" :class="hasSortFilters ? '' : 'hide'">
            <th colspan="100%">
              <span class="search-title">
                {{ this.$root.dictionary.LABEL_SORTING }} :
              </span>
              <div
                class="search-badge"
                v-for="(header, index) in getHeaders"
                :key="index"
                @click.stop="removeSortFilter(header)"
              >
                <span v-if="header.orderBy != null && header.orderBy != ''">
                  {{ $root.dictionary[header.langDefinition] }} ->
                  {{ header.orderBy }}
                </span>
              </div>
            </th>
          </tr>
          <tr class="table-filtering hide">
            <th v-for="(header, index) in getHeaders" :key="index">
              <span v-if="header.show">
                <input
                  :type="
                    header.dataType == 'int' ||
                    header.dataType == 'double' ||
                    header.dataType == 'float' ||
                    header.dataType == 'decimal'
                      ? 'number'
                      : 'text'
                  "
                  v-model="header.value"
                  @keyup.enter.prevent="getSearchValues"
                  :disabled="
                    header.dataType === 'enum' ||
                    header.dataType === 'date' ||
                    header.dataType === 'datetime'
                      ? true
                      : false
                  "
                  class="form-control search-input"
                  :class="
                    header.dataType === 'enum' ||
                    header.dataType === 'date' ||
                    header.dataType === 'datetime' ||
                    header.dataType === 'collection'
                      ? 'disabled'
                      : ''
                  "
                  data-order-by="asc"
                  data-enable="true"
                  data-search-enable="true"
                  data-sort-enable="true"
                  :data-inputType="header.inputType"
                  :data-type="header.dataType"
                  :data-column="header.column"
                  :data-searchType="searchType"
                  :data-url="searchValue"
                />
              </span>
              <span v-else></span>
            </th>
          </tr>
          <tr class="table-headers" ref="searchDropDown">
            <th
              class="position-relative"
              v-for="(header, index) in getHeaders"
              :key="index"
              :style="header.color ? 'backgroundColor:' + header.color : ''"
              :data-class="[
                header.column === 'id' ? ' checkboxColumn' : '',
                header.dataType === 'icon' ? ' iconClass' : '',
                !$utils.isNullOrWhiteSpace(header.column)
                  ? header.column.replace(/\./g, '_')
                  : ''
              ]"
            >
              <div v-if="header.show">
                <span class="filter_sort_icons">
                  <i
                    v-if="isSortable && header.isSortable"
                    :class="{
                      'las la-sort':
                        header.orderBy == '' || header.orderBy == null,
                      'las la-sort-down': header.orderBy == 'asc',
                      'las la-sort-up': header.orderBy == 'desc'
                    }"
                    @click.prevent="handleSorting"
                    :data-column="header.column"
                    :data-searchType="searchType"
                    :data-url="searchValue"
                  ></i>
                  <i
                    v-if="isSearchable && header.isSearchable"
                    @click.prevent="openSearchBar"
                    :class="{ 'primary-color': header.filterRule != '' }"
                    class="las la-filter"
                    :data-column="header.column"
                    :data-type="header.dataType"
                    :data-searchType="searchType"
                    :data-url="searchValue"
                  ></i>
                </span>
                <span class="text-content">
                  {{ $root.dictionary[header.langDefinition] }}
                </span>
                <div class="grid-filter-dropdown hide closed">
                  <ul
                    v-if="header.inputType == 'string'"
                    class="dropdown-content"
                  >
                    <li
                      v-for="(tableFilter, index) in getDataFilters(
                        header.dataType
                      )"
                      :key="index"
                      :class="{
                        'primary-color': header.filterRule == tableFilter
                      }"
                      @click.prevent="handleFilter"
                      :data-value="tableFilter.value"
                    >
                      {{ tableFilter.title }}
                      <i
                        v-if="header.filterRule == tableFilter.value"
                        class="las la-check"
                      ></i>
                    </li>
                  </ul>
                  <ul
                    v-if="header.inputType == 'date'"
                    class="dropdown-content"
                  >
                    <li class="date">
                      <cts-flatpickr
                        @change="getDateValue"
                        :data-column="header.column"
                        css="from"
                        :text="getDataFilters(header.dataType)[0].title"
                        type="text"
                        :isOneLine="false"
                        :checkAuth="false"
                      />
                      <cts-flatpickr
                        @change="getDateValue"
                        :data-column="header.column"
                        css="to"
                        :text="getDataFilters(header.dataType)[1].title"
                        type="text"
                        :isOneLine="false"
                        :checkAuth="false"
                      />
                      <Button
                        @click.stop="handleDateSearch"
                        class="btn btn-primary"
                      >
                        Search
                      </Button>
                      <Button
                        @click.stop="handleFilter"
                        data-value="Show empty"
                        class="btn btn-primary"
                        style="width: 105px; margin-left: 5px"
                      >
                        Show Empty
                      </Button>
                    </li>
                  </ul>
                  <ul
                    v-if="
                      header.inputType == 'int' ||
                      header.inputType == 'float' ||
                      header.inputType == 'decimal' ||
                      header.inputType == 'double'
                    "
                    class="dropdown-content"
                  >
                    <li
                      v-for="(tableFilter, index) in getDataFilters(
                        header.dataType
                      )"
                      :key="index"
                      :class="{
                        'primary-color': header.filterRule == tableFilter
                      }"
                      @click.prevent="handleFilter"
                      :data-value="tableFilter.value"
                    >
                      {{ tableFilter.title }}
                      <i
                        v-if="header.filterRule == tableFilter.value"
                        class="las la-check"
                      ></i>
                    </li>
                  </ul>
                  <select
                    @change.stop="handleFilter"
                    class="form-control"
                    v-model="header.value"
                    v-if="header.dataType == 'enum'"
                  >
                    <option disabled value>Please select</option>
                    <option
                      v-for="(option, index) in getDataFilters(
                        header.inputType
                      )"
                      :key="index"
                      :value="option.value"
                      :selected="option"
                    >
                      {{ option.title }}
                    </option>
                  </select>
                </div>
              </div>
              <span v-else>
                <cts-checkbox
                  :set="
                    (rand = (Date.now() * Math.random())
                      .toString()
                      .replace('.', ''))
                  "
                  :id="`selectAll-${rand}`"
                  :name="`selectAll-${rand}`"
                  :isInGrid="true"
                  @change="selectAllCheckBoxes"
                  :checkAuth="false"
                  :model="selectAllIsChecked"
                  v-model="selectAllIsChecked"
                />
              </span>
            </th>
          </tr>
        </thead>
        <tbody :key="componentKey">
          <template v-for="(column, index) in this.dataList">
            <tr
              :data-id="column.id"
              :key="index"
              class="parent"
              @dblclick="$emit('dblClick', [column.id])"
            >
              <td
                @click.stop="handleSelection"
                v-for="(header, index) in getHeaders"
                :key="index"
                :class="[
                  header.column === 'id' ? ' checkboxColumn' : '',
                  header.dataType === 'icon' ? ' iconClass' : '',
                  header.column
                ]"
                :data-class="[
                  header.column === 'id' ? ' checkboxColumn' : '',
                  header.dataType === 'icon' ? ' iconClass' : '',
                  !$utils.isNullOrWhiteSpace(header.column)
                    ? header.column.replace(/\./g, '_')
                    : ''
                ]"
              >
                <span
                  v-if="
                    header.show &&
                    header.dataType === 'icon' &&
                    header.inputType == 'array'
                  "
                >
                  <span v-if="showIconColumn">
                    <i
                      v-for="(item, index) in column[header.column]"
                      :key="index"
                      :class="item.iconClass"
                      :title="item.tooltip"
                    />
                  </span>
                  <span v-else>
                    <span
                      v-for="(item, index) in column[header.column]"
                      :key="index"
                    >
                      {{ item.tooltip }}
                    </span>
                  </span>
                </span>
                <span v-else-if="header.show && header.dataType === 'icon'">
                  <i :class="column[header.column]" :title="column.tooltip" />
                </span>
                <span
                  v-else-if="header.show && header.dataType === 'collection'"
                >
                  <a
                    @click.stop="
                      openCollection(header.column, column.id, header.table)
                    "
                  >
                    <i
                      :class="
                        column[header.column].show
                          ? 'las la-minus-circle'
                          : 'las la-plus-circle'
                      "
                    ></i>
                    {{ $root.dictionary[header.langDefinition] }}
                  </a>
                </span>
                <span v-else-if="header.show && header.dataType === 'link'">
                  <a @click.stop="openData(column, header)">
                    <i class="las la-external-link-alt"></i>
                    {{ $root.dictionary[header.langDefinition] }}
                  </a>
                </span>
                <span v-else-if="header.show && header.column.includes('.')">
                  {{ getSubColumnValue(column, header.column) }}
                  <span
                    style="float: right"
                    class="btn btn-outline-primary text-black btn-rounded"
                    v-if="checkStringLimit(column[header.column])"
                    @click="readMore(column[header.column])"
                    >{{ $root.dictionary.BUTTON_READ_MORE }}</span
                  >
                </span>
                <span
                  class="text-nowrap"
                  v-else-if="
                    header.show &&
                    header.dataType !== 'enum' &&
                    header.dataType !== 'date' &&
                    header.dataType !== 'datetime'
                  "
                >
                  {{ getString(column[header.column], header) }}
                  <span
                    style="cursor: pointer"
                    class="las la-comment-dots la-2x"
                    v-if="checkStringLimit(column[header.column])"
                    @click="readMore(column[header.column])"
                  ></span>
                </span>
                <span
                  :class="
                    getEnumCssClass(column[header.column], header.columnType)
                  "
                  v-else-if="header.show && header.dataType === 'enum'"
                >
                  {{ getEnumValue(column[header.column], header.columnType) }}
                </span>
                <span v-else-if="header.show && header.dataType === 'date'">
                  {{
                    $utils.convertToDate(
                      $utils.convertDateToTimeZone(
                        column[header.column],
                        header
                      ),
                      "tr-tr",
                      false
                    )
                  }}
                </span>
                <span v-else-if="header.show && header.dataType === 'datetime'">
                  {{
                    $utils.convertToDate(
                      $utils.convertDateToTimeZone(
                        column[header.column],
                        header
                      ),
                      "tr-tr",
                      true
                    )
                  }}
                </span>
                <span v-else>
                  <cts-checkbox
                    :set="
                      (rand = (Date.now() * Math.random())
                        .toString()
                        .replace('.', ''))
                    "
                    :isArray="true"
                    :isInGrid="true"
                    :id="rand"
                    :name="rand"
                    v-model="selectedRows"
                    :val="column.id"
                    :checkAuth="false"
                  />
                </span>
              </td>
            </tr>
            <tr
              v-for="(collectionHeader, index) in getCollectionHeaders"
              :key="`${index}-${column.id}`"
              v-show="
                column[collectionHeader.column].show
                  ? column[collectionHeader.column].show
                  : false
              "
            >
              <td colspan="100%" class="collection">
                <list-component
                  :ref="collectionHeader.column"
                  :title="$root.dictionary[collectionHeader.langDefinition]"
                  :list="column[collectionHeader.column].list"
                  :headers="column[collectionHeader.column].headers"
                  :headerFilters="headerFilters"
                  :searchType="1"
                  :searchValue="collectionHeader.searchValue"
                  :dataCount="column[collectionHeader.column].totalDataCount"
                  :pageCount="column[collectionHeader.column].totalPageCount"
                  :page="column[collectionHeader.column].currentPage"
                  :key="componentKey"
                  :masterId="column.id"
                  :masterTableName="collectionHeader.table"
                />
              </td>
            </tr>
          </template>
        </tbody>
        <tfoot>
          <template v-if="hasAnyOperation()">
            <tr>
              <td colspan="100%" style="height: 30px"></td>
            </tr>
            <tr class="noBorder">
              <td
                :class="
                  !$utils.isNullOrWhiteSpace(header.columnOperation)
                    ? header.columnOperation.split(',')[0].toLowerCase()
                    : ''
                "
                v-for="(header, index) in getHeaders"
                :key="index"
              >
                {{ getOperationValue(header) }}
              </td>
            </tr>
            <tr class="noBorder" v-if="hasSubTotalOperation()">
              <td v-for="(header, index) in getHeaders" :key="index">
                <div class="float-right" v-if="headerHasSubTotal(header)">
                  <span
                    style="
                      color: #fff;
                      background-color: #5885a0;
                      padding: 5px 10px;
                    "
                  >
                    Total: {{ getSubTotalValue(header) }}
                  </span>
                </div>
              </td>
            </tr>
          </template>
          <tr>
            <td colspan="100%" v-if="pagination">
              <pager
                :currentPage="this.currentPage"
                :totalDataCount="this.totalDataCount"
                :totalPageCount="this.totalPageCount"
                @goto="goto"
              />
            </td>
            <td colspan="100%" v-else>
              <div class="page-info">
                <p>
                  <strong>
                    Total Data Count is:
                    {{ $utils.formatBigNumber(totalDataCount) }}
                  </strong>
                </p>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>
  import app from "@/common/constants/app";
  import axios from "axios";
  import collectionHelper from "@/common/helpers/collectionHelper";
  import CtsCheckbox from "@/components/ui-components/CtsCheckbox/CtsCheckbox";
  import CtsFlatpickr from "@/components/ui-components/CtsFlatpickr/CtsFlatpickr";
  import pager from "@/components/data-table/Pager";
  import searchTypes from "@/common/enums/searchTypes";

  window.addEventListener("keydown", function (event) {
    if (event.ctrlKey && event.which == 70) {
      // Block CTRL + F event
      event.preventDefault();
      document.querySelectorAll("tr.table-headers i.la-filter")[0].click();
    }
  });

  export default {
    name: "listComponent",
    components: {
      pager,
      "cts-flatpickr": CtsFlatpickr,
      "cts-checkbox": CtsCheckbox
    },
    props: {
      title: { type: String, default: "" },
      list: {
        required: true,
        default() {
          return [];
        }
      },
      page: { type: Number },
      dataCount: { type: Number },
      pageCount: { type: Number },
      headers: {
        required: true,
        default() {
          return [];
        }
      },
      headerFilters: {
        required: true
      },
      searchType: { type: Number, default: searchTypes.URL },
      searchValue: { type: String, required: true },
      tableId: { type: Number },
      checkedRows: {
        default() {
          return [];
        }
      },
      masterId: { type: Number, default: null },
      masterTableName: { type: String, default: null },
      defaultSearchValues: {
        type: Array,
        default() {
          return [];
        }
      },
      startedSearchValues: {
        type: Array,
        default() {
          return [];
        }
      },
      componentId: { type: Number },
      menuId: { type: Number },
      isSearchable: { type: Boolean, default: true },
      isSortable: { type: Boolean, default: true },
      systemType: { type: Number, default: 0 }, //For status columns
      parentRefIds: {
        //For status columns
        type: Array,
        default() {
          return [];
        }
      },
      parentColumnName: { type: String, default: "" }, //For status columns
      showUserColumn: { type: Boolean, default: true }, //For status columns
      showIconColumn: { type: Boolean, default: true }, //For icon columns
      detailHeaderConfigurations: {
        //For status columns
        type: Array,
        default() {
          return [];
        }
      },
      footerInfo: { type: String, default: "" },
      subTotals: {
        default() {
          return [];
        }
      },
      clearSelectedRows: { type: Boolean, default: true }, //Sayfa geçişlerinde selected row arrayin temizlenip temizlenmiceği bilgisi
      pagination: { type: Boolean, default: true }, //Sayfalama olup olmayacağı
      pageSize: { type: Number, default: app.pageSize },
      readFromDynamicList: { type: Boolean, default: false }
    },
    data() {
      return {
        dataList: this.list,
        collectionList: this.list,
        dataHeaders: this.headers,
        dataFilters: this.headerFilters,
        currentPage: this.page,
        totalDataCount: this.dataCount,
        totalPageCount: this.pageCount,
        selectedRows: this.checkedRows,
        sortValues: [],
        componentKey: 0,
        searchValues: this.defaultSearchValues,
        isFixedHeadersAligned: false,
        tableHeight: 0,
        subTotalList: [],
        selectAllIsChecked: false
      };
    },
    watch: {
      tableHeight() {},
      defaultSearchValues() {
        this.generateSearchKeys();
      },
      checkedRows() {
        this.selectedRows = this.checkedRows;
      },
      list() {
        this.dataList = this.list;
        this.collectionList = this.list;
        this.currentPage = this.page;
        // this.totalDataCount = this.totalDataCount;
        // this.totalPageCount = this.totalPageCount;
      },
      subTotals() {
        this.subTotalList = this.subTotals;
      },
      headers() {
        this.dataHeaders = this.headers;

        if (this.systemType != 0) {
          let statusColumn = this.dataHeaders.filter(
            (x) => x.column === "status"
          );

          if (statusColumn.length === 0) {
            this.addStatusHeader();
            // this.dataHeaders.sort(function(a, b) {
            //   return a.sequence - b.sequence;
            // });
          }
        }
      },
      headerFilters() {
        this.dataFilters = this.headerFilters;
      },
      page() {
        this.currentPage = this.page;
      },
      currentPage() {
        this.$emit("updateCurrentPage", this.currentPage);
      },
      dataCount() {
        this.totalDataCount = this.dataCount;
      },
      pageCount() {
        this.totalPageCount = this.pageCount;
      },
      selectedRows() {
        this.$emit("changeSelectedRows", this.selectedRows);
      },
      sortValues() {
        this.$emit("sortValues", this.sortValues);
      },
      searchValues() {
        this.$emit("searchValues", this.searchValues);
      },
      dataList() {
        this.$emit("changeDataList", this.dataList);

        if (this.systemType !== 0 && this.dataList.length > 0) {
          this.getStatus();
        }
      }
    },
    updated() {
      this.$emit("handleList", this.dataList);
      this.$nextTick(() => {
        this.$utils.applyStickyRules(".table-span");
      });
    },
    computed: {
      getCollectionHeaders() {
        return this.dataHeaders.filter((x) => x.dataType === "collection");
      },
      getHeaders() {
        return this.$root.changeHeader(
          this.dataHeaders.filter((header) => {
            return header.sequence >= 0;
          })
        );
      },
      hasSearchFilters() {
        let searchFilters = this.headers.filter((header) => {
          return header.value != "" && header.value != null;
        });

        return searchFilters.length ? true : false;
      },
      hasSortFilters() {
        let sortFilters = this.headers.filter((header) => {
          return header.orderBy != "" && header.orderBy != null;
        });

        return sortFilters.length ? true : false;
      }
    },
    methods: {
      getOperationValue(header) {
        let result = "";

        if (!this.$utils.isNullOrWhiteSpace(header.columnOperation)) {
          let operations = header.columnOperation.split(",");

          operations.forEach((operation) => {
            if (operation.toLowerCase() == "sum") {
              result = 0;
              this.dataList.forEach((element) => {
                result += element[header.column];
              });
            }

            if (operation.toLowerCase() == "distinctcount") {
              if (header.column.includes(".")) {
                let values = [];

                this.dataList.forEach((element) => {
                  let value = this.getSubColumnValue(element, header.column);
                  values.push(value);
                });

                let distinctList = [...new Set(values.map((item) => item))];
                result = distinctList.length;
              } else {
                let distinctList = [
                  ...new Set(this.dataList.map((item) => item[header.column]))
                ];
                result = distinctList.length;
              }
            }
          });
        }

        if (!this.$utils.isNullOrWhiteSpace(result)) {
          return parseFloat(result).toFixed(app.decimalPoint);
        }

        return result;
      },
      getSubTotalValue(header) {
        let result = "";

        if (!this.$utils.isNullOrWhiteSpace(header.columnOperation)) {
          let operations = header.columnOperation.split(",");

          operations.forEach((operation) => {
            if (operation.toLowerCase() == "subtotal") {
              this.subTotalList.forEach((element) => {
                if (element.column == header.column) {
                  result = element.value;
                }
              });
            }
          });
        }

        if (!this.$utils.isNullOrWhiteSpace(result)) {
          return parseFloat(result).toFixed(app.decimalPoint);
        }

        return result;
      },
      hasAnyOperation() {
        var result = false;
        var headers = this.getHeaders;

        headers.forEach((header) => {
          if (!this.$utils.isNullOrWhiteSpace(header.columnOperation)) {
            result = true;
          }
        });
        return result;
      },
      hasSubTotalOperation() {
        var result = false,
          headers = this.getHeaders;

        headers.forEach((header) => {
          var response = this.headerHasSubTotal(header);

          if (response) {
            result = true;
          }
        });

        return result;
      },
      headerHasSubTotal(header) {
        let result = false;

        if (!this.$utils.isNullOrWhiteSpace(header.columnOperation)) {
          let operations = header.columnOperation.split(",");

          operations.forEach((operation) => {
            if (operation.toLowerCase() == "subtotal") {
              result = true;
            }
          });
        }

        return result;
      },
      addStatusHeader() {
        let headers = this.$utils.addStatusHeader(this.showUserColumn),
          idHeader = this.dataHeaders.filter((x) => x.column === "id"),
          tempList = [...idHeader, ...headers],
          otherHeaders = this.dataHeaders.filter((x) => x.column !== "id");

        this.dataHeaders = [...tempList, ...otherHeaders];
      },
      // Status icons on the page
      async getStatus() {
        let isChild = false,
          refIds = this.dataList.map((x) => x.id),
          parentWithChildIds = [];

        if (this.parentRefIds.length > 0) {
          //Prepare parent child data for child list
          //Ex. CommercialInvoiceDetail
          this.parentRefIds.forEach((parentRefId) => {
            let childRefIds = this.list
              .filter((x) => x[this.parentColumnName] === parentRefId)
              .map((y) => y.id);

            let parentChildData = {
              parentRefId: parentRefId,
              childRefIds: childRefIds
            };

            parentWithChildIds.push(parentChildData);
          });

          isChild = true;
        }

        let response = await axios.post(
          "statusReference/GetStatusReferencesByTypeAndRefId",
          {
            type: this.systemType,
            refIds: refIds,
            isChild: isChild,
            parentWithChildIds: parentWithChildIds
          }
        );

        if (this.$utils.isResponseReturnedOk(response)) {
          let statusReferences = response.data.list;

          statusReferences.forEach((statusReference) => {
            let data = this.dataList.filter(
              (data) => data.id === statusReference.refId
            );

            data[0].status = !this.$utils.isNullOrUndefined(data[0].status)
              ? data[0].status
              : [];
            data[0].changedUser = "";

            let langDef = !this.$utils.isNullOrWhiteSpace(
                statusReference.status.langDefinition
              )
                ? statusReference.status.langDefinition
                : statusReference.status.statusType.langDefinition,
              iconClass = !this.$utils.isNullOrWhiteSpace(
                statusReference.status.cssClass
              )
                ? statusReference.status.cssClass
                : statusReference.status.statusType.cssClass;

            let statusItem = {
                iconClass: iconClass,
                tooltip: this.$root.dictionary[langDef]
              },
              checkStatus = true;

            data[0].status.forEach((element) => {
              if (
                element.iconClass == statusItem.iconClass &&
                element.tooltip == statusItem.tooltip
              ) {
                checkStatus = false;
              }
            });

            if (checkStatus) {
              data[0].status.push(statusItem);
            }

            //TODO --> In case of more than one user, the display will be modified.
            data[0].changedUser += !this.$utils.isNullOrWhiteSpace(
              data[0].changedUser
            )
              ? "," + statusReference.username
              : statusReference.username;
          });

          this.forceRerender();
        } else {
          this.$utils.showErrorMessage();
        }
      },
      openData(data, header) {
        this.$emit("openData", { data, header });
      },
      forceRerender() {
        this.componentKey += 1;
      },
      openCollection(detailColumnName, masterId, masterTableName) {
        let master = this.dataList.filter((x) => x.id == masterId)[0],
          detail = master[detailColumnName];

        if (this.$utils.isNullOrUndefined(detail.list) && !detail.show) {
          let header = this.dataHeaders.filter(
            (x) => x.column == detailColumnName
          );

          this.doSearch(
            false,
            header[0].searchValue,
            detailColumnName,
            masterId,
            masterTableName
          );
        }

        detail.show = !detail.show;
        this.forceRerender();
      },
      getEnumCssClass(value, columnType) {
        let text = "";

        switch (columnType) {
          case "IntegrationResult":
            switch (value) {
              case 0:
                text = "badge badge-light";
                break;
              case 1:
                text = "badge badge-success";
                break;
              case 2:
                text = "badge badge-danger";
                break;
              case 3:
                text = "badge badge-warning";
                break;
              case 4:
                text = "badge badge-light";
                break;
              case 5:
                text = "badge badge-warning";
                break;
            }

            break;

          case "StatusTypes":
            switch (value) {
              case 1:
                text = "badge badge-warning";
                break;
              case 2:
                text = "badge badge-success";
                break;
              case 3:
                text = "badge badge-danger";
                break;
              case 4:
                text = "badge badge-light";
                break;
            }

            break;

          case "WindowsServiceStatus":
            if (value) {
              text = "badge badge-warning";
            } else {
              text = "badge badge-success";
            }

            break;

          case "BondedStatusTypes":
            switch (value) {
              case 16:
                text = "badge badge-warning";
                break;
              case 7:
                text = "badge badge-success";
                break;
              case 17:
                text = "badge badge-danger";
                break;
            }

            break;

          case "SapShipmentStatus":
            switch (value) {
              default:
                text = "badge badge-dark";
                break;
            }

            break;

          case "ActivePassive":
          case "YesNo": {
            switch (value) {
              case true:
                text = "badge badge-success";
                break;
              case false:
                text = "badge badge-danger";
                break;
              default:
                break;
            }
          }
        }

        return text;
      },
      getEnumValue(value, columnType) {
        let text = "";

        switch (columnType) {
          case "ActivePassive":
            if (value) {
              text = this.$root.dictionary.LABEL_ACTIVE;
            } else if (!value) {
              text = this.$root.dictionary.LABEL_PASSIVE;
            } else {
              text =
                this.$root.dictionary
                  .TABLE_FILTER_ENUM_ACTIVE_PASSIVE_SHOW_EMPTY;
            }
            break;

          case "YesNo":
            if (value) {
              text = this.$root.dictionary.LABEL_YES;
            } else if (!value) {
              text = this.$root.dictionary.LABEL_NO;
            } else {
              text = this.$root.dictionary.TABLE_FILTER_ENUM_YES_NO_SHOW_EMPTY;
            }

            break;

          case "IntegrationResult":
            switch (value) {
              case 0:
                text = this.$root.dictionary.LABEL_NOT_SPECIFIED;
                break;
              case 1:
                text = this.$root.dictionary.LABEL_SUCCESS;
                break;
              case 2:
                text = this.$root.dictionary.LABEL_FAILED;
                break;
              case 3:
                text = this.$root.dictionary.LABEL_PARTIALLY_COMPLETED;
                break;
              case 4:
                text = this.$root.dictionary.LABEL_SKIPPED;
                break;
              case 5:
                text = this.$root.dictionary.LABEL_VALIDATION_ERROR;
                break;
            }

            break;

          case "SubModule":
            switch (value) {
              case 0:
                text = "NotSpecified";
                break;
              case 1:
                text = "BillOfLading";
                break;
              case 2:
                text = "FreightInvoice";
                break;
              case 3:
                text = "CommercialInvoice";
                break;
              case 4:
                text = "Insurance";
                break;
              case 5:
                text = "Shipment";
                break;
              case 6:
                text = "Material";
                break;
            }

            break;

          case "QueueSystemType":
            switch (value) {
              case 0:
                text = "NotSpecified";
                break;
              case 1:
                text = "Shipment";
                break;
              case 2:
                text = "CommercialInvoiceDetail";
                break;
              case 3:
                text = "CommercialInvoiceExtraCosts";
                break;
              case 4:
                text = "InboundDeliveryCreateCheck";
                break;
            }

            break;

          case "WorkStateType":
            switch (value) {
              case 0:
                text = "NotSpecified";
                break;
              case 1:
                text = "Add";
                break;
              case 2:
                text = "Update";
                break;
              case 3:
                text = "Both";
                break;
            }

            break;

          case "DataStatus":
            switch (value) {
              case 0:
                text = "NoChange";
                break;
              case 1:
                text = "Added";
                break;
              case 2:
                text = "NotAdded";
                break;
              case 3:
                text = "Updated";
                break;
              case 4:
                text = "NotUpdated";
                break;
              case 5:
                text = "Deleted";
                break;
              case 6:
                text = "NotDeleted";
                break;
              case 7:
                text = "Show";
                break;
              case 8:
                text = "Hide";
                break;
              case 9:
                text = "DoNotCompare";
                break;
              case 10:
                text = "PartiallAdded";
                break;
              case 11:
                text = "PartialUpdated";
                break;
              case 12:
                text = "PartiallDeleted";
                break;
            }

            break;

          case "SystemType":
            switch (value) {
              case 0:
                text =
                  this.$root.dictionary
                    .TABLE_FILTER_ENUM_INTEGRATION_RESULT_NOT_SPECIFIED;
                break;
              case 1:
                text =
                  this.$root.dictionary.TABLE_FILTER_ENUM_SYSTEM_TYPE_MATERIAL;
                break;
              case 2:
                text =
                  this.$root.dictionary
                    .TABLE_FILTER_ENUM_SYSTEM_TYPE_COMMERCIAL_INVOICE;
                break;
              case 3:
                text =
                  this.$root.dictionary
                    .TABLE_FILTER_ENUM_SYSTEM_TYPE_COMMERCIAL_INVOICE_DETAIL;
                break;
              case 4:
                text =
                  this.$root.dictionary
                    .TABLE_FILTER_ENUM_SYSTEM_TYPE_TRUCK_RATE;
                break;
              case 5:
                text =
                  this.$root.dictionary
                    .TABLE_FILTER_ENUM_SYSTEM_TYPE_TRUCK_RATE_PALLET;
                break;
              case 6:
                text =
                  this.$root.dictionary
                    .TABLE_FILTER_ENUM_SYSTEM_TYPE_OCEAN_RATE;
                break;
              case 7:
                text =
                  this.$root.dictionary
                    .TABLE_FILTER_ENUM_SYSTEM_TYPE_GTIP_PAYMENT_TYPE;
                break;
              case 8:
                text =
                  this.$root.dictionary
                    .TABLE_FILTER_ENUM_SYSTEM_TYPE_BILL_OF_LADING;
                break;
              case 9:
                text =
                  this.$root.dictionary
                    .TABLE_FILTER_ENUM_SYSTEM_TYPE_FREIGHT_INVOICE;
                break;
              case 10:
                text =
                  this.$root.dictionary.TABLE_FILTER_ENUM_SYSTEM_TYPE_AIR_RATE;
                break;
              case 11:
                text =
                  this.$root.dictionary.TABLE_FILTER_ENUM_SYSTEM_TYPE_SHIPMENT;
                break;
              case 12:
                text =
                  this.$root.dictionary.TABLE_FILTER_ENUM_SYSTEM_CC_COST_RATE;
                break;
              case 13:
                text = this.$root.dictionary.TABLE_FILTER_CONTAINER_TRACKING;
                break;
              case 14:
                text =
                  this.$root.dictionary
                    .TABLE_FILTER_IMPORT_FROM_BONDED_WAREHOUSE;
                break;
              case 15:
                text =
                  this.$root.dictionary
                    .TABLE_FILTER_COMMERCIAL_INVOICE_EXTRA_COST;
                break;
              case 16:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_SYSTEM_CB_COST;
                break;
              case 17:
                text =
                  this.$root.dictionary
                    .MENU_HEADING_CUSTOMS_BROKERAGE_EXTRA_COST;
                break;
            }

            break;

          case "StatusTypes":
            switch (value) {
              case 1:
                text = this.$root.dictionary.LABEL_STATUS_WAITING_APPROVAL;
                break;
              case 2:
                text = this.$root.dictionary.LABEL_STATUS_APPROVED;
                break;
              case 3:
                text = this.$root.dictionary.LABEL_STATUS_REJECTED;
                break;
            }

            break;

          case "DistributionType":
            switch (value) {
              case 0:
                text = "NotSpecified";
                break;
              case 1:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_FREIGHT;
                break;
              case 2:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_FREIGHT_WEIGHT;
                break;
              case 3:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_TOTAL;
                break;
            }

            break;

          case "CalculationType":
            switch (value) {
              case 0:
                text = "NotSpecified";
                break;
              case 1:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_PRICE;
                break;
              case 2:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_UNIT_OF_WEIGHT;
                break;
              case 3:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_OVER_THE_RATE;
                break;
            }

            break;

          case "DeclarationType":
            switch (value) {
              case 0:
                text = "NotSpecified";
                break;
              case 1:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_BONDED;
                break;
              case 2:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_DIRECT;
                break;
            }

            break;

          case "BondedInOut":
            switch (value) {
              case 0:
                text = "NotSpecified";
                break;
              case 1:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_BONDED_IN;
                break;
              case 2:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_BONDED_OUT;
                break;
            }

            break;

          case "FinanceOperationType":
            switch (value) {
              case 0:
                text =
                  this.$root.dictionary
                    .TABLE_FILTER_ENUM_INTEGRATION_RESULT_NOT_SPECIFIED;
                break;
              case 1:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_POST_INVOICE;
                break;
              case 2:
                text =
                  this.$root.dictionary.TABLE_FILTER_ENUM_DOWN_PAYMENT_REQUEST;
                break;
            }

            break;

          case "Shift":
            switch (value) {
              case 0:
                text =
                  this.$root.dictionary
                    .TABLE_FILTER_ENUM_INTEGRATION_RESULT_NOT_SPECIFIED;
                break;
              case 1:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_A;
                break;
              case 2:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_B;
                break;
              case 3:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_C;
                break;
            }

            break;

          case "OtherInvoicePostingCheck":
            switch (value) {
              case 0:
                text = "NotSpecified";
                break;
              case 1:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_APPROVE;
                break;
              case 2:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_RETURN_INVOICE;
                break;
              case 3:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_FI;
                break;
              case 4:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_OTHER;
                break;
            }

            break;

          case "OtherInvoicePostingStatus":
            switch (value) {
              case 0:
                text = "NotSpecified";
                break;
              case 1:
                text = this.$root.dictionary.LABEL_INITIAL;
                break;
              case 2:
                text = this.$root.dictionary.LABEL_SUCCESS;
                break;
              case 3:
                text = this.$root.dictionary.LABEL_FAILED;
                break;
            }

            break;

          case "RunTimePeriod":
            switch (value) {
              case 1:
                text = this.$root.dictionary.LABEL_RUNTIMEPERIOD_IMMEDIATELY;
                break;
              case 2:
                text = this.$root.dictionary.LABEL_RUNTIMEPERIOD_MINUTE;
                break;
              case 3:
                text = this.$root.dictionary.LABEL_RUNTIMEPERIOD_HOURLY;
                break;
              case 4:
                text = this.$root.dictionary.LABEL_RUNTIMEPERIOD_DAILY;
                break;
              case 5:
                text = this.$root.dictionary.LABEL_RUNTIMEPERIOD_WEEKLY;
                break;
              case 6:
                text = this.$root.dictionary.LABEL_RUNTIMEPERIOD_MONTHLY;
                break;
            }

            break;

          case "WindowsServiceStatus":
            if (value) {
              text = this.$root.dictionary.LABEL_SERVICE_RUNNING;
            } else {
              text = this.$root.dictionary.LABEL_SERVICE_RUN_BEFORE;
            }

            break;

          case "BondedStatusTypes":
            switch (value) {
              case 16:
                text = this.$root.dictionary.LABEL_INITIAL;
                break;
              case 7:
                text = this.$root.dictionary.LABEL_STATUS_WORK_ORDER_SENT;
                break;
              case 17:
                text = this.$root.dictionary.LABEL_CUSTOMS_CLEARED;
                break;
            }

            break;

          case "CtrType":
            switch (value) {
              case 1:
                text = this.$root.dictionary.LABEL_CTR_WITH_PO;
                break;
              case 2:
                text = this.$root.dictionary.LABEL_CTR_WITH_STO;
                break;
              case 3:
                text = this.$root.dictionary.LABEL_CTR_WITHOUT_PO;
                break;
            }

            break;

          case "CbExtraCostType":
            switch (value) {
              case 0:
                text = "NotSpecified";
                break;
              case 1:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_FREE_OF_CHARGE;
                break;
              case 2:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_BULK_MATERIAL;
                break;
              case 3:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_SUPHALAN;
                break;
              case 4:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_PER_CONTAINER;
                break;
              case 5:
                text =
                  this.$root.dictionary.TABLE_FILTER_ENUM_FEATURED_PROCESSOZ3;
                break;
              case 6:
                text =
                  this.$root.dictionary.TABLE_FILTER_ENUM_FEATURED_PROCESSOZ4;
                break;
            }

            break;

          case "GtipType":
            switch (value) {
              case 0:
                text = "NotSpecified";
                break;
              case 1:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_DTS;
                break;
              case 2:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_TSE;
                break;
              case 3:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_REGISTRATION;
                break;
              case 4:
                text = this.$root.dictionary.TABLE_FILTER_ENUM_TAPDK;
                break;
            }

            break;

          case "BmrType":
            switch (value) {
              case 1:
                text = this.$root.dictionary.LABEL_BMR_WITH_PO;
                break;
              case 2:
                text = this.$root.dictionary.LABEL_BMR_WITHOUT_PO;
                break;
            }

            break;

          case "SapShipmentStatus":
            switch (value) {
              case 0:
                text = this.$root.dictionary.LABEL_STATUS_NOINFO;
                break;
              case 1:
                text = this.$root.dictionary.LABEL_STATUS_PLANNED;
                break;
              case 2:
                text = this.$root.dictionary.LABEL_STATUS_CHECKIN;
                break;
              case 3:
                text = this.$root.dictionary.LABEL_STATUS_LOADING_STARTED;
                break;
              case 4:
                text = this.$root.dictionary.LABEL_STATUS_LOADING_END;
                break;
              case 5:
                text = this.$root.dictionary.LABEL_STATUS_SHIPMENT_COMPLETION;
                break;
              case 6:
                text = this.$root.dictionary.LABEL_STATUS_SHIPMENT_STARTED;
                break;
              case 7:
                text = this.$root.dictionary.LABEL_STATUS_SHIPMENT_FINISHED;
                break;
            }

            break;
        }

        return text;
      },
      async goto(page) {
        this.currentPage = page;
        this.generateSearchKeys(true);
      },
      getDataFilters(type) {
        return this.dataFilters[type];
      },
      openSearchBar(event) {
        let clickedElement =
            typeof event.target !== "undefined" ? event.target : event,
          ref = this.$refs.searchDropDown,
          //headFilters = document.querySelector("thead tr.table-filtering");
          headFilters =
            clickedElement.closest(".table-headers").previousSibling;

        if (
          !this.$utils.isNullOrUndefined(clickedElement) &&
          ref.contains(clickedElement)
        ) {
          let column = clickedElement.attributes["data-column"].value,
            searchInput = document.querySelector(
              ".search-input[data-column='" + column + "']"
            ),
            dropdown = clickedElement
              .closest("td,th")
              .querySelector(".grid-filter-dropdown");
          if (
            !this.$utils.isNullOrUndefined(headFilters) &&
            !this.$utils.isNullOrUndefined(searchInput) &&
            !this.$utils.isNullOrUndefined(dropdown)
          ) {
            if (headFilters.classList.contains("hide")) {
              this.$utils.removeClass(headFilters, ["hide"]);
              searchInput.focus();
            } else {
              this.closeOtherDropDowns(dropdown);

              if (
                dropdown.classList.contains("closed") &&
                dropdown.classList.contains("hide")
              ) {
                this.$utils.removeClass(dropdown, ["closed", "hide"]);
                this.$utils.addClass(dropdown, ["opened"]);
              } else {
                this.$utils.removeClass(dropdown, ["opened"]);
                this.$utils.addClass(dropdown, ["hide", "closed"]);
              }
            }
          }
        }
      },
      getSearchValues(event) {
        if (event.keyCode == 13) {
          this.generateSearchKeys();
        }
      },
      generateSearchKeys(isPaging, externalReturn) {
        this.searchValues.length = 0;
        var searchValues = this.headers.filter((x) => {
          return (
            (x.value != "" && x.value != null) || x.filterRule == "Show empty"
          );
        });
        this.searchValues = searchValues.concat(this.defaultSearchValues);

        // var tempStartSearchValues = [];

        // if (this.searchValues.length > 0) {
        //   this.searchValues.forEach((searchValue) => {
        //     this.startedSearchValues.forEach((startedSearchValue) => {
        //       if (searchValue.column != startedSearchValue.column) {
        //         tempStartSearchValues.push(startedSearchValue);
        //       }
        //     });
        //   });
        // } else {
        //   Object.assign(tempStartSearchValues, this.startedSearchValue);
        // }

        // this.searchValues = searchValues.concat(tempStartSearchValues);

        if (typeof externalReturn !== "undefined" && externalReturn === true) {
          return this.searchValues;
        } else {
          this.doSearch(isPaging);
        }
      },
      async handleCheckboxSelection(event) {
        let closestTr = event.target.closest("tr");

        if (event.target.checked) {
          this.$utils.addClass(closestTr, ["selectedTr"]);
        } else {
          this.$utils.removeClass(closestTr, ["selectedTr"]);
        }
      },
      handleSelection(event) {
        let closestTr = event.target.closest("tr");

        if (event.target.localName === "label") {
          if (closestTr.classList.contains("row_selected")) {
            closestTr.classList.remove("row_selected");
          } else {
            closestTr.classList.add("row_selected");
          }
        }
      },
      selectAllCheckBoxes(event) {
        if (event.target.checked) {
          this.dataList.forEach((row) => {
            if (this.selectedRows.indexOf(row.id) == -1) {
              this.selectedRows.push(row.id);
            }
          });
        } else {
          this.selectedRows = this.selectedRows.filter(
            (x) => !this.dataList.map((y) => y.id).includes(x)
          );
        }
      },
      handleDateSearch(event) {
        let closestFilter = this.$utils.findChild(
          event.target.closest("td.position-relative,th.position-relative"),
          "i.la-filter"
        );

        if (!this.$utils.isNullOrUndefined(closestFilter)) {
          closestFilter.click();
        }

        this.generateSearchKeys();
      },
      async handleFilter(event) {
        let filterRule =
            event.target.localName == "li" || event.target.localName == "button"
              ? event.target.attributes["data-value"].value.trim()
              : event.target.value.toLowerCase() == "active" ||
                event.target.value.toLowerCase() == "yes"
              ? true
              : event.target.value.toLowerCase() == "show empty"
              ? "Show empty"
              : false,
          closestFilter = this.$utils.findChild(
            event.target.closest("td.position-relative,th.position-relative"),
            "i.la-filter"
          ),
          dataFilter = closestFilter.attributes["data-column"].value,
          filterHeader = this.headers.filter((x) => {
            return x.column == dataFilter;
          });

        if (!this.$utils.isNullOrUndefined(filterHeader)) {
          let el = this.$utils.findChild(
            closestFilter.closest("td.position-relative,th.position-relative"),
            "i.la-filter"
          );

          filterHeader[0].filterRule = filterRule;
          this.openSearchBar(el);
          this.generateSearchKeys();
        }
      },
      generateSearchParamsForDetail(masterTableName, masterId) {
        let masTabName = masterTableName
            ? masterTableName
            : this.masterTableName,
          searchObj = {
            DataType: "int",
            Column: masTabName + "Id",
            Value: masterId ? masterId : this.masterId
          };

        if (masTabName && masterTableName) {
          return [searchObj];
        } else if (masTabName) {
          this.searchValues.push(searchObj);
        }
      },
      headerConfigurations(headers) {
        this.detailHeaderConfigurations.forEach((headerConf) => {
          headers.forEach((header) => {
            if (
              header.column == headerConf.column &&
              header.table.toLowerCase() == headerConf.table.toLowerCase()
            ) {
              if (
                !this.$utils.isNullOrUndefined(headerConf.minimumFractionDigits)
              ) {
                header.minimumFractionDigits = headerConf.minimumFractionDigits;
              }

              if (
                !this.$utils.isNullOrUndefined(headerConf.maximumFractionDigits)
              ) {
                header.maximumFractionDigits = headerConf.maximumFractionDigits;
              }
            }
          });
        });

        return headers;
      },
      async doSearch(
        isPaging,
        detailSearchValue,
        detailColumnName,
        masterId,
        masterTableName
      ) {
        this.$utils.activateLoading();
        this.selectAllIsChecked = false;

        if (this.clearSelectedRows) {
          this.selectedRows = [];
        }

        let page = isPaging ? this.currentPage : 1,
          searchValue = detailSearchValue
            ? detailSearchValue
            : this.searchValue,
          searchParams = [];

        searchParams = this.generateSearchParamsForDetail(
          masterTableName,
          masterId
        );

        switch (this.searchType) {
          case searchTypes.URL: {
            let response = await axios.post(searchValue, {
              Page: page,
              Size: this.$route.fullPath
                .toLowerCase()
                .indexOf("sapshipment/list")
                ? 100
                : this.pageSize,
              SearchParameters: detailSearchValue
                ? searchParams
                : this.searchValues,
              SortParameters: detailSearchValue ? [] : this.sortValues,
              MenuId: this.menuId ? this.menuId : this.$route.meta.menuId,
              ComponentId: this.componentId
                ? this.componentId
                : this.$route.meta.componentId,
              TableId: this.tableId,
              withPaging: this.pagination
            });

            if (this.$utils.isResponseReturnedOk(response)) {
              this.subTotalList = response.data.dynamicList;

              if (!this.$utils.isNullOrUndefined(detailColumnName)) {
                let data = this.dataList.filter((x) => x.id == masterId)[0],
                  list = response.data.list || response.data.dynamicList;

                data[detailColumnName].list = list;
                data[detailColumnName].headers = response.data.headers;

                if (this.detailHeaderConfigurations.length > 0) {
                  data[detailColumnName].headers = this.headerConfigurations(
                    data[detailColumnName].headers
                  );
                }

                data[detailColumnName].currentPage = response.data.currentPage;
                data[detailColumnName].totalDataCount =
                  response.data.totalDataCount;
                data[detailColumnName].totalPageCount =
                  response.data.totalPageCount;

                this.forceRerender();
              } else {
                this.dataList = this.readFromDynamicList
                  ? response.data.dynamicList
                  : response.data.list || response.data.dynamicList;
                this.currentPage = response.data.currentPage;
                this.totalDataCount = response.data.totalDataCount;
                this.totalPageCount = response.data.totalPageCount;
              }
            } else {
              this.dataList = [];
              this.currentPage = 0;
              this.totalDataCount = 0;
              this.totalPageCount = 0;
              this.$utils.showErrorMessage(response.data.message);
            }

            this.$nextTick(() => {
              this.highlightRow();
            });
            break;
          }
          case searchTypes.STATE: {
            break;
          }
          case searchTypes.LOCALSTORAGE: {
            break;
          }
          case searchTypes.COLLECTION: {
            this.$utils.activateLoading();
            this.dataList = collectionHelper.filterListForCollection(
              this.searchValues,
              this.sortValues,
              this.collectionList
            );
            this.totalDataCount = this.dataList.length;
            break;
          }
        }

        this.$utils.disableLoading();
      },
      async handleSorting(event) {
        let element = event.target,
          dataFilter = element.attributes["data-column"].value,
          filterHeader = this.headers.filter((x) => {
            return x.column == dataFilter;
          });

        if (!this.$utils.isNullOrUndefined(filterHeader)) {
          if (
            filterHeader[0].orderBy === "" ||
            filterHeader[0].orderBy === null ||
            filterHeader[0].orderBy === "desc"
          ) {
            filterHeader[0].orderBy = "asc";
          } else if (filterHeader[0].orderBy === "asc") {
            filterHeader[0].orderBy = "desc";
          }
        }

        this.sortValues = filterHeader.filter((x) => {
          return x.orderBy != "" && x.orderBy != null;
        });
        this.generateSearchKeys();
      },
      getDateValue(attrs) {
        let cssClass = attrs.css,
          searchInput = document.querySelector(
            ".search-input[data-column='" + attrs.dataColumn + "']"
          ),
          header = this.headers.filter((x) => {
            return x.column == attrs.dataColumn;
          }),
          filterHeader = this.headers.filter((x) => {
            return x.column == attrs.dataColumn;
          }),
          dateFromControl = false;

        if (searchInput) {
          searchInput.value = "";

          if (cssClass.indexOf("from") > -1) {
            searchInput.setAttribute("date-from", attrs.value);
          } else {
            searchInput.setAttribute("date-to", attrs.value);
          }

          if (
            !this.$utils.isNullOrWhiteSpace(
              searchInput.getAttribute("date-from")
            )
          ) {
            searchInput.value += searchInput.getAttribute("date-from");
            dateFromControl = true;
            filterHeader[0].filterRule = "from";
          }

          if (
            !this.$utils.isNullOrWhiteSpace(searchInput.getAttribute("date-to"))
          ) {
            if (dateFromControl) {
              searchInput.value += " - " + searchInput.getAttribute("date-to");
              filterHeader[0].filterRule = "between";
            } else {
              searchInput.value += searchInput.getAttribute("date-to");
              filterHeader[0].filterRule = "to";
            }
          }

          header[0].value = searchInput.value;
        }
      },
      closeOtherDropDowns(dropdown) {
        let specifiedElements = document.querySelectorAll(".dropdown-content");

        specifiedElements.forEach(function (e) {
          if (
            e.parentNode !== dropdown &&
            e.parentNode.classList.contains("opened")
          ) {
            this.$utils.removeClass(e.parentNode, ["opened"]);
            this.$utils.addClass(e.parentNode, ["hide", "closed"]);
          }
        });
      },
      getSubColumnValue(column, header) {
        // example =>
        //   header="company.header"
        // column={ id:"1", company:{ id:"1", header:"company" } }
        let result = column,
          headers = header.split(".");

        for (let i = 0; i < headers.length; i++) {
          if (result != null) {
            result = result[headers[i]];
          }
        }

        result = this.getString(result);

        return result;
      },
      getString(value, header = null) {
        if (value != null) {
          if (value.length > 60) {
            value = value.substring(0, 60) + "...";
          }

          if (
            header != null &&
            header.dataType != null &&
            header.dataType.toLowerCase() == "decimal"
          ) {
            value = this.getDecimalValue(value, header);
          }

          if (
            typeof value === "string" &&
            (value.includes("<soap") || value.includes("<?xml"))
          ) {
            value = value
              .replace(/(?:\\[rn]|[\r\n])/g, "")
              .replace(/\\"/g, '"');
          }
        }

        return value;
      },
      getDecimalValue(value, header) {
        if (
          !this.$utils.isNullOrUndefined(header.minimumFractionDigits) &&
          !this.$utils.isNullOrUndefined(header.maximumFractionDigits)
        ) {
          value = value.toLocaleString("en-US", {
            minimumFractionDigits: header.minimumFractionDigits,
            maximumFractionDigits: header.maximumFractionDigits
          });
        } else if (
          !this.$utils.isNullOrUndefined(header.minimumFractionDigits)
        ) {
          value = value.toLocaleString("en-US", {
            minimumFractionDigits: header.minimumFractionDigits
          });
        } else if (
          !this.$utils.isNullOrUndefined(header.maximumFractionDigits)
        ) {
          value = value.toLocaleString("en-US", {
            maximumFractionDigits: header.maximumFractionDigits
          });
        } else {
          value = value.toLocaleString("en-US");
        }

        var valueSplit = value.split(".");

        valueSplit[0] = valueSplit[0]
          .toString()
          .replace(",", this.$root.parameters.DigitGroupingSymbol);

        if (!this.$utils.isNullOrUndefined(valueSplit[1])) {
          value =
            valueSplit[0] + this.$root.parameters.DecimalSymbol + valueSplit[1];
        }

        return value;
      },
      checkStringLimit(value) {
        if (value != null && value.length && value.length > 60) {
          return true;
        }
        return false;
      },
      readMore(data) {
        let type = "text";

        if (
          data.includes("<soapenv") ||
          data.includes("<SOAPENV") ||
          data.includes("<?xml") ||
          data.includes("<soap") ||
          data.includes("<SOAP") ||
          data.includes("<BTR") ||
          data.includes("<MTR") ||
          data.includes("<BMR")
        ) {
          data = data
            .replace(/\t/g, "")
            .replace(/\\"/g, '"')
            .replace(/(?:\\[rn]|[\r\n])/g, "")
            .replace(/(?:\\[t]|[\t])/g, "")
            .substring(0, data.length - 1);
        } else {
          type = "html";
        }

        switch (type) {
          case "html":
            this.$CtsModal.showHtmlModal(
              this,
              "",
              data
                .replace(/\r\n{2,}/g, "<br />")
                .replace(/\n{2,}/g, "<br />")
                .replace(/[\r\n]{2,}/g, "<br />")
            );
            break;

          default:
            this.$CtsModal.showTextModal(this, "", data);
            break;
        }
      },
      removeSearchFilter(header) {
        header.value = "";
        header.filterRule = "";
        this.generateSearchKeys();
      },
      removeSortFilter(header) {
        header.orderBy = "";
        this.sortValues = this.sortValues.filter((x) => {
          return x != header;
        });
        this.generateSearchKeys();
      },
      getFilterRule(header) {
        let rule = "";

        if (header.dataType !== "enum") {
          if (header.filterRule != "" && header.filterRule != null) {
            rule = this.headerFilters[header.dataType].filter((x) => {
              return x.value.toLowerCase() == header.filterRule.toLowerCase();
            })[0].title;
          } else {
            rule = this.headerFilters[header.dataType].filter((x) => {
              if (header.dataType == "string") {
                return x.value == "Contains";
              }
              if (header.dataType == "int" || header.dataType == "decimal") {
                return x.value == "Equals";
              }
              if (header.dataType == "date" || header.dataType == "datetime") {
                return x.value == "from";
              }
            })[0].title;
          }
        } else {
          rule = this.headerFilters["int"].filter((x) => {
            return x.value == "Equals";
          })[0].title;
        }

        return rule;
      },
      highlightRow() {
        if (window.location.pathname.indexOf("integrationlog") > -1) {
          let rows = document.querySelectorAll(".status");

          for (const row of rows) {
            if (row.classList.contains("status")) {
              // TODO
              // Add badge icon here
            }
          }
        }
      }
    }
  };
</script>

<style scoped>
  @import "../../components/data-table/data-table.css";
</style>
