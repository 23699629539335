const initialState = () => {
  return {
    menulist: []
  };
};
const state = () => initialState();

import axios from "axios";
import router from "@/router";

export const routerModule = {
  namespaced: true,
  state: state,
  mutations: {
    resetModuleState(state) {
      Object.assign(state, initialState());
    },
    setRoutersDynamically(state, list) {
      list.forEach(function (comp) {
        import(`@/views/${comp.url}.vue`).then(() => {
          const obj = {
            name: `${comp.menu.heading}_${comp.heading}`,
            path: `/${comp.url}`,
            meta: {
              layout: "default",
              componentId: `${comp.id}`,
              menuObjectTypeId: `${comp.menuObjectTypeId}`,
              isPublic: false
            },
            component: () => import(`@/views/${comp.url}.vue`)
          };

          router.addRoute(obj);
        });
      });
    }
  },
  actions: {
    RESET_APP_STATE({ commit }) {
      commit("resetModuleState");
    },
    GET_ROUTER_LIST({ commit }) {
      axios
        .post("menucomponent/getwithmenu")
        .then((response) => {
          if (response.status === 200) {
            var list = response.data.filter((element) => {
              return element.isActive && !element.isDeleted;
            });

            commit("setRoutersDynamically", list);
          }
        })
        .catch((error) => {
          console.log("error on dynamic router!");
          console.log(error);
        });
    }
  }
};
