const initialState = () => {
  return {
    isLoading: true
  };
};
const state = () => initialState();

export const loading = {
  namespaced: true,
  state: state,
  mutations: {
    resetModuleState(state) {
      Object.assign(state, initialState());
    },
    setLoadingState(state, value) {
      state.isLoading = value;
    }
  },
  actions: {
    SET_LOADING_STATE: ({ commit }, payload) => {
      commit("setLoadingState", payload);
    }
  }
};
