import axios from "axios";
import utils from "@/common/utils/utils";
import localStorageService from "@/services/LocalStorageService";
import tokenService from "@/services/TokenService";
import store from "@/store";
import router from "@/router";
//import _ from "lodash";

function isRedirectLogin(url) {
  let redirectUrls = require("@/configs/configs." +
    process.env.NODE_ENV +
    ".json");

  for (const obj in redirectUrls) {
    if (
      Object.prototype.hasOwnProperty.call(redirectUrls[obj], "url") &&
      redirectUrls[obj].url === url
    ) {
      return redirectUrls[obj].redirectLogin;
    }
  }
}

axios.interceptors.request.use(
  (config) => {
    const token = tokenService.getAccessToken();
    const refreshToken = tokenService.getRefreshToken();

    let languageStore = localStorageService.get("language"),
      languageList = !utils.isNullOrUndefined(languageStore)
        ? languageStore.languages
        : null,
      culture =
        languageList != null && languageList.length > 0
          ? languageList.filter((x) => x.id == languageStore.languageId)[0].code
          : "tr-tr";

    if (utils.isNullOrUndefined(config.headers.ContentType)) {
      config.headers.ContentType = "application/json";
    }

    config.headers.UserCulture = culture;
    // config.timeout = 15000; // in miliseconds = 15 mins

    if (!utils.isNullOrUndefined(router.currentRoute.meta.menuId)) {
      config.headers.menuid = router.currentRoute.meta.menuId;
    }

    if (
      config.url.indexOf("/auth/") === -1 &&
      token !== null &&
      typeof token !== "undefined" &&
      refreshToken !== null
    ) {
      config.headers.Authorization = "Bearer " + token.replace(/Bearer /g, "");
      config.headers.RefreshToken = refreshToken;
    }

    // return new Promise((resolve) => {
    //   _.debounce(() => resolve(config), 2000);
    // });
    return Promise.resolve(config);
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.status !== 401) {
      if (store.state.loading.isLoading) {
        setTimeout(() => {
          store.dispatch("loading/SET_LOADING_STATE", false);
        }, 500);
      }

      return Promise.resolve(response);
    }
  },
  async (error) => {
    console.log(error);
    store.dispatch("loading/SET_LOADING_STATE", false);

    if (error.response.status === 403) {
      // router.push("/status/forbidden");
    } else if (error.response.status === 401 && error.config) {
      let redirectLogin = isRedirectLogin(error.config.baseURL);

      if (redirectLogin) {
        let tokenInfo = localStorageService.get("user");

        if (tokenInfo !== null) {
          let _tokenInfo = {
            Email: tokenInfo.email,
            UserName: tokenInfo.UserName,
            AccessToken: tokenInfo.accessToken,
            RefreshToken: tokenInfo.refreshToken
          };

          return await store
            .dispatch("login/REFRESH_TOKEN", _tokenInfo)
            .then(async () => {
              await store.dispatch("login/SET_REFRESH_STATE", true);
              let req = await axios.request(error.config);
              await store.dispatch("login/SET_REFRESH_STATE", false);

              return Promise.resolve(req);
            });
        } else {
          router.push("/auth/login");
        }
      }
    }

    return Promise.resolve(error.response);
  }
);
