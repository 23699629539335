<template>
  <div id="login">
    <div class="container">
      <div id="login-row" class="row justify-content-center align-items-center">
        <div id="login-column" class="col-xs-12 col-sm-8 col-md-5">
          <div id="login-box" class="col-md-12">
            <form @submit.prevent="Login(loginInfo)">
              <h2 class="text-center">
                <span>{{ this.$root.dictionary.TITLE_SPOCK_WELCOME }}</span>
              </h2>
              <div class="w-100"></div>
              <div v-if="!isOAuth">
                <cts-input
                  :text="this.$root.dictionary.INPUT_EMAIL_OR_USERNAME"
                  name="username"
                  id="username"
                  v-model="loginInfo.UserName"
                  :model="loginInfo.UserName"
                  type="text"
                  :isOneLine="false"
                  :checkAuth="false"
                />
                <cts-input
                  :text="this.$root.dictionary.INPUT_PASSWORD"
                  type="password"
                  name="password"
                  id="password"
                  v-model="loginInfo.Password"
                  :model="loginInfo.Password"
                  :isOneLine="false"
                  :checkAuth="false"
                />
                <cts-checkbox
                  :text="this.$root.dictionary.INPUT_REMEMBERME"
                  name="rememberme"
                  id="rememberme"
                  v-model="loginInfo.RememberMe"
                  :model="loginInfo.RememberMe"
                  textAlign="Right"
                  type="carrot"
                  :checkAuth="false"
                />
                <div class="form-group">
                  <button
                    class="btn btn-primary btn-block"
                    type="submit"
                    :disabled="status"
                  >
                    {{ this.$root.dictionary.BUTTON_LOGIN }}
                  </button>
                </div>
              </div>
              <div v-else>
                <img
                  class="mx-auto d-block"
                  alt="loading"
                  src="@/assets/preloads/preloader.gif"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import app from "@/common/constants/app";
  import utils from "@/common/utils/utils";
  import awsTokenService from "@/services/AwsTokenService";
  import localStorageService from "@/services/LocalStorageService";
  import CtsInput from "@/components/ui-components/CtsInput/CtsInput";
  import CtsCheckbox from "@/components/ui-components/CtsCheckbox/CtsCheckbox";

  export default {
    name: "Login-Component",
    data() {
      return {
        loginInfo: {
          UserName: "",
          Password: "",
          RememberMe: false,
          ErrorMessage: ""
        },
        btnStatus: false
      };
    },
    components: { "cts-input": CtsInput, "cts-checkbox": CtsCheckbox },
    computed: {
      status() {
        return this.btnStatus;
      },
      isOAuth() {
        return app.loginWithActiveDirectory;
      }
    },
    methods: {
      async Login(info) {
        if (
          !app.loginWithActiveDirectory &&
          info.UserName != "" &&
          info.Password
        ) {
          this.$store
            .dispatch("login/LOGIN", info)
            .then(async () => {
              await this.$store.dispatch("bookmarksModule/GET_BOOKMARKS");
              await this.$store.dispatch("parameterModule/GET_PARAMETERS");
              this.$router.push("/home");
            })
            .catch((error) => {
              this.$CtsToastr.showToastr("error", error.data.message);
            });
        }
      },
      async LoginOrRegister(info) {
        await this.$store
          .dispatch("login/LOGIN_OR_REGISTER", info)
          .then(async () => {
            await this.$store.dispatch("bookmarksModule/GET_BOOKMARKS");
            await this.$store.dispatch("parameterModule/GET_PARAMETERS");
            this.$router.push("/home");
          })
          .catch((error) => {
            this.$CtsToastr.showToastr("error", error.data.message);
          });
      },
      async getUrlParams(urlParams, code) {
        if (!utils.isNullOrUndefined(code)) {
          let response = await axios.post("/auth/adlogin?code=" + code);

          if (this.$utils.isResponseReturnedOk(response)) {
            await awsTokenService.setStorage(response.data.dynamicData);
            this.$store.dispatch("login/SET_USER", response.data.data);
            this.$store.dispatch("bookmarksModule/GET_BOOKMARKS");
            this.$store.dispatch("parameterModule/GET_PARAMETERS");
            this.$router.push("/home");
          } else {
            this.$utils.showErrorMessage();
            this.$router.push("/auth/login");
          }
        }
      }
    },
    async mounted() {
      if (app.loginWithActiveDirectory) {
        let urlParams = new URLSearchParams(window.location.search),
          code = urlParams.get("code");

        if (!utils.isNullOrUndefined(code)) {
          await this.getUrlParams(urlParams, code);
        } else {
          window.location.replace(app.redirectUri);
        }
      } else {
        this.$utils.get("username").focus();
      }
    },
    created() {
      localStorageService.remove(["user", "cngtkn", "ltoft"]);
    }
  };
</script>

<style scoped>
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
  @import "../../styles/bootstrap.min.css";
  @import "../../styles/login.css";
</style>
