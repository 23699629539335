const initialState = () => {
  return {
    searchArr: [],
    sortArr: [],
    comps: ["About", "Home"],
    lastComponent: null,
    refreshHome: 0,
    masterDetailTableNames: []
  };
};
const state = () => initialState();

export const app = {
  namespaced: true,
  state: state,
  getters: {},
  mutations: {
    resetModuleState(state) {
      let refreshHome = state.refreshHome;
      Object.assign(state, initialState());
      state.refreshHome = refreshHome;
    },
    setRefreshHome(state) {
      state.refreshHome++;
    },
    setMasterDetailTableNames(state, payload) {
      state.masterDetailTableNames = payload;
    }
  },
  actions: {
    RESET_APP_STATE({ commit }) {
      commit("resetModuleState");
    },
    REFRESH_HOME({ commit }) {
      commit("setRefreshHome");
    },
    SET_MASTER_DETAIL_TABLE_NAMES({ commit }, payload) {
      commit("setMasterDetailTableNames", payload);
    }
  }
};
