<template>
  <div class="list-group">
    <h3>{{ $root.dictionary["OPTIONS_BOOKMARK_HEADING"] }}</h3>
    <div class="alert alert-warning" role="alert">
      {{ $root.dictionary["OPTIONS_INFO"] }}
    </div>
    <vue-tabs>
      <v-tab
        v-for="table in bookmarkNames"
        :key="table"
        :title="$root.dictionary['TABLE_' + table.toUpperCase()]"
      >
        <draggable
          v-model="list"
          draggable=".list-group-item"
          ghost-class="ghost"
          :move="handleChange"
        >
          <transition-group name="mix">
            <div
              :class="'list-group-item'"
              v-for="option in list"
              :key="option"
            >
              <div class="row">
                <div class="col-1">
                  <cts-checkbox
                    :name="option.menu.heading"
                    :id="option.menu.heading"
                    :val="option.id"
                    v-model="option.isChecked"
                    :model="option.isChecked"
                    :checkAuth="false"
                  />
                </div>
                <div class="col-10 row-info">
                  <p>
                    {{ $root.dictionary[option.menu.langDefinition] }}
                  </p>
                </div>
              </div>
            </div>
          </transition-group>
        </draggable>
        <div class="float-right mt20 mb20">
          <button
            type="button"
            class="btn btn-primary"
            @click.stop="saveBookmarkOptions"
          >
            {{ $root.dictionary["OPTIONS_SAVE_BUTTON"] }}
          </button>
        </div>
      </v-tab>
    </vue-tabs>
  </div>
</template>

<script>
  import draggable from "vuedraggable";
  import { VueTabs, VTab } from "vue-nav-tabs";
  import CtsCheckbox from "../ui-components/CtsCheckbox/CtsCheckbox";
  import "vue-nav-tabs/themes/vue-tabs.css";

  export default {
    name: "BookmarkOptions",
    components: { draggable, "cts-checkbox": CtsCheckbox, VueTabs, VTab },
    props: {
      options: Array,
      componentId: String,
      default: function () {
        return [];
      }
    },
    data() {
      return {
        bookmarkNames: ["Bookmarks"],
        list: []
      };
    },
    async created() {},
    watch: {
      options() {}
    },
    methods: {
      handleChange({ relatedContext, draggedContext }) {
        const draggedElement = draggedContext.element;
        const relatedElement = relatedContext.element;

        return (
          (!relatedElement || !relatedElement.isFixed) &&
          !draggedElement.isFixed
        );
      },
      async GetUserBookmarks() {
        var list = this.$store.state.bookmarksModule.bookmarks;

        list.map((x) => {
          x.isChecked = true;
          x.isFixed = false;
        });

        return list != null && list.length > 10 ? list.slice(0, 10) : list;
      },
      async saveBookmarkOptions() {
        await this.$store.dispatch(
          "bookmarksModule/UPDATE_BOOKMARKS",
          this.list.filter((x) => x.isChecked),
          { root: true }
        );
      }
    },
    async mounted() {
      this.list = await this.GetUserBookmarks();
    }
  };
</script>

<style scoped>
  div.list-group-item {
    padding: 5px !important;
  }
  div.list-group-item:hover {
    cursor: move;
  }
  .ghost {
    opacity: 0.5;
    background: #efefef;
  }
  .row-info {
    display: flex;
    align-items: center;
  }
</style>
