<template>
  <div>
    <div class="loading" v-show="isLoading">
      <img src="../../src/assets/preloads/preloader.gif" />
    </div>
  </div>
</template>
<script>
  export default {
    name: "VueLoading",
    watch: {
      isLoading() {}
    },
    computed: {
      isLoading() {
        return this.$store.state.loading.isLoading;
      }
    }
  };
</script>

<style scoped>
  .loading {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    z-index: 9999999 !important;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    opacity: 0.8;
  }
  .loading i {
    position: absolute;
    opacity: 1;
  }
</style>
