<template>
  <div
    class="row justify-content-center align-items-flex-start col-md-12 col-lg-12"
    style="position: relative"
  >
    <div class="col-md-6 col-lg-6" style="text-align: left">
      <cts-multiselect
        v-model="multiselectData"
        :model="multiselectData"
        label="heading"
        trackBy="id"
        text="Multiselect"
        id="MultiSelect"
        :isOneLine="true"
        url="/role/GetForMultiselect"
        :multiple="true"
        :closeOnSelect="false"
        name="MultiSelect"
      ></cts-multiselect>
      <br />
      <cts-multiselect
        v-model="singleSelectData"
        :model="singleSelectData"
        label="heading"
        trackBy="heading"
        text="SingleSelect"
        id="SingleSelect"
        :isOneLine="true"
        url="/role/GetForMultiselect"
        name="SingleSelect"
        :defaultOptions="options"
      ></cts-multiselect>
    </div>
    <div class="col-md-6 col-lg-6" style="text-align: left">
      <ul>
        <li>
          <b>Props:</b>
          <ul>
            <li>text -> Type String (Required)</li>
            <li>id -> Type String (Required)</li>
            <li>placeholder -> Type String (Not required, default: "")</li>
            <li>model -> Type Array (Required)</li>
            <li>maxHeight -> Type Number (Not required, default: 200)</li>
            <li>label -> Type String (Required)</li>
            <small>
              (Used to determines in which field of incoming data to search)
            </small>
            <li>trackBy -> Type String (Required)</li>
            <small>
              (Used to compare objects. Only use if options are objects.)
            </small>
            <li>isOneLine -> Type Boolean (Not required, default: true)</li>
            <small>
              (Used to determine whether the component and name will be on a
              line)
            </small>
            <li>
              openDirection -> Type String (Not required, default: "bottom")
            </li>
            <small>
              (Fixed opening direction (instead of auto). Options are
              "above"/"top" or "below"/"bottom")
            </small>
            <li>optionsLimit -> Type Number (Not required, default: 300)</li>
            <small>
              (Limits the options displayed in the dropdown to the first X
              options.)
            </small>
            <li>
              limitText -> Type String (Not required, default: " other options")
            </li>
            <small>
              (The message shown when selected elements pass the defined limit)
            </small>
            <li>limit -> Type Number (Not required, default: 3)</li>
            <small>
              (Limit the display of selected options. The rest will be hidden
              within the limitText string.)
            </small>
            <li>url -> Type String (Required)</li>
            <small>
              (The address to which the request will be sent to bring the data)
            </small>
            <li>multiple -> Type Boolean (Not required, default: false)</li>
            <small>
              (Used to determine whether multiple selections can be made)
            </small>
            <li>closeOnSelect -> Type Boolean (Not required, default: true)</li>
            <small> (Used to close the dropdown after selection) </small>
            <li
              >Reference :
              <a href="https://vue-multiselect.js.org/">Vue-multiselect</a></li
            >
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import axios from "axios";
  import CtsMultiselect from "@/components/ui-components/CtsMultiselect/CtsMultiselect";
  export default {
    name: "CtsMultiselectComponent",
    data() {
      return {
        options: [],
        multiselectData: [
          {
            dateCreated: "2019-12-31T00:00:00",
            dateUpdated: null,
            heading: "User",
            id: 2,
            isActive: true,
            isDeleted: false,
            MenuObjectPermissions: [],
            userRoles: []
          }
        ],
        singleSelectData: [
          {
            dateCreated: "2019-12-31T00:00:00",
            dateUpdated: null,
            heading: "User",
            id: 2,
            isActive: true,
            isDeleted: false,
            MenuObjectPermissions: [],
            userRoles: []
          }
        ]
      };
    },
    components: {
      "cts-multiselect": CtsMultiselect
    },
    async mounted() {
      let response = await axios.post("/role/GetForMultiselect", {
        searchObj: ""
      });

      this.options = response.data.list;
    }
  };
</script>
