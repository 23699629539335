const initialState = () => {
  return {
    statusTypes: []
  };
};
const state = () => initialState();

import axios from "axios";
import utils from "@/common/utils/utils";

export const statusTypeModule = {
  namespaced: true,
  state: state,
  mutations: {
    resetModuleState(state) {
      Object.assign(state, initialState());
    },
    setStatus(state, statusTypes) {
      state.statusTypes = statusTypes;
    }
  },
  actions: {
    GET_STATUS: ({ commit }) => {
      axios
        .get("statusType/getAll")
        .then((response) => {
          if (utils.isResponseReturnedOk(response)) {
            commit("setStatus", response.data.list);
          }
        })
        .catch((error) => {
          console.log("error on get status types " + error);
        });
    }
  }
};
