const localStorageService = {
  set(...args) {
    args.forEach((arg) => {
      if (typeof arg.value === "object") {
        localStorage.setItem(arg.key, JSON.stringify(arg.value));
      } else {
        localStorage.setItem(arg.key, arg.value);
      }
    });
  },
  get(key) {
    let result = localStorage.getItem(key);

    if (typeof result !== undefined && result != null && result[0] === "{") {
      return JSON.parse(result);
    } else {
      return result;
    }
  },
  remove(...args) {
    args.forEach((arg) => {
      localStorage.removeItem(arg);
    });
  },
  clear() {
    localStorage.clear();
  }
};

export default localStorageService;
