<template>
  <div v-if="this.$utils.canShow(name) || !checkAuth">
    <div v-if="isFormGroup">
      <div :class="outerDivClass">
        <label :for="id" :class="labelDivClass">{{ text }}</label>
        <div :class="inputDivClass">
          <textarea
            v-if="!disabled && (this.$utils.canModify(name) || !checkAuth)"
            :type="type"
            :name="name"
            :id="id"
            :v-model="model"
            :value="model"
            @input="update($event.target.value)"
            class="form-control cts-input"
            :rows="rows"
            :placeholder="placeholder"
            :disabled="disabled"
            :class="[css, isRequired ? 'requiredField' : '']"
          />
          <div class="disabledField" v-else>{{ model }}</div>
          <div class="errors" v-if="validator.$error">
            <div
              class="error"
              v-for="error in activeErrorMessages"
              :key="error"
            >
              <span> {{ getError(error) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!disabled && (this.$utils.canModify(name) || !checkAuth)">
      <textarea
        :type="type"
        :name="name"
        :id="id"
        :v-model="model"
        :value="model"
        @input="update($event.target.value)"
        class="form-control cts-input"
        :rows="rows"
        :placeholder="placeholder"
        :class="[css, isRequired ? 'requiredField' : '']"
      />
      <div class="errors" v-if="validator.$error">
        <div class="error" v-for="error in activeErrorMessages" :key="error">
          <span>{{ getError(error) }}</span>
        </div>
      </div>
    </div>
    <div class="disabledField pa-10 lh150" v-else>{{ model }}</div>
  </div>
</template>

<script>
  import { singleErrorExtractorMixin } from "vuelidate-error-extractor";

  export default {
    name: "CtsTextarea",
    mixins: [singleErrorExtractorMixin],
    data() {
      return {
        outerDivClass: "form-group ",
        labelDivClass: "control-label ",
        inputDivClass: ""
      };
    },
    props: {
      text: {
        type: String,
        default: ""
      },
      name: {
        type: String,
        default: ""
      },
      id: {
        type: [String, Number],
        default: ""
      },
      model: {
        type: String,
        required: true
      },
      type: {
        type: String,
        default: "text"
      },
      isOneLine: {
        type: Boolean,
        default: true
      },
      rows: {
        type: Number,
        default: 2
      },
      placeholder: {
        type: String,
        default: ""
      },
      isFormGroup: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      labelClass: {
        type: String,
        default: ""
      },
      inputClass: {
        type: String,
        default: ""
      },
      checkAuth: {
        // for check menu object permission
        type: Boolean,
        default: true
      },
      isRequired: {
        type: Boolean,
        default: false
      },
      css: {
        type: [String, Array],
        default: ""
      }
    },
    methods: {
      getClass() {
        if (this.isOneLine) {
          this.outerDivClass += "row";
          this.labelDivClass += "col-xs-12 col-sm-4 col-md-3 col-lg-3";
          this.inputDivClass += "col-xs-12 col-sm-8 col-md-9 col-lg-9";
        }

        if (this.labelClass != "" && this.inputClass != "") {
          this.labelDivClass = "control-label " + this.labelClass + " ";
          this.inputDivClass = this.inputClass + " ";
        }
      },
      update(value) {
        this.$emit("input", value);
      },
      getError(error) {
        return this.$utils.getVuelidateErrorMessage(error);
      }
    },
    created() {
      this.getClass();
    }
  };
</script>

<style scoped>
  .cts-input {
    min-height: 40px;
  }
</style>
