<template>
  <ul id="sidebar-menu" :class="this.css">
    <li v-for="menu in sortedlist" :key="menu.id" :class="isMenuActive(menu)">
      <span v-if="menu.url != null">
        <router-link :class="isUrlActive(menu.url)" :to="menuUrl(menu.url)">
          {{ $root.dictionary[menu.langDefinition] }}
        </router-link>
        <a
          href="javascript:void(0);"
          class="bookmark"
          @click.stop="setBookmark(menu)"
        >
          <i :class="isBookmarked(menu)"></i>
        </a>
      </span>
      <span v-else>
        <a
          href="javascript:void(0);"
          :class="isUrlActive(menu.url)"
          @click.stop="openMenu"
          >{{ $root.dictionary[menu.langDefinition] }}</a
        >
      </span>
      <side-bar
        v-if="menu.menuList != null && menu.menuList.length > 0"
        :list="menu.menuList"
        :css="'submenu'"
      ></side-bar>
    </li>
  </ul>
</template>

<script>
  export default {
    name: "Sidebar-Component",
    props: { list: Array, css: String },
    methods: {
      isMenuActive(menu) {
        let cls = "",
          currentUrl = this.$router.currentRoute.path.toLowerCase(),
          isInSub = menu.menuList.filter(function (e) {
            return currentUrl.indexOf(e.url.toLowerCase()) > -1;
          });

        if (isInSub.length) {
          cls += "active ";
        }

        cls +=
          menu.menuList != null && menu.menuList.length > 0
            ? "hassubmenu "
            : "";

        return cls.trim();
      },
      menuUrl(url) {
        return url != null ? url + "/list" : "javascript:void(0);";
      },
      openMenu(event) {
        let item = event.target,
          clicked = item.closest(".active"),
          openli = document.querySelectorAll("#sidebar .active");

        // first close all opened
        for (const element of openli) {
          element.classList.toggle("active");
        }

        if (clicked == null) {
          clicked = item.closest("li");
          clicked.classList.toggle("active");
        } else {
          clicked.classList.remove("active");
        }
      },
      isBookmarked(menu) {
        let cls = "",
          bookmarks = this.$store.state.bookmarksModule.bookmarks,
          isBookmarked =
            bookmarks !== null && bookmarks != [] && bookmarks !== "undefined"
              ? bookmarks.findIndex((v) => v.menuId === menu.id) > -1
              : false;

        cls = isBookmarked ? "fas fa-bookmark" : "las la-bookmark";

        return cls;
      },
      setBookmark(menu) {
        let bookmarks = this.$store.state.bookmarksModule.bookmarks,
          isBookmarked =
            bookmarks !== null && bookmarks != [] && bookmarks !== "undefined"
              ? bookmarks.findIndex((v) => v.menuId === menu.id) > -1
              : false;

        if (isBookmarked) {
          this.$store.dispatch("bookmarksModule/REMOVE_BOOKMARK", menu, {
            root: true
          });
        } else {
          this.$store.dispatch("bookmarksModule/ADD_BOOKMARK", menu, {
            root: true
          });
        }
      }
    },
    computed: {
      sortedlist() {
        return this.list != null
          ? this.list
              .filter((element) => {
                return element.isActive;
              })
              .sort((first, second) => {
                return first.sequence - second.sequence;
              })
          : [];
      },
      isUrlActive: (vm) => {
        return (menuUrl) => {
          return menuUrl != null &&
            vm.$route.path
              .split("/")
              .filter(
                (e) =>
                  e.toLowerCase() ===
                  menuUrl.slice(1, menuUrl.length).toLowerCase()
              ).length > 0
            ? "active"
            : "";
        };
      }
    }
  };
</script>

<style scoped>
  #sidebar ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  #sidebar a {
    display: inline-block;
    text-decoration: none;
    padding: 8px;
    color: #fff;
    width: 90%;
  }

  #sidebar a.bookmark {
    display: table-row !important;
    text-decoration: none;
    padding: 8px;
    color: #fff;
    width: 10%;
  }

  #sidebar a.router-link-active,
  #sidebar a.active,
  a:hover {
    transition: ease 0.2s;
    font-weight: bold;
    margin: 0 5px;
    border-radius: 3px;
    width: 85% !important;
  }

  #sidebar ul,
  #sidebar li {
    transition: height 0.4s linear;
  }

  #sidebar li.active,
  #sidebar li.active .submenu {
    display: block;
    max-height: 100%;
    transition: height 0.4s linear;
  }

  .submenu {
    background-color: #364554 !important;
    max-height: 0;
    overflow: hidden;
    transition: height 0.4s linear;
  }

  .submenu li {
    padding: 1px 0px;
  }

  ul.submenu li a {
    padding: 8px 16px !important;
    display: inline-block;
  }

  #sidebar ul li {
    position: relative;
  }

  #sidebar li.hassubmenu span:not(:only-child):before {
    content: "\f105";
    font-family: "Line Awesome Free";
    position: absolute;
    right: 10px;
    top: 10px;
    font-weight: 700;
  }

  #sidebar li.hassubmenu.active span:not(:only-child):before {
    content: none;
  }

  #sidebar li.hassubmenu.active span:not(:only-child):after {
    content: "\f107";
    font-family: "Line Awesome Free";
    position: absolute;
    right: 10px;
    top: 10px;
    font-weight: 700;
  }

  #sidebar li.hassubmenu.active span:not(:only-child):after {
    content: "\f107";
    font-family: "Line Awesome Free";
    font-weight: 700;
  }
</style>
