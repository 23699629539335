<template>
  <div
    class="row justify-content-center align-items-flex-start col-md-12 col-lg-12"
    style="position: relative"
  >
    <div class="col-md-6 col-lg-6" style="text-align: left">
      <cts-flatpickr
        v-model="flatpickrData"
        :model="flatpickrData"
        :time="true"
        placeholder="Select date and time"
        name="date"
        text="Flatpickr"
        id="date"
      />

      <div class="form-group row">
        <label
          for="example"
          class="control-label col-xs-12 col-sm-4 col-md-3 col-lg-3"
        ></label>
        <div class="col-xs-12 col-sm-8 col-md-9 col-lg-9">
          <div class="example" id="example">
            <h5>
              <pre> {{ example }} </pre>
            </h5>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6" style="text-align: left">
      <ul>
        <li>
          <b>Props:</b>
          <ul>
            <li>text -> Type String (Not required, default: "" )</li>
            <li>name -> Type String (Not required, default: "" )</li>
            <li>id -> Type String, Number (Not required, default: "" )</li>
            <li>placeholder -> Type String (Not required, default: "")</li>
            <li>model -> Type String (Required)</li>
            <li>time -> Type Boolean (Not required, default: false)</li>
            <small>(used to determine whether to choose the time)</small>
            <li>isOneLine -> Type Boolean (Not required, default: true)</li>
            <small>
              (Used to determine whether the component and name will be on a
              line)
            </small>
            <li>cssClass -> Type String (Not required, default: "")</li>
            <small> (Used to add custom class) </small>
            <li
              >Reference :
              <a href="https://github.com/ankurk91/vue-flatpickr-component"
                >Vue FlatPickr</a
              ></li
            >
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import CtsFlatpickr from "@/components/ui-components/CtsFlatpickr/CtsFlatpickr";
  export default {
    name: "CtsFlatpickerComponent",
    data() {
      return {
        flatpickrData: "",
        example:
          "<cts-flatpickr" +
          "\n" +
          ' text="Flatpickr"' +
          "\n" +
          ' name="date"' +
          "\n" +
          ' id="date"' +
          "\n" +
          ' v-model="flatpickrData"' +
          "\n" +
          ' :model="flatpickrData"' +
          "\n" +
          ' :time="true"' +
          "\n" +
          ' placeholder="Select date and time. ' +
          "\n" +
          " />"
      };
    },
    components: {
      "cts-flatpickr": CtsFlatpickr
    }
  };
</script>
