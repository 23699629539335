<template>
  <component :is="layout">
    <cts-loading></cts-loading>
    <router-view :key="$route.fullPath" />
  </component>
</template>

<script>
  import { mapState } from "vuex";
  import axios from "axios";
  import localStorageService from "@/services/LocalStorageService";
  import LoadingComponent from "@/components/LoadingComponent";

  const default_layout = "default";

  export default {
    name: "app",
    components: { "cts-loading": LoadingComponent },
    methods: {
      checkUserInfo() {
        let userInfo = localStorageService.get("user");

        if (!this.$utils.isNullOrUndefined(userInfo)) {
          axios
            .get("/token/validatetoken", {
              params: {
                token: userInfo.accessToken
              }
            })
            .catch((error) => {
              console.log(error);
              this.$router.push("/auth/login");
            });
        }
      },
      getMenuObjectWithPermission() {
        let componentId = this.$route.meta.componentId,
          menuId = this.$route.meta.menuId,
          userRoleIds = this.$store.state.login.user.userRoles.map((x) => {
            return x.roleId;
          });

        let request = {
          ComponentId: componentId,
          RoleIds: userRoleIds,
          MenuId: menuId
        };

        this.$store.dispatch(
          "menuObject/GET_MENU_OBJECT_WITH_PERMISSION",
          request
        );
      }
    },
    created() {
      this.$store.state.app.lastComponent = () =>
        import(`@/views/${this.$store.state.app.comps[0]}.vue`);
    },
    computed: {
      ...mapState(["app", "roleModule"]),
      layout() {
        if (
          this.$route.meta.componentId &&
          !this.$utils.isNullOrUndefined(this.$store.state.login.user)
        ) {
          this.getMenuObjectWithPermission();
        }

        return (this.$route.meta.layout || default_layout) + "-layout";
      }
    }
  };
</script>

<style>
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
</style>
