import axios from "axios";

const initialState = () => {
  return {
    menuObjects: []
  };
};
const state = () => initialState();

export const menuObject = {
  namespaced: true,
  state: state,
  getters: {
    getUser: (state) => {
      return state.user;
    }
  },
  mutations: {
    resetModuleState(state) {
      Object.assign(state, initialState());
    },
    setMenuObject(state, menuObjects) {
      state.menuObjects = menuObjects;
    }
  },
  actions: {
    RESET_APP_STATE({ commit }) {
      commit("resetModuleState");
    },
    GET_MENU_OBJECT_WITH_PERMISSION({ commit }, payload) {
      axios
        .post("menuobject/getpermissions", payload)
        .then((response) => {
          if (response.status === 200 && response.data.state) {
            commit("setMenuObject", response.data.list);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }
};
