<template>
  <div>
    <Navbar></Navbar>
    <slot />
  </div>
</template>

<script>
  import Navbar from "@/components/NavbarComponent";

  export default {
    name: "NoSideBar-Layout",
    components: {
      Navbar
    }
  };
</script>
