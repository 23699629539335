<template>
  <div>
    <div
      class="row justify-content-center align-items-flex-start col-md-12 col-lg-12"
      style="position: relative"
    >
      <div class="col-md-6 col-lg-6" style="text-align: left">
        <cts-input
          text="Input"
          name="username"
          id="Input"
          v-model="inputData"
          :model="inputData"
          placeholder="This is a single line input."
        />

        <div class="form-group row">
          <label
            for="example"
            class="control-label col-xs-12 col-sm-4 col-md-3 col-lg-3"
          ></label>
          <div class="col-xs-12 col-sm-8 col-md-9 col-lg-9">
            <div class="example" id="example">
              <h5>
                <pre> {{ example }} </pre>
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-6" style="text-align: left">
        <ul>
          <li>
            <b>Props:</b>
            <ul>
              <li>text -> Type String (Not required, default: "" )</li>
              <li>name -> Type String (Not required, default: "" )</li>
              <li>id -> Type String, Number (Not required, default: "" )</li>
              <li>model -> Type String (Required)</li>
              <li>type -> Type String (Not required, default: "text" )</li>
              <li>placeholder -> Type String (Not required, default: "" )</li>
              <li>isOneLine -> Type Boolean (Not required, default: true)</li>
              <small>
                (Used to determine whether the component and name will be on a
                line)
              </small>
              <li>isFormGroup -> Type Boolean (Not required, default: true)</li>
              <small>
                (If element is not used in form group and label will not be
                added, it is used as false.)
              </small>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
  import CtsInput from "@/components/ui-components/CtsInput/CtsInput";
  export default {
    name: "CtsInputComponent",
    data() {
      return {
        inputData: "",
        example:
          "<cts-input " +
          "\n" +
          ' text="Input"' +
          "\n" +
          ' name="username"' +
          "\n" +
          ' id="Input"' +
          "\n" +
          ' v-model="inputData"' +
          "\n" +
          ' :model="inputData"' +
          "\n" +
          ' placeholder="This is a single line' +
          "\n" +
          '  input."' +
          "\n" +
          " />"
      };
    },
    components: {
      "cts-input": CtsInput
    }
  };
</script>
