<template>
  <div class="pt20">
    <div class="alert alert-warning">
      {{ $root.dictionary["OPTIONS_INFO"] }}
    </div>
    <div class="list-group">
      <vue-tabs @tab-change="handleTabChange">
        <v-tab
          v-for="(table, index) in tables"
          :key="index"
          :title="
            !$utils.isNullOrWhiteSpace(
              $root.dictionary['TABLE_' + table.toUpperCase()]
            )
              ? $root.dictionary['TABLE_' + table.toUpperCase()]
              : 'Columns'
          "
        >
          <draggable
            v-model="list"
            draggable=".list-group-item"
            ghost-class="ghost"
            :move="handleChange"
            class="ghost-container"
          >
            <transition-group>
              <div
                :class="'list-group-item no_margin no_padding'"
                v-for="(option, index) in list"
                :key="index"
              >
                <div class="form-group no_margin no_padding row">
                  <div
                    class="col-1 sequence"
                    style="background-color: #efefef; border: 1px"
                  >
                    <b>{{ index + 1 }}</b>
                  </div>
                  <div class="col-1 checkbox">
                    <cts-checkbox
                      :set="
                        (rand = (Date.now() * Math.random())
                          .toString()
                          .replace('.', ''))
                      "
                      :disabled="option.isFixed || option.isRequired"
                      :name="option.column + rand"
                      :id="option.column + rand"
                      :val="option.id"
                      v-model="option.isChecked"
                      :model="option.isChecked"
                      :checkAuth="false"
                    />
                  </div>
                  <div class="col-10">
                    <p class="col_name">
                      {{ getColumnLanguageDefinition(option.langDefinition) }}
                    </p>
                  </div>
                </div>
              </div>
            </transition-group>
          </draggable>
          <div class="row rowContent">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-spock float-right"
                @click.stop="saveGridOptions"
                >{{ $root.dictionary["OPTIONS_SAVE_BUTTON"] }}</button
              >
            </div>
          </div>
        </v-tab>
      </vue-tabs>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import draggable from "vuedraggable";
  import { VueTabs, VTab } from "vue-nav-tabs";
  import CtsCheckbox from "../ui-components/CtsCheckbox/CtsCheckbox";
  import "vue-nav-tabs/themes/vue-tabs.css";

  export default {
    name: "GridOptions",
    components: { draggable, "cts-checkbox": CtsCheckbox, VueTabs, VTab },
    props: {
      componentId: {
        type: [String, Number]
      },
      table: {
        type: String
      }
    },
    data() {
      return {
        selectedTabName: null,
        menuLayoutTableNames: [],
        list: [],
        tables: []
      };
    },
    watch: {},
    computed: {
      getList() {
        return this.list;
      }
    },
    methods: {
      handleChange({ relatedContext, draggedContext }) {
        const draggedElement = draggedContext.element,
          relatedElement = relatedContext.element;

        return (
          (!relatedElement || !relatedElement.isFixed) &&
          !draggedElement.isFixed
        );
      },
      async GetMenuLayoutTableNames(menuId) {
        let response = await axios.get("menuObject/" + menuId + "/tablenames");

        if (this.$utils.isResponseReturnedOk(response)) {
          this.selectedTabName = response.data.list[0];

          return response.data.list;
        }
      },
      async getColumns() {
        let response = await axios.post("menuObject/getcolumns", {
          menuId: parseInt(this.$route.meta.menuId),
          componentId: parseInt(this.componentId),
          table: this.selectedTabName
        });

        if (this.$utils.isResponseReturnedOk(response)) {
          this.list = response.data.data.filter((x) => x.sequence > 0);
        }
      },
      async handleTabChange(tabIndex, newTab, oldTab) {
        if (newTab != oldTab) {
          this.selectedTabName = this.tables[tabIndex];
          await this.getColumns();
        }
      },
      async saveGridOptions() {
        this.$utils.activateLoading();
        let response = await axios.post("/menuObject/saveuserlayout/", {
          componentId: this.componentId,
          userLayoutVm: this.list,
          table: this.selectedTabName
        });

        if (this.$utils.isResponseReturnedOk(response)) {
          var defaultComp = this.$root.$children[0].$children[0];

          defaultComp.$data.isGripOptionsChanges++;
          Promise.resolve(response);
          this.$utils.showSuccessMessage(
            this.$root.dictionary.MSG_OPERATION_SUCCESSFULLY_COMPLETED
          );
        } else {
          Promise.reject(response);
        }

        this.$utils.disableLoading();
      },
      getColumnLanguageDefinition(value) {
        return this.$root.dictionary[value];
      }
    },
    async created() {
      this.tables =
        this.$store.state.app.masterDetailTableNames.length > 0
          ? this.$store.state.app.masterDetailTableNames
          : [this.table];

      this.selectedTabName = this.tables[0];

      await this.getColumns();
    }
  };
</script>

<style scoped>
  /* div.list-group-item {
    padding: 5px !important;
  } */
  div.list-group-item:hover {
    cursor: move;
  }
  div.list-group-item p,
  div.list-group-item b {
    padding-top: 10px;
  }
  div.sequence,
  div.checkbox {
    max-width: 50px;
    vertical-align: middle !important;
    text-align: center !important;
  }
  div.col_name {
    text-align: left !important;
  }
  div.row {
    margin-left: 2px !important;
  }
  .ghost {
    opacity: 0.5;
    background: #efefef;
  }
  .ghost-container {
    max-height: 600px;
    overflow: hidden;
    overflow-y: scroll;
    padding-bottom: 20px !important;
  }
  .row-info {
    display: flex;
    align-items: center;
    align-content: center;
    min-width: 50px;
    text-align: center !important;
  }
</style>
