import store from "@/store/index";
import utils from "../utils/utils";
import menuObjectType from "@/common/enums/menuObjectType";

const objectPermissionHelper = {
  checkMenuObjectPermissionsSee(objectIdentifier) {
    let menuObject = store.state.menuObject.menuObjects.find(
      (x) => x.objectIdentifier == objectIdentifier
    );
    let user = store.state.login.user,
      canSeeList = [],
      canLimited = true;

    if (
      !utils.isNullOrUndefined(menuObject) &&
      !utils.isNullOrUndefined(user)
    ) {
      if (!user.systemAdmin) {
        if (menuObject.menuObjectTypeId === menuObjectType.TOOLBAR_OBJECT) {
          let menuObjectModules = menuObject.modulePermissions.map(
              (x) => x.moduleId
            ),
            userModules = user.customerGroupModules.map((x) => x.moduleId);

          let canSee = false;

          if (menuObjectModules.length > 0) {
            menuObjectModules.forEach((objectModule) => {
              if (userModules.indexOf(objectModule) > -1) {
                canSee = true;
              }
            });
          }

          if (!canSee) {
            return false;
          }
        }

        if (!user.isAdmin) {
          user.userRoles.forEach((role) => {
            let permission = menuObject.menuObjectPermissions.find(
              (permission) =>
                !permission.isDeleted && permission.roleId == role.roleId
            );
            if (utils.isNullOrUndefined(permission)) {
              canLimited = false;
            } else {
              canSeeList.push(permission.canSee);
            }
          });

          if (canLimited) {
            let canSee = canSeeList.filter((x) => x == true);

            if (canSee.length === 0) {
              return false;
            }
          }
        }
      }
      return true;
    }
    return false;
  },
  checkMenuObjectPermissionsModify(objectIdentifier) {
    let menuObject = store.state.menuObject.menuObjects.find(
      (x) => x.objectIdentifier == objectIdentifier
    );
    let user = store.state.login.user,
      canModifyList = [],
      canLimited = true;

    if (
      !utils.isNullOrUndefined(menuObject) &&
      !utils.isNullOrUndefined(user)
    ) {
      if (!user.systemAdmin) {
        let menuObjectModules = menuObject.modulePermissions.map(
            (x) => x.moduleId
          ),
          userModules = user.customerGroupModules.map((x) => x.moduleId);

        let modified = false;

        if (menuObjectModules.length > 0) {
          menuObjectModules.forEach((objectModule) => {
            if (userModules.indexOf(objectModule) > -1) {
              modified = true;
            }
          });
        }

        if (!modified) {
          return false;
        }

        if (!user.isAdmin) {
          user.userRoles.forEach((role) => {
            let permission = menuObject.menuObjectPermissions.find(
              (permission) =>
                !permission.isDeleted && permission.roleId == role.roleId
            );

            if (utils.isNullOrUndefined(permission)) {
              canLimited = false;
            } else {
              canModifyList.push(permission.canModify);
            }
          });

          if (canLimited) {
            let canModify = canModifyList.filter((x) => x == true);

            if (canModify.length === 0) {
              return false;
            }
          }
        }
      }
    }
    return true;
  }
};

export default objectPermissionHelper;
