<template>
  <div>
    <div class="container">
      <div
        class="row justify-content-center align-items-center col-md-12 col-lg-12"
        style="padding: 25px"
      >
        <h1>UI COMPONENTS GUIDE</h1>
      </div>

      <CtsInputComponent />
      <hr />

      <CtsInputGroupComponent />
      <hr />

      <CtsTextareaComponent />
      <hr />

      <CtsFileinputComponent />
      <hr />

      <CtsMultiselectComponent />
      <hr />

      <CtsFlatpickrComponent />
      <hr />

      <CtsCheckboxComponent />
      <hr />

      <CtsAlertComponent />
      <hr />

      <CtsToastrComponent />
      <hr />

      <CtsModalComponent />
      <hr />

      <CtsTimelineComponent />
      <hr />

      <CtsTooltipComponent />
      <hr />
    </div>
  </div>
</template>

<script>
  import CtsInputComponent from "@/components/Development/CtsInputComponent";
  import CtsInputGroupComponent from "@/components/Development/CtsInputGroupComponent/CtsInputGroupComponent";
  import CtsTextareaComponent from "@/components/Development/CtsTextareaComponent";
  import CtsFileinputComponent from "@/components/Development/CtsFileinputComponent";
  import CtsMultiselectComponent from "@/components/Development/CtsMultiselectComponent";
  import CtsFlatpickrComponent from "@/components/Development/CtsFlatpickrComponent";
  import CtsCheckboxComponent from "@/components/Development/CtsCheckboxComponent";
  import CtsAlertComponent from "@/components/Development/CtsAlertComponent";
  import CtsToastrComponent from "@/components/Development/CtsToastrComponent";
  import CtsModalComponent from "@/components/Development/CtsModalComponent";
  import CtsTimelineComponent from "@/components/Development/CtsTimelineComponent";
  import CtsTooltipComponent from "@/components/Development/CtsTooltipComponent";

  export default {
    name: "Development-UI-Page",
    components: {
      CtsInputComponent,
      CtsTextareaComponent,
      CtsFileinputComponent,
      CtsMultiselectComponent,
      CtsFlatpickrComponent,
      CtsCheckboxComponent,
      CtsAlertComponent,
      CtsToastrComponent,
      CtsModalComponent,
      CtsTimelineComponent,
      CtsTooltipComponent,
      CtsInputGroupComponent
    }
  };
</script>
<style>
  @import url("https://fonts.googleapis.com/css?family=News+Cycle:400,700|Roboto:400,700&display=swap&subset=latin-ext");
  @import "../../styles/bootstrap.min.css";
  @import "../../styles/login.css";
  .example {
    background-image: linear-gradient(
      to bottom,
      #f2f2f2,
      #f3f3f3,
      #f4f4f4,
      #f5f5f5,
      #f6f6f6
    );
    opacity: 0.8;
  }
</style>
