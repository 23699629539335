export const systemTypeEnum = {
  Material: 1,
  CommercialInvoice: 2,
  CommercialInvoiceDetail: 3,
  TruckRate: 4,
  TruckRatePallet: 5,
  OceanRate: 6,
  GTIPPaymentType: 7,
  BillOfLading: 8,
  FreightInvoice: 9,
  AirRate: 10,
  Shipment: 11,
  CustomsConsultancyCostRate: 12,
  ContainerTracking: 13,
  ImportFromBondedWarehouse: 14,
  CommercialInvoiceExtraCost: 15,
  CustomsBrokerageCost: 16,
  CustomsBrokerageExtraCost: 17,
  Partner: 18,
  Incoterm: 19,
  ShippingPoint: 20,
  MultiModelRate: 21
};
