<template>
  <div class="container-fluid row col-12">
    <div
      class="col-xs-12 col-sm-12 col-md-3 log-lg-3"
      style="padding-right: 4px"
    >
      <div class="card">
        <div style="background-color: #dee0f8; padding: 20px">
          <img :src="profileImage" />
        </div>
        <h1>{{ user.fullName }}</h1>
        <p class="title">{{ user.userName }} </p
        ><p class="title"> {{ user.email }}</p> <p>{{ user.companyName }}</p
        ><br /><br /><br />
        <p
          ><button @click="profilePhotoChange()"
            ><i class="las la-camera la-2x" /></button
        ></p>
      </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 form">
      <cts-input
        :placeholder="this.$root.dictionary.INPUT_FULLNAME"
        :model="user.fullName"
        :text="this.$root.dictionary.INPUT_FULLNAME"
        :disabled="checkDisabled"
        name="fullName"
        id="fullName"
        :checkAuth="false"
      ></cts-input>
      <cts-input
        :placeholder="this.$root.dictionary.INPUT_COMPANY"
        :model="user.companyName"
        v-model="user.companyName"
        :text="this.$root.dictionary.INPUT_COMPANY"
        :disabled="checkDisabled"
        name="companyName"
        id="companyName"
        :checkAuth="false"
      ></cts-input>
      <cts-input
        :placeholder="this.user.userName"
        :model="user.userName"
        v-model="user.userName"
        :text="this.$root.dictionary.INPUT_USERNAME"
        name="userName"
        id="userName"
        :checkAuth="false"
        :disabled="checkDisabled"
      ></cts-input>
      <cts-input
        :placeholder="this.user.email"
        v-model="user.email"
        :model="user.email"
        :text="this.$root.dictionary.INPUT_EMAIL"
        name="email"
        id="email"
        :checkAuth="false"
        :disabled="checkDisabled"
      ></cts-input>
      <cts-input
        :placeholder="this.user.password"
        :model="user.password"
        v-model="user.password"
        :text="this.$root.dictionary.INPUT_PASSWORD"
        name="password"
        type="password"
        id="password"
        :checkAuth="false"
        :disabled="checkDisabled"
      ></cts-input>
      <div class="float-right mt20 mb20">
        <button
          type="button"
          class="btn btn-primary"
          :disabled="checkDisabled"
          @click.prevent="updateUser"
        >
          {{ $root.dictionary["OPTIONS_SAVE_BUTTON"] }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import app from "../../common/constants/app";
  import axios from "axios";
  import profileImage from "../Profile/profileDefault.png";
  import CtsInput from "@/components/ui-components/CtsInput/CtsInput";
  import { pageStateEnum } from "../../common/enums/pageStateEnum";

  export default {
    name: "profile-page",
    components: {
      "cts-input": CtsInput
    },
    data() {
      return {
        profileImage: profileImage,
        user: {
          email: "",
          userName: "",
          fullName: "",
          password: "",
          companyName: ""
        }
      };
    },
    computed: {
      checkDisabled() {
        return app.loginWithActiveDirectory;
      }
    },
    methods: {
      profilePhotoChange() {},
      async getUser() {
        let response = await axios.get("user/GetMe");

        if (this.$utils.isResponseReturnedOk(response)) {
          this.responseUser = response.data.entity;
          this.user.email = response.data.entity.email;
          this.user.fullName = response.data.entity.fullName;
          this.user.userName = response.data.entity.userName;
          this.user.companyName = response.data.entity.company.heading;
        } else {
          this.$utils.showErrorMessage();
        }
      },
      async updateUser() {
        this.responseUser.email = this.user.email;
        this.responseUser.userName = this.user.userName;
        this.responseUser.password = this.user.password;

        let response = await axios.put("user/put", this.responseUser);

        if (response.status === 200 && response.data.state) {
          this.$CtsToastr.pageStateToastr(pageStateEnum.UPDATED);
        } else if (response.data.message == "EmailDublicate") {
          this.$utils.showErrorMessage(
            this.$root.dictionary.MSG_THIS_MAIL_HAS_BEEN_RECEIVED_BEFORE
          );
        } else {
          this.$CtsToastr.pageStateToastr(pageStateEnum.NOTUPDATED);
        }
      }
    },
    async mounted() {
      await this.getUser();
    }
  };
</script>

<style scoped>
  h1 {
    margin-bottom: 30px;
  }
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    margin: auto;
    text-align: center;
    font-family: arial;
    margin: 0;
  }
  .title {
    color: grey;
    font-size: 18px;
  }
  button {
    border: none;
    outline: 0;
    display: inline-block !important;
    vertical-align: bottom !important;
    padding: 8px;
    color: white;
    background-color: #4d5162;
    text-align: center;
    cursor: pointer !important;
    width: 100%;
    font-size: 18px;
  }
  button:hover,
  a:hover {
    opacity: 0.7;
  }
  img {
    width: 50%;
    border-radius: 500px;
    background-color: transparent;
  }
  .form {
    background-color: #f8f8fd;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding-top: 15px;
  }
</style>
