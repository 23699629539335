const initialState = () => {
  return {
    sidebar: []
  };
};
const state = () => initialState();

export const sidebar = {
  namespaced: true,
  state: state,
  getters: {},
  mutations: {
    resetModuleState(state) {
      Object.assign(state, initialState());
    }
  },
  actions: {},
  mounted() {}
};
