<template>
  <div class="inwardProcessingCertificate form_opt">
    <h4>{{
      this.$root.dictionary.TITLE_FORTHCOMING_EXPIRATION_DATE_FOR_CERTIFICATES
    }}</h4>
    <div id="frm" class="inwardProcessingCertificate_form row">
      <div class="w-100"></div>
      <div class="col-12">
        <cts-table
          :list="this.list"
          :headers="this.headers"
          :headerFilters="this.$root.tableFilters"
          :searchType="this.searchType"
          :searchValue="'/InwardProcessingCertificate/getwithoptionswithdatecontrol'"
          :page="this.currentPage"
          :dataCount="this.totalDataCount"
          :pageCount="this.totalPageCount"
          :subTotals="this.subTotalList"
        ></cts-table>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import app from "@/common/constants/app";
  import searchTypes from "@/common/enums/searchTypes";
  import ListComponent from "@/components/data-table/DataTableComponent";

  export default {
    name: "dateControlList",
    components: {
      "cts-table": ListComponent
    },
    data() {
      return {
        saveFunction: Function,
        list: [],
        headers: [],
        currentPage: 0,
        totalDataCount: 0,
        totalPageCount: 0,
        searchType: searchTypes.URL,
        subTotalList: []
      };
    },
    computed: {
      getHeaders() {
        return this.$root.changeHeader(this.headers);
      }
    },
    methods: {
      async getCertificateList() {
        let response = await axios.post(
          "/InwardProcessingCertificate/getwithoptionswithdatecontrol",
          {
            page: app.firstPage,
            size: app.pageSize,
            menuId: this.$route.meta.menuId,
            componentId: this.$route.meta.ExpirationControlListComponentId
          }
        );

        if (this.$utils.isResponseReturnedOk(response)) {
          this.list = response.data.state ? response.data.list : [];
          this.headers = response.data.headers;
          this.totalDataCount = response.data.totalDataCount;
          this.totalPageCount = response.data.totalPageCount;
          this.currentPage = response.data.currentPage;
          this.subTotalList = response.data.dynamicList;
        } else {
          this.$utils.showErrorMessage();
        }
      },
      setDataList(datalist) {
        this.list = datalist;
      },
      setSelectedRows(selectedRows) {
        this.selectedRows = selectedRows;
      },
      dataEventBus(data) {
        this.list = data;
      }
    },
    async mounted() {
      await this.getCertificateList();
      this.importFunction = this.import;
    }
  };
</script>
