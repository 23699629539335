<template>
  <div
    class="row justify-content-center align-items-flex-start col-md-12 col-lg-12"
    style="position: relative"
  >
    <div class="col-md-6 col-lg-6" style="text-align: left">
      <div class="col-md-12 col-lg-12 row">
        <div class="col-md-4 col-lg-4">
          <div class="form-group">
            <button
              class="btn btn-alert btn-success btn-block"
              @click="successModal"
              >Success Modal</button
            >
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <div class="form-group">
            <button
              class="btn btn-alert btn-primary btn-block"
              @click="modalWithButtons"
              >Modal With Buttons</button
            >
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <div class="form-group">
            <button
              class="btn btn-alert btn-danger btn-block"
              @click="inputModal"
              >Input Modal</button
            >
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="example" id="example">
          <h5>
            <pre> {{ example }} </pre>
          </h5>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6" style="text-align: left">
      <ul>
        <li>
          <b>
            For All Options:
            <a
              href="https://sweetalert2.github.io/#configuration"
              target="_blank"
              >sweetalert2 documentation</a
            >
          </b>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  export default {
    name: "CtsAlertComponent",
    data() {
      return {
        modalInputData:
          '<div class="form-group row" value="username">' +
          '<label for=" username " class="control-label col-xs-12 col-sm-4 col-md-3 col-lg-3">Github username' +
          "</label>" +
          '<div class="col-xs-12 col-sm-8 col-md-9 col-lg-9">' +
          '<input name="username" id="username" class="form-control">' +
          "</div>" +
          "</div>",
        example:
          "Usage in the function:" +
          "\n" +
          " successModal() {" +
          "\n" +
          "   this.$CtsModal.showInfoModal(" +
          "\n" +
          "     this," +
          "\n" +
          '     "Success!",' +
          "\n" +
          '     "Will disappear in seconds",' +
          "\n" +
          '     "success"' +
          "\n" +
          "   );" +
          "\n" +
          "  },"
      };
    },
    methods: {
      successModal() {
        this.$CtsModal.showInfoModal(
          this,
          "Success!",
          "Will disappear in seconds",
          "success"
        );
      },

      modalWithButtons() {
        this.$CtsModal
          .showConfirmModal(
            this,
            "Confirm!",
            "It's a confirmation.",
            "You can't escape with esc or click outside :)"
          )
          .then(() => {
            //Do some Stuff
          })
          .catch((err) => {
            console.log(err);
          });
      },

      inputModal() {
        this.$CtsModal
          .showFormModal(
            this,
            "Magic Time",
            "Enter your github username",
            this.modalInputData,
            this.modalPreFunction
          )
          .then((result) => {
            if (result.value) {
              this.$fire({
                title: `${result.value.login}'s avatar`,
                imageUrl: result.value.avatar_url
              });
            }
          });
      },

      modalPreFunction() {
        let username = document.querySelector("input#username").value;

        return fetch(`//api.github.com/users/${username}`)
          .then((response) => {
            if (!response.ok) {
              throw new Error(response.statusText);
            }
            return response.json();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
</script>
<style>
  .btn-alert {
    height: 50px;
  }
</style>
