import axios from "axios";
import localStorageService from "@/services/LocalStorageService.js";
import utils from "@/common/utils/utils.js";

const languageServices = {
  getDictionary(language, condition) {
    let localDictionary = localStorageService.get("dictionary");

    if (utils.isNullOrUndefined(localDictionary) || condition) {
      axios
        .get("languagedictionary/GetById", {
          params: {
            languageId: language
          }
        })
        .then((res) => {
          let dictionary = {};
          res.data.forEach((item) => {
            Object.defineProperty(dictionary, item.definition, {
              get: function () {
                return item.description;
              },
              enumerable: true
            });
          });

          localStorageService.set({
            key: "dictionary",
            value: dictionary
          });
        });
    }
  }
};

export default languageServices;
