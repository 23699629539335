<template id="timeline-template">
  <ul class="timeline">
    <li
      v-for="item in items"
      :item="item"
      :key="item.id"
      :class="'timeline-item ' + item.action_needed"
    >
      <div :class="item.icon_status + ' timeline-badge'">
        <i :class="item.icon_class" />
      </div>
      <div
        :class="
          'timeline-panel ' +
          item.element_status +
          ' ' +
          item.element_day_marker
        "
      >
        <div class="timeline-heading">
          <h6 :class="'timeline-title ' + item.text_status">
            {{ item.title }}
          </h6>
          <div class="timeline-panel-controls">
            <div class="controls">
              <a
                href="#"
                @click="handleClick(control, item)"
                v-for="control in item.controls"
                :control="control"
                :key="control.method"
              >
                <i :class="control.icon_class" />
              </a>
            </div>
            <div class="timestamp">
              <small class="">{{ item.created }}</small>
            </div>
          </div>
        </div>
        <div class="timeline-body">{{ item.body }}</div>
      </div>
    </li>
  </ul>
</template>

<script>
  import { timelineStatusEnum } from "../../../common/enums/timelineStatusEnum";
  export default {
    name: "CtsTimeline",
    props: {
      items: {
        type: Array,
        required: true
      }
    },
    components: {},
    methods: {
      handleClick(control, item) {
        this.$emit("handleClick", control, item);
      }
    },
    created() {
      for (var i = 0; i < this.items.length; i++) {
        let item = this.items[i];

        switch (item.Statu) {
          case timelineStatusEnum.SUCCESS:
            item.icon_class = "las la-check";
            item.icon_status = "success";
            item.element_status = "main_element";
            break;
          case timelineStatusEnum.DANGER:
            item.icon_class = "las la-exclamation";
            item.icon_status = "danger";
            item.text_status = "danger";
            item.element_status = "danger";
            item.action_needed = "pulse_wrap";
            break;
          case timelineStatusEnum.PLANNING:
            item.icon_class = "las la-map";
            item.icon_status = "planning";
            break;
          case timelineStatusEnum.INFO:
            item.icon_class = "las la-info";
            item.icon_status = "info";
            break;
          case timelineStatusEnum.PRIMARY:
            item.icon_class = "las la-arrow-up";
            item.icon_status = "primary";
            break;
          case timelineStatusEnum.WARNING:
            item.icon_class = "las la-times";
            item.icon_status = "warning";
            item.element_status = "today";
            item.title = "";
            item.created = "";
            item.body = "";
            break;
          case timelineStatusEnum.PAST:
            item.icon_class = "las la-backward";
            item.icon_status = "past";
            item.element_status = "past";
            break;
          case timelineStatusEnum.DISTANTPAST:
            item.icon_class = "las la-step-backward";
            item.icon_status = "selected_past";
            item.element_status = "selected_past";
            break;
        }
      }
    }
  };
</script>

<style>
  @import "./ctstimeline.css";
</style>
