import axios from "axios";
import utils from "@/common/utils/utils";
import localStorageService from "@/services/LocalStorageService";
import router from "@/router";

const awsTokenService = {
  async getUserInfo(code) {
    let bearerToken = btoa(
        require("@/configs/cognito." + process.env.NODE_ENV + ".json")
          .aws_user_pools_web_client_id +
          ":" +
          require("@/configs/cognito." + process.env.NODE_ENV + ".json")
            .aws_user_pools_web_secret_key
      ),
      details = {
        grant_type: "authorization_code",
        scope: "email+openid+profile",
        code: code,
        redirect_uri: require("@/configs/cognito." +
          process.env.NODE_ENV +
          ".json").aws_redirectUri
      },
      formBody = Object.keys(details)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(details[key])}`
        )
        .join("&");

    if (!utils.isNullOrWhiteSpace(code)) {
      let response = await axios.post(
        require("@/configs/cognito." + process.env.NODE_ENV + ".json")
          .aws_tokenUrl,
        formBody,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            responseType: "json",
            Authorization: "Basic " + bearerToken
          }
        }
      );

      if (
        utils.isResponseOk(response) &&
        !utils.isNullOrWhiteSpace(response.data) &&
        utils.hasPropertyExistInArray(response.data, "access_token")
      ) {
        let res = await axios.post(
          require("@/configs/cognito." + process.env.NODE_ENV + ".json")
            .aws_tokenUserInfo,
          null,
          {
            headers: {
              Authorization: "Bearer " + response.data.access_token
            }
          }
        );

        if (utils.isResponseOk(res)) {
          let today = new Date(),
            tokenExpiresInHours = parseInt(response.data.expires_in) / 3600;

          today.setHours(today.getHours() + tokenExpiresInHours);
          localStorageService.set({
            key: "cngtkn",
            value: response.data
          });
          localStorageService.set({ key: "ltoft", value: today });

          return res.data;
        }
      }
    }
  },
  async refreshToken() {
    let today = new Date(),
      cognitoToken = localStorageService.get("cngtkn"),
      tokenLifeTime = localStorageService.get("ltoft"),
      isTokenExpired = !utils.isNullOrWhiteSpace(tokenLifeTime)
        ? new Date(tokenLifeTime.replace(/"/g, "")) < today
        : true;

    if (
      cognitoToken !== null &&
      utils.hasPropertyExistInArray(cognitoToken, "refresh_token") &&
      isTokenExpired
    ) {
      let refreshToken = cognitoToken.refresh_token,
        bearerToken = btoa(
          require("@/configs/cognito." + process.env.NODE_ENV + ".json")
            .aws_user_pools_web_client_id +
            ":" +
            require("@/configs/cognito." + process.env.NODE_ENV + ".json")
              .aws_user_pools_web_secret_key
        ),
        details = {
          grant_type: "refresh_token",
          client_id: require("@/configs/cognito." +
            process.env.NODE_ENV +
            ".json").aws_user_pools_web_client_id,
          refresh_token: refreshToken
        },
        formBody = Object.keys(details)
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(details[key])}`
          )
          .join("&");

      let response = await axios.post(
        require("@/configs/cognito." + process.env.NODE_ENV + ".json")
          .aws_tokenUrl,
        formBody,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Basic " + bearerToken
          }
        }
      );

      if (
        utils.isResponseOk(response) &&
        !utils.isNullOrWhiteSpace(response.data) &&
        utils.hasPropertyExistInArray(response.data, "access_token")
      ) {
        response.data.refresh_token = refreshToken;
        localStorageService.set({
          key: "cngtkn",
          value: response.data
        });
        localStorageService.set({ key: "ltoft", value: today });
      } else {
        router.push("/auth/login");
      }
    }
  },
  async isLoginNeeded() {
    let userInfo = localStorageService.get("user"),
      cognito_token = localStorageService.get("cngtkn");

    if (
      router.currentRoute.name !== "login" &&
      userInfo === null &&
      cognito_token === null
    ) {
      return true;
    } else if (
      router.currentRoute.name !== "login" &&
      userInfo !== null &&
      cognito_token !== null &&
      !utils.hasPropertyExistInArray(cognito_token, "refresh_token")
    ) {
      return true;
    } else {
      return false;
    }
  },
  async setStorage(dictionary) {
    if (!utils.isNullOrUndefined(dictionary)) {
      Object.entries(dictionary).forEach(([key, value]) => {
        localStorageService.set({
          key: key,
          value: value
        });
      });
    }
  }
};

export default awsTokenService;
