export const statusTypeEnum = {
  WaitingForApproval: 1,
  Approved: 2,
  Rejected: 3,
  Inconsistent: 4,
  Consistent: 5,
  ManifestSent: 6,
  WorkOrderSent: 7,
  InTransit: 8,
  AtPort: 9,
  Ready: 10,
  Requested: 11,
  Fumigation: 12,
  Reused: 13,
  Returned: 14,
  OnGround: 15,
  Initial: 16,
  CustomsCleared: 17,
  ConditionSent: 18
};
